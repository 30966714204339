@import url('../../../css/VARIABLES.css');

.wrapper {
    display: flex;
}

.wrapper .btn {
    display: flex;
    gap: 8px;
    align-items: center;
    height: 36px;
    border: 1px solid var(--Color__Neutral3);
    color: var(--PrimaryBrandColor);
    border-radius: 0;
    background: var(--RadioAsButton__Bg);
    padding: 10px;
}

.wrapper .btn:first-child {
    border-right: none
}

.wrapper .btn:last-child {
    border-left: none
}

.wrapper .btn:not(:first-child) {
    border-left: 1px solid var(--Color__Neutral3);
}

.wrapper .btn:focus {
    outline: none;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center
}

.icon svg {
    width: 18px;
    fill: var(--SecondaryBrandColor);
}

.btn.selected {
    background-color: var(--PrimaryBrandColor);
    border: 1px solid var(--PrimaryBrandColor);
    color: var(--BtnPrimary__Text)
}

/* Cutler Styles */

.btn.cutler.selected {
    background-color: var(--SecondaryBrandColor) !important;
    border: none !important;
    color: var(--BtnSecondary__Text)
}

.btn.selected svg {
    fill: var(--IconColor);
}

/* Constellation Styles */

.btn.constellation.selected svg {
    fill: var(--BtnPrimary__Text);
}

.constellation .icon svg {
    fill: var(--PrimaryBrandColor);
}