@import url("../../css/VARIABLES.css");

.privacyLink {
  margin-right: 6px;
  display: contents;
}

.noBorderBtn {
  border-radius: 0;
}

.twoColWrapper {
  gap: 20px;
}

.customerInfoBtnWrapper {
  display: flex;
}

.customerInfoBtnWrapper > button:last-child {
  margin-left: 1rem;
}

.savelaterwrapper a {
  margin-left: 13%;
}

.saveForLater {
  color: var(--BtnLink__Text) !important;
  cursor: pointer;
}
/* save modal */

.saveCardHeader h4 {
  margin-bottom: 0px;
}

.saveCardHeader {
  display: flex;
  padding: 1rem;
  border-bottom: 1px solid var(--Color__Neutral2);
  justify-content: space-between;
}

.btnAlign {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .customerInfoBtnWrapper {
    width: 100% !important;
    flex-direction: column-reverse;
  }

  .customerInfoBtnWrapper > button:first-child {
    margin-top: 1rem;
  }

  .savelaterwrapper {
    text-align: center;
  }
  .savelaterwrapper a {
    margin-left: 0;
  }
}
