@import url('../../css/VARIABLES.css');

.tabNav {
    list-style: none;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-left: 0;
    margin-bottom: 24px;
}

.tab {
    flex-grow: 1;
    padding: 16px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-bottom: 1px solid var(--Color__Neutral2);
    cursor: pointer;
    /* border: 1px solid mediumseagreen; */
}

.activeTab {
    border-bottom: 4px solid var(--ActiveTab);
    color: var(--ActiveTab__Text);
    font-weight: 600;
}

.planDetailsCard {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    /* border: 1px solid tomato */
}

.planDetailsCol {
    border-left: 1px solid var(--Color__Neutral2);
    padding: 32px;
}

.detailsList {
    list-style: none;
    padding-left: 0;
    padding-bottom: 24px;
}

.accountUpdateAlert {
    position: absolute;
    z-index: 999;
    right: 0;
}

.listHeader {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

.containerPadding {
    padding: 0px 35px 50px !important;
}
