.headerBorder {
    border-bottom: 1px solid #b9c6d4;
}

.headerLg {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.closeButton {
    top: 2px;
    z-index: 9;
}

.logoContainer {
    padding: 14px;
}

.logoContainer a {
    cursor: pointer;
}

.logo {
    height: 40px;
    width: 85px;
    margin: 8px;
}

.mobileNav button {
    border: 1px solid rgba(0, 0, 0, .1) !important;
    padding: 0 !important;
}

.navContainer {
    display: flex;
    justify-content: flex-end;
}

.navTabs li a {
    padding: .5rem .7rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    color: var(--PrimaryTextColor) !important;
    border: none !important;
    background: none !important;
    cursor: pointer;
}

.navTabs li a:active,
.navTabs li a:hover {
    background: var(--PrimaryBrandColor) !important;
    color: #fff !important;
}

.mobileLogo {
    display: none;
}

.navMenu {
    margin: 0;
}

@media screen and (max-width:767px) {
    .headerLg {
        display: none;
    }

    .navTabs li a {
        color: var(--Color__Neutral1) !important;
        font-size: 1.3rem !important;
    }

    .navContainer {
        justify-content: space-between;
    }

    .mobileLogo {
        display: block;
    }
}

@media (min-width:767px) {

    .mobileNav {
        padding: 0 !important;
    }
}

@media only screen and (max-device-width :768px) {

    .mobileLogo {
        display: block;
    }

    .headerLg {
        display: none;
    }

    .navContainer {
        padding: 10px;
        justify-content: space-between;
    }
}