@import url('../../../css/VARIABLES.css');

.pageLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 600px;
    width: 100%;
}

.spinnerBorder {
    width: 3rem;
    height: 3rem;
    border: .25em solid;
    border-right: .25em solid transparent;    
    animation: spinner-border .75s linear infinite;
    margin-bottom: .5rem;
    border-radius: 50%;
}
.spinnerBorder.primary {
    color: var(--PrimaryBrandColor) !important;
}

.spinnerBorder.secondary {
    color: var(--SecondaryBrandColor) !important;
}

@keyframes spinner-border {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}