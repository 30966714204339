@import url('../../../css/VARIABLES.css');
.wrapper {
    position: relative;
    max-width: 350px;
}

@media (max-width: 767px) {
    .wrapper {
        max-width: 100%;
    }
}

.optionsContent {
    position: absolute;
    top: 55px;
    background: white;
    z-index: 1;
    border: 1px solid #dee2e6;
    width: 100%;
}

.dropOption {
    padding: 8px 8px;
    cursor: pointer;
    width: 100%;
}

.searchInput {
    border: none;
    padding: 8px 24px;
    display: block;
    margin-bottom: 0;
    width: 100%;
}

.optionsContent label,
.searchInput {
    width: 100%;
}

.optionsContent > * {
    border-bottom: 1px solid #dee2e6;
}

.optionsContent > .dropOption:last-child {
    border-bottom: none;
}

.dropError {
    border-color: var(--ErrorColor);
    color: var(--ErrorColor);
} 