.overviewContainer {
    width: 1120px;
    margin:0 auto;
}

.planhero {
    min-height: 180px;
    background: linear-gradient(90deg, #001871 0%, #1352DE 100%);
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 32px;
    padding: 24px 0;
}

    .planhero h1 {
        color: #fff;
        margin-bottom: 0;
        text-align: left;
        justify-content: left !important;
        font-size: 40px;
    }

.cardHeader{
    padding: 15px 35px!important;
}

.cardHeader h3 {
    font-size: 1.5rem !important;
}

.contentList {
    list-style: none;
    pointer-events: none;
    padding-left:11px;
}
 .planhero h1 {
    color: #fff;
    margin-bottom: 0;
}
.search {
    display: flex;
    align-items: center;
    color: #1352DE !important;
    font-size: 20px;
    font-weight: 600;
    margin: 0px 14px;
}
.searchIcon {
    fill: #1352DE;
    height: 20px;
    width: 20px;
    transform:rotate(90deg);
}
.cardIcon {
    fill: #1352DE;
    height: 63px;
    width: auto;
    object-fit: contain;
    position: absolute;
    bottom: 20px;
    right: 36px;
}

.card {
    border-top: 5px solid #1352DE;
    border-radius:7px;
    margin-bottom:10px;
}

.card-body {
    margin-bottom: 8px;
    display: flex;
    /*align-items: flex-end;*/
    padding: 20px 36px !important;
    position: relative;
}

.mb3 {
    margin-bottom: 30px;
}

.planbtn {
    padding: 5px 15px !important;
}

@media only screen and (max-width: 767px) {
    .planhero .card {
        margin-top: 16px;
    }
    .overviewContainer {
        width: 500px;
        margin: 0 auto;
    }

    .cardIcon {
        top: 20px;
    }
}
