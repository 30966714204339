@import url('../../../../css/VARIABLES.css');

.rtCol {
    background: var(--Color__Neutral1);
    padding: 24px;
    max-width: 416px;
    line-height: 1.8;
    font-size: 15px;
    border-radius: 6px;
    margin: 9px 0;
}

.deviceImg {
    width: 92px;
    height: 108px;
}

.rtCol .h6 {
    color: #343741;
}

.rtCol ul {
    margin-bottom: 0;
}

.rtCol p {
    margin: .5rem 0 0;
}

.btmLine {
    border-bottom: 1px solid var(--Color__Neutral3);
    padding-bottom: 24px;
    margin-bottom: 24px;
}

h3.total {
    margin-bottom: 0;
    font-size: calc(1.125rem * 1.173 * 1.173);
    line-height: 1.33;
    color: #1352DE;
}

.totalPaymentContainer{
    padding: 0px;
    margin: 0px;
    justify-content: space-between;
}

.totalValue {
    color: #1352DE;
}

/*.paymentInfo {
    margin-top: 1rem;
}*/

.paymentInfo div:last-child {
    font-weight: bold;
    margin-top: 8px;
}

.blue {
    color: #1352DE;
}

.rtCol .h6 {
    margin-bottom: 0;
}

.deviceInfo {
    position: relative;
}

    .deviceInfo h3 {
        width: 302px;
    }

.deviceImg {
    position: absolute;
    right: 0;
    top: 0;
}

.ccImage {
    width: 50px;
    margin-right: 8px;
}

.pr15 {
    padding-right: 85px !important;
}

@media (max-width: 991px) {
    .rtCol {
        margin: 24px 15px 0;
    }
}

@media (max-width: 573px) {
    .rtCol {
        max-width: 100%;
    }
}

.wrapText {
    overflow-wrap: break-word;
}
