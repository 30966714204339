@import url('../../../css/VARIABLES.css');

.cutlerFooter {
    font-size: 14px;
    color: #343741;
    background: transparent;
    padding: 36px 18px;
    margin-top: 0;
}

.cutlerFooterText {
    color: #343741;
    margin-bottom: 0;
    margin-top: 0;
    padding: 20px 0;
    border: none;
}

.navItem {
    list-style-type: none;
    display: inline-flex;
    padding-left: 0;
    text-decoration: none;
    margin-bottom: 10px;
}

    .navItem li:first-child ::after {
        content: "|";
        color: var(--PrimaryBrandColor);;
        margin-left: 16px;
    }

    .navItem li a:first-child {
        padding-right: 16px;
    }

    .navItem li a {
        color: var(--PrimaryBrandColor);
        padding: 0px;
    }

.cutlerFooter p {
    letter-spacing: 0.43px;
}

.footerBrandInfo {
    display: none;
}

.footerTextArea {
    padding: 0 20px;
}

.cutlerFooterPadding {
    padding-left: 34px !important;
}

.footerNav {
    margin: 0 5.5rem;
}

@media (max-width: 767px) {
    .container {
        max-width: 100%;
    }
}