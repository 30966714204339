.confirmSection {
    text-align: center;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    padding: 75px 0;
}

.confirmWidthWrap {
    max-width: 640px;
}

.confirmIcon {
    margin-bottom: 26px;
    height: 151px;
}

.errorIcon {
    margin-bottom: 26px;
    height: 120px !important;
}

.confirmSection h2 {
    font-size: 16px;
    font-weight: 600;
    color: #343741;
    line-height: 1.625;
}

.confirmSection article {
    width: 677px;
    text-align: justify;
}

.mt18 {
    margin-top: 18px;
}

@media screen and (max-width: 767px) {
    article {
        width: auto;
    }
}
