@import url('../../../css/VARIABLES.css');

.contentHeader {
    color: var(--PrimaryTextColor);
}

.appleLogo {
    margin-top: 80px;
}

/*Ignore header styling for Southbridge and bold resume claim page text*/
.resumeClaimSB {
    font-weight: 600 !important;
}
