.search {
    display: flex;
    align-items: center;
    color: #1352DE !important;
    font-size: 16px;
    font-weight: 600;
    margin: 0px 14px;
}

.searchIcon {
    fill: #1352DE;
    height: 16px;
    width: 16px;
    margin-right: .25rem;
    transform: rotate(90deg);
}

.btmLine {
    border-bottom: 1px solid #B9C6D4;
    padding-bottom: 16px;
    margin-bottom: 32px;
}

.reimbursementList{
margin-left: -17px!important;
}

.submitBtn {
    width: 134px;
}

@media (max-width: 767px) {
    .submitBtn {
        width: 102px;
    }
}


/* Styles Moves out to seperate Component*/

.progressItem {
    position: relative;
    min-height: 75px;
    list-style: none;
    padding-left: 0.5rem;
    margin: 0;
    font-size: 1.125rem;
    font-weight: bold;
}

    .progressItem:before {
        content: "";
        position: absolute;
        left: -30px;
        top: 25px;
        height: 73%;
        width: 1px;
        border-left: 1px dotted #979797;
    }

    .progressItem:after {
        content: '';
        position: absolute;
        top: 5px;
        left: -2.5rem;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #1352DE;
    }

    .progressItem:last-child:before {
        border: none;
    }

    .progressItem.progressItemCompleted:after {
        border: 1px solid #1352DE;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=);
        background-color: #1352DE;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 9px 9px;
    }

    .progressItem p {
        margin-bottom: 0;
    }

        .progressItem p:nth-child(2) {
            margin-bottom: 0;
            font-weight: normal;
            line-height: 1.625;
        }
.rtCol {
    background: rgb(199,219,244, .25);
    padding: 24px 32px;
    line-height: 1.8;
    font-size: 15px;
    border-radius: 6px;
    max-width: 416px;
}

.estArrival {
    font-weight: normal;
    color: #343741;
    margin-bottom: 0;
    font-size: calc(1.125rem * 1.173 * 1.173);
    line-height: 1.33;
}

@media (max-width: 991px) {
    .rtCol {
        margin: 0 15px 32px;
    }
}

@media (max-width: 573px) {
    .rtCol {
        max-width: 100%;
    }
}
