@import url("../../css/VARIABLES.css");
.myClaimsTable>tbody td {
    vertical-align: middle;
    padding: 0.75rem 1.75rem;
    text-overflow: ellipsis;
}

.myClaimsTable>thead th {
    padding: 1.75rem;
    position: relative;
}

.myClaimsTable>tbody tr:nth-of-type(odd) {
    background-color: var(--Table__RowBg);
}

.myClaimsTable {
    border: var(--Table__Border);
    color: var(--PrimaryTextColor);
}

.myClaimActions {
    text-align: center;
}

.myClaimActions button {
    width: 138px;
}

.sortingIcon svg {
    height: 14px;
    width: 14px;
    margin-left: 12px;
    cursor: pointer;
    margin-bottom: 4px;
}

.myClaimsTable a {
    cursor: pointer;
}
