@import url('../../../css/VARIABLES.css');

.footerText {
    color: var(--PrimaryTextColor);
    margin-bottom: 0;
    margin-top: 0;
    padding: 20px 0;
    border: none;
}

.footerTextArea {
    padding: 0 20px;
}

.constellationFooter {
    font-size: 14px;
    background: #333333;
    padding: 36px 20px;
    margin-top: 0;
}

.constellationFooter p {
    letter-spacing: 0.43px;
}

.footerPadding {
    padding-left: 38px !important;
}

.navItem {
    list-style-type: none;
    display: inline-flex;
    padding-left: 0;
    text-decoration: none;
    margin-bottom: 10px;
}

.navItem li:first-child ::after {
    content: "|";
    margin-left: 16px;
}

.navItem li a:first-child {
    padding-right: 16px;
}

.navItem li a {
    color: #FFFFFF;
    padding: 0px;
}

@media screen and (max-device-width: 1199.98px) {

    .footerPadding {
        padding-left: 28px !important;
    }
}