@import url("../../../css/VARIABLES.css");

.subNavBar {
    display: flex;
    align-content: center;
    justify-content: space-between;
}
.assetContainer {
    border: var(--Table__Border);
    padding: 0px;
}

.assetRow {
    background-color: var(--Color__Neutral1);
    display: flex;
    padding: 25px 35px;
    align-items: center;
    justify-content: space-between;
}

.coverageContainer {
    padding: 20px;
}

.assetNameHeader {
    color: var(--PrimaryBrandColor);
    font-weight: bold;
    margin: 0px;
    flex: 5;
}

.fileClaimButton {
    flex: 1;
    max-width: 15%;
}

.assetIcon svg {
    height: 68px;
    width: 68px;
    fill: var(--PrimaryBrandColor);
    flex: 1;
    margin-right: 2rem;
}

.arrowIcon {
    fill: var(--PrimaryBrandColor);
    height: 16px;
    width: 16px;
    margin-right: .25rem;
    transform: rotate(90deg);
}

.checkmarkContainer {
    display: flex;
    justify-content: center;
    align-items:  center;
    border-left: 1px solid var(--Color__Neutral2);
    padding: 12px 16px;
}

.checkmarkIcon {
    fill: #13AA41;
    height: 16px;
    width: 16px;
}

.viewLink {
    display: flex;
    align-items: center;
    color: var(--PrimaryBrandColor) !important;
    font-size: 16px;
    font-weight: 600;
    margin: 20px 8px;
    cursor: pointer;
}

.coveredInfoContainer {
    margin: -25px -40px;
}

.coveredInfoContainer .coveredItemsRow:last-child {
    border-bottom: none;
}

.coveredItemsRow {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--Color__Neutral2);
}

.coveredItem {
    display: flex;
    padding: 5px 40px;
    align-items: center;
}

.notCoveredInfoContainer {
    margin: -6px -18px;
}

.notCoveredInfoContainer li {
    margin-bottom: 8px;
}

@media screen and (max-width: 541px) {
    .assetRow {
        padding: 15px 25px;
    }

    .assetIcon svg {
        height: 42px;
        width: 42px;
        margin-right: 1rem;
    }

    .assetNameHeader {
        font-size: var(--H4__Size);
    }  
}