@import url('../../css/VARIABLES.css');
.viewDocModalHeader {
    display: flex;
    padding: 16px;
    border-bottom: var(--Table__Border);
    justify-content: start;
}

.viewDocModalHeader div {
    display: flex;
    align-items: center;
}

.viewDocModalHeader div p {
    margin-bottom: 0px;
}

.viewDocModalBody {
    padding: 0px 16px 0px 16px !important;
}

.viewDocModalBody p {
    font-size: 14px;
}

.docDetailslist {
    border-right: var(--Table__Border);
}

.docDetailslist ul {
    list-style-type: none;
    padding: 16px 0px 16px 0px;
}

.docDetailslist li {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.detailListLabels {
    color: var(--PrimaryBrandColor);
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 8px;
    max-width: 100%;
}

.pdfViewer div[class^="pg-viewer-wrapper"] {
    overflow-y: auto !important;
    max-height: 600px;
}

.docImage {
    margin: 16px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.docImage input {
    max-height: 500px;
    max-width: 530px;
}

.pdfImage {
    width: 100%;
    overflow: scroll;
}