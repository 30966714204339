@import url('../../../../css/VARIABLES.css');

.cardTitle {
  color: var(--PrimaryTextColor);
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 8px;
}

h2.cardTitle {
  margin-bottom: 1rem;
}

.w100 {
  width: 100% !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}


.symptomGroup {
  margin: 0 -.5rem;
}

.symptomGroup [class*="col"] {
  padding: 0 .5rem !important;
}

#followQuestion .radioButton input[type="radio"]+label {
  padding: 15px 4rem;
  margin: .5rem 1rem 1rem 0;
}

#troubleshooting ul {
  padding-left: 1rem;
}

#troubleshooting ul li {
  margin-bottom: .25rem;
}

#affirm .checkbox {
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  #affirm .checkbox {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

.mw275 {
  max-width: 275px;
}

.maxLength {
  font-size: 0.875rem;
  margin-bottom: 0;
  text-align: right;
}

.dateInput {
  max-width: 200px;
  border-right: 0 !important;
}

.datePicker {
  width: 65px;
  border: 1px solid #b9c6d4;
  border-left: 0;
  padding: 13px 0 13px 0;
  height: 56px;
}

.description {
  font-size: 16px;
  margin-bottom: 8px;
}

.description ul {
  padding-left: 18px;
}

.description ul li {
  text-indent: -4px;
}

.description ul li::marker {
  font-size: 14px;
}

/* Save Modal css */

.saveModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.saveModalBody div {
  text-align: left;
  margin-bottom: 2.5rem;
}

.saveModalBody p {
  margin-bottom: 24px;
}

.formError {
    border-color: var(--ErrorColor)!important;
    background-color: #fff;
    color: var(--ErrorColor)!important;
}

.textError {
    color: var(--ErrorColor) !important;
}

@media (max-width: 425px) {
  .saveModalBody div {
    padding: 0px;
  }
}