@import url('../../css/VARIABLES.css');

.btmLine {
    border-bottom: 1px solid var(--Color__Neutral3);
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.infoIcon {
    height: 17px;
    fill: var(--IconColor);
    max-width: 25px;
    vertical-align: sub;
    cursor: pointer;
}

.downloadIcon {
    height: 20px;
    fill: var(--IconColor);
    vertical-align: sub;
    margin-left: 10px;
}

.tooltiptext {
    width: 480px;
}

.addressForm {
    width: 70%;
}

.addressSection ul {
    margin-bottom: 0;
    padding-bottom: 24px !important;
}

.totalSection {
    margin-bottom: 24px;
}

.totalSection div[class^="position-relative form-group"],
.totalSection div[class^="form-error"] {
    margin-bottom: 0px !important;
}