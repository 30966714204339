@import url('../../css/VARIABLES.css');
@media screen and (max-width:385px) {
    .pageTitle {
        text-align: center !important;
    }
}

.cardTitle {
    color: var(--PrimaryTextColor);
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 1rem!important;
    text-align: center;
    display: block;
}

h3.cardTitle {
    color: var(--PrimaryTextColor);
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 24px;
    display: block;
    text-align: center;
}

.srvAddress {
    color: var(--PrimaryTextColor);
    padding-left: 0;
    list-style: none;
}

.srvAddress li:first-child {
    font-weight: bold;
}

.srvAddress li:nth-child(4) {
    margin-bottom: 1.3rem;
}

.srvAddress li:nth-child(5) {
    margin-bottom: 1.3rem;
    margin-top: 1rem;
}

.pageHeader {
    height: 229px;
    background-color: var(--Color__Neutral1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.custDetailsBox {
    box-sizing: border-box;
    border: 1px solid #E0E5EB;
    border-radius: 6px;
    align-items: center;
    flex-direction: column;
    padding: 1.2rem;
    max-width: 400px;
}

.custDetailsBox svg {
    margin-bottom: 24px;
}

form {
    margin-bottom: 0;
    /*text-align: center;*/
}

.phonePreference {
    min-width: 150px;
}

.inputGroup {
    margin-bottom: 24px;
}

.inputGroup input {
    border-radius: 0 3px 3px 0;
}

.inputGroup .form-control {
    border-right: var(--Input__Border);
}

.inputGroup .dropbtn {
    border-radius: 3px 0 0 3px;
}

.floatingLabel {
    font-size: 0.75rem;
    color: #5D738D;
    letter-spacing: 0.38px;
    font-weight: 500;
    position: absolute;
    top: -8px;
    left: 23px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    background-color: #fff;
    margin-bottom: 0rem;
    z-index: 1;
}

.serviceAddressFields {
    text-align: left !important;
}

.mesgDiscl {
    font-size: 11.5px !important;
    text-align: center;
}

.verifyDropContainer {
    width: 50%;
}

.btnWrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

@media (max-width: 767px) {
    .btnWrapper {
        display: block !important;
        width: 100% !important;
    }
    .btnWrapper button {
        margin-bottom: 1rem;
        margin-top: 24px;
    }
}