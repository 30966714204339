@import url("../../css/VARIABLES.css");

.modal-header.suggestedAddress {
  flex-direction: column;
  padding-bottom: 0;
  border-bottom: none;
}

.modalFooterButtonRow button {
  border-radius: 0 !important;
}

.radioBox input[type="radio"]:checked + label {
  border: 1px solid var(--RadioAsButton__CheckedBorder);
}

.radioControlsLabel {
  -ms-flex-align: baseline;
  align-items: baseline;
  color: var(--PrimaryTextColor);
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
  padding: 1rem 3rem 1rem 4rem;
  position: relative;
  text-align: left;
  border: var(--Input__Border);
  margin: 0.25rem 0 1rem;
  border-radius: 3px;
  width: 100%;
}

.addressContainer {
  -ms-flex-align: baseline;
  align-items: baseline;
  color: var(--PrimaryTextColor);
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
  padding: 1rem 3rem 1rem 3rem;
  position: relative;
  text-align: left;
  border: var(--Input__Border);
  margin: 0.25rem 0 1rem;
  border-radius: 3px;
  width: 100%;
}

.radioBox input[type="radio"]:checked + .radioControlsLabel:after {
  background: linear-gradient(225deg, #1352de, #001871);
  border-radius: 50%;
  content: "";
  height: 16px;
  left: 12px;
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  width: 16px;
}

.radioControlsLabel:before {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border-radius: 50%;
  border: var(--Input__Border);
  content: "";
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex: 0 0 18px;
  flex: 0 0 31px;
  height: 24px;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 24px;
  transition: background 0.3s ease;
  width: 24px;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translate(0, -50%);
}

.radioBox input[type="radio"]:checked + .radioControlsLabel:before {
  border-color: var(--RadioAsButton__CheckedBorder);
}

.radioBox input[type="radio"] {
  left: -9999px;
  opacity: 0;
  position: absolute;
}

.radioBoxWrapper {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.radioBox {
  position: relative;
  width: 290px;
}

.addressRadioLabel {
  color: var(--PrimaryTextColor);
  text-align: left;
  font-weight: 600;
  margin-bottom: 0;
}

.editButton {
  display: flex;
}

.addressH3 {
  color: var(--PrimaryTextColor);
  margin-bottom: 0.2rem;
  font-size: 1rem;
  line-height: 1.33;
  text-align: left;
}

.addressParagraph {
  color: var(--PrimaryTextColor);
  text-align: left;
  font-weight: 600;
  margin-bottom: 0;
}
