.forgotPlanBackground {
    background: url('../../assets/images/Vendor/Cutler/forgot-plan.png') #FFF no-repeat top right;
    background-size: 30%;
}

.forgotPlanCard {
    padding: 60px 67px;
    border: none;
    margin-left: 75px;
    margin-top: 70px;
    margin-bottom: 70px;
    box-shadow: 28px 24px 68px 36px rgba(50, 50, 50, 0.06);
}

.mainContainer {
    padding: 0;
}

@media screen and (max-device-width: 768px) {
    .forgotPlanBackground {
        background-size: 39% 60%;
        background-position-x: 103%;
    }

    .forgotPlanCard {
        padding: 60px 78px;
        margin: 30px 0;
    }
}

@media screen and (max-device-width: 430px) {
    .forgotPlanBackground {
        background: none;
    }

    .forgotPlanCard {
        padding: 30px 24px;
        background: #f9f9f9;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
    }
}

@media screen and (max-device-width: 991px){
    .forgotPlanCard {
        margin-left: 0px;
    }
}