@import url('../../../css/VARIABLES.css');

.helpBtnWrapper {
    position: relative;
    position: fixed;
    bottom: 40px;
    right: 40px;
    color: var(--PrimaryTextColor);
    font-size: 16px;
}

    .helpBtnWrapper a, .helpBtnWrapper a:hover {
        color: var(--LinkColor);
        padding: 0;
        font-weight: 600;
        cursor: pointer;
    }

        .helpBtnWrapper a:first-child {
            color: var(--PrimaryTextColor);
            padding: 0;
            font-weight: normal;
        }

.helpBtn {
    font-size: 1rem;
    line-height: inherit;
    padding: 8px 15px;
    transition: all .3s ease;
    align-items: center;
    border-radius: 100px;
    font-weight: 600;
    cursor: pointer;
    justify-content: center;
    letter-spacing: .5px;
    text-align: center;
    background: var(--Color__Neutral2);
    color: var(--PrimaryBrandColor);
    border: 2px solid var(--PrimaryBrandColor);
}

    .helpBtn::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 60%;
        bottom: 1%;
        border-right: 2px solid var(--PrimaryBrandColor);
        border-bottom: 2px solid var(--PrimaryBrandColor);
        transform: translate(-50%,50%) rotate(45deg);
        background-color: var(--Color__Neutral2);
        box-shadow: 0 2px 4px 0 rgba(0,87,184,0.01);
    }

    .helpBtn:focus{
        outline-color: var(--BtnAndInput__ActiveBorder);
    }

.helpTipText {
    background: #fff;
    padding: 40px;
    position: absolute;
    right: 0;
    bottom: 60px;
    width: 340px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
}


    .helpTipText h4 {
        color: var(--PrimaryTextColor);
        margin-bottom: 0;
    }

    .helpTipText .noBullets {
        margin-bottom: 24px;
    }

    .helpTipText .close {
        background-color: transparent;
        border: none;
        background: url('../../../assets/images/close.svg');
        background-repeat: no-repeat;
        height: 13px;
        width: 13px;
        margin: auto 0.35rem auto 0;
        position: absolute;
        top: 20px;
        right: 20px;
    }


@media (max-width: 767px) {
    .helpTipText {
        width: 300px;
        overflow: auto;
    }
}


@media screen and (max-width: 767px) {
    .helpBtnWrapper a:first-child {
        cursor: pointer;
        color: var(--LinkColor);
        font-weight: 600;
    }
}
