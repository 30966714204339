@import url('../../../css/VARIABLES.css');

.autoMargin {
    margin: 0 auto;
}
.instructions {
    color: var(--PrimaryTextColor);
    font-size: 24px;
    text-align: center;
}
.gridWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 32px;
    width: 100%;
}

.intialText{
    color: var(--PrimaryTextColor);
    font-size: 24px;
    font-weight:500;
    text-align:center;
    display:block;
}

.contentWrapper {
    max-width:800px;
}

.arrowButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    font-weight: 600;
    font-size: 24px;
    padding: 0px 8px;
}

.arrowButton:hover {
    cursor: pointer;
}

.arrowIcon {
    height: 32px;
    width: 32px;
    fill: var(--PrimaryBrandColor);
}

.arrowButton:hover .arrowIcon{
    fill: var(--BtnSecondary__HoverBg);
}

.arrowButton.disabled .arrowIcon {
    fill: var(--Color__Neutral3);
}

.arrowIcon.left {
    transform: rotate(180deg);
}
.arrowIcon.right {
    transform: rotate(0deg);
}
.arrowIcon.up {
    transform: rotate(-90deg);
}
.arrowIcon.down {
    transform: rotate(90deg);
}

.tableAndLegendWrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    flex: 1;
    max-width: 85%; 
}

.datesContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
}

.datesContainer[data-gridLength='2'] {
    justify-content: flex-start;
}

.datesContainer[data-gridLength='2'] .dayWrapper {
    flex: 1 0 160px;
    max-width: 160px;
}

.dayWrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    width: 125px;
    padding: 24px 16px;
}

.timeslotWrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.dayWrapper:not(:last-child) {
    border-right: 1px solid var(--Color__Neutral3);
}

.dateDay {
    color: var(--PrimaryTextColor);
    margin-bottom: 16px;
    text-align: center;
}

.dateDay .dayOfWeek {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 1px;
}

.dateDay .date {
    line-height: 14px;
}

.timeslot {
    height: 45px;
    width: 100%;
    max-width: 125px;
    font-weight: bold;
    background-color: var(--InputDisabled__Bg);
    border: var(--InputDisabled__Border);
    color: var(--InputDisabled__Text);
    display: flex;
    justify-content: center;
    align-items: center;
}

.timeslot input[type="radio"] {
    display: none;
}

    .timeslot input[type="radio"][disabled] + label {
        pointer-events: none!important;
    }

.timeslot label {
    margin-bottom: 0rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .1s ease-out;
}

    .timeslot input[type="radio"]:checked + label {
        background-color: var(--SecondaryBrandColor);
        border: var(--SecondaryBrandColor);
        color: var(--BtnPrimary__Text);
    }

.available {
    background-color: var(--PrimaryBrandColor);
    color: var(--BtnPrimary__Text);
    border: none;
}

.timeslot.available:hover {
    opacity: 0.9;
}

.timeslot:not(:last-child) {
    margin-bottom: 8px;
}

.mobileButtonWrapper {
    display: flex;
    width: 100%;
}

.mobileButtonWrapper .arrowButton {
    padding: 8px 0;
    height: 40px;
}

.mobileButtonWrapper .arrowButton:first-child {
    margin-right: auto;
}

@media screen and (max-width: 991px) {
    .gridWrapper {
        flex-flow: column nowrap;
        width: 100%;
        /* max-width: 100%; */
    }

    .arrowIcon {
        height: 24px;
        width: 24px;
    }
    .dayWrapper {
        padding: 8px 0;
        width: 100%;
        border: none !important;
    }
    .dateDay {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        margin-bottom: 8px;
    }
    .dateDay .dayOfWeek {
        margin-right: 8px;
    }
    .datesContainer {
        flex-flow: column nowrap;
        width: 100%;
    }
    .datesContainer[data-gridLength='2'] {
        justify-content: flex-start;
        align-items: flex-start;
    }
    .datesContainer[data-gridLength='2'] .dayWrapper {
        flex: 0 0 auto;
        max-width: 100%;
    }
    .tableAndLegendWrapper {
        width: 100%;
        align-self: stretch;
        max-width: 100%;
    }
    .timeslotWrapper {
        flex-flow: row nowrap;
        justify-content: space-evenly;
    }
    .timeslot:not(:last-child) {
        margin-bottom: 0;
        margin-right: 4px;
    }
    .timeslot {
        max-width: 100%;
        font-size: 14px;
    }
}

/* =============================================================================
  Color Key
============================================================================= */

.legend {
    display: flex;
    align-items: center;
    padding: 16px 0;
    padding-left: 16px;
}

.legend > *:nth-child(even) {
    margin-right: 16px;
}

.legend > *:nth-child(odd) {
    margin-right: 8px;
}

.legend.key:first-child {
    margin-left: 0;
}

.key {
    height: 16px;
    width: 16px;
    flex: 0 0 auto;
}

.key.disabled {
    background-color: var(--InputDisabled__Bg);
    border: var(--InputDisabled__Border);
}

.key.selected {
    background-color: var(--SecondaryBrandColor);
}

@media screen and (max-width: 991px) {
    .legend {
        font-size: 12px;
        padding: 0;
        padding-bottom: 8px;
    }
    .legend > * {
        margin-right: 8px;
    }
}

/* =============================================================================
  Servicer Table
============================================================================= */

.servicerTable {
    border: none;
    overflow: scroll;
    width: 100%;
}

.servicerTable > tbody tr {
    cursor: pointer;
}

.servicerTable thead > tr {
    border-top: none !important;
}

/* =============================================================================
  Special Notes Field
============================================================================= */

.specialNotesWrapper {
    max-width: 360px;
    margin-top: 16px;
}

.notesLabel {
    color: var(--Input__LabelColor);
} 

.notesField {
    min-height: 125px;
}

.fullWidthNotesWrapper {
    width: 100%;
}

.fullWidthNotesWrapper p,.specialNotesWrapper p {
    text-align: right;
    font-size: 16px!important;
}

/* =============================================================================
  No Servicers Found screen
============================================================================= */

@media screen and (max-width: 767px) {
    .btnWrapperNew button:first-child {
        margin-bottom: 24px;
    }
    .infoText {
        width: 290px !important;
        right: -67px !important;
    }
    .infoText::after {
        right: 63px !important;
    }
}

@media screen and (min-width: 768px) and (max-width) {
    .infoText {
        width: 400px !important;
    }
}

.serviceOptions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.serviceOptions p {
    font-size: 24px;
}

.newRadioBtn input:checked + label,
.newRadioBtn label.selected,
.newRadioBtn input[type="radio"] + label:hover,
.newRadioBtn input[type="radio"] + label:focus {
    color: var(--RadioAsButton__CheckedText) !important;
    background-color: var(--RadioAsButton__CheckedBg) !important;
    border-color: var(--RadioAsButton__CheckedBorder) !important;
}

.newRadioBtn input[type="radio"] + label {
    padding: 15px 2rem 15px 2rem;
    border: var(--RadioAsButton__Border);
    background-color: var(--RadioAsButton__Bg);
    color: var(--RadioAsButton__Text);
    /* display: inline-block; */
    text-align: center;
}

.newRadioBtn input[type="radio"] {
    display: none;
    width: 0px;
    height: 0px;
}

.newRadioBtn {
    margin-bottom: 15px;
}

.ownServInfo svg {
    height: 17px;
    width: 17px;
    max-width: 17px;
    fill: var(--IconColor);
    margin-bottom: 3px;
}

.newRadioBtn:hover .ownServInfo svg,
.newRadioBtn:focus .ownServInfo svg,
.newRadioBtn input[type="radio"]:checked .ownServInfo svg {
    fill: var(--RadioAsButton__CheckedText);
    /* stroke: #fff; */
}

.infoIcon {
    fill: #fff !important;
}

.ownServInfo {
    border-bottom: none;
    margin-left: 6px;
    position: relative;
    text-align: left;
}

.infoText {
    visibility: hidden;
    height: auto;
    width: 500px;
    background-color: #fff;
    color: var(--PrimaryTextColor);
    padding: 24px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
    box-sizing: border-box;
    border: 1px solid var(--Color__Neutral1);
    position: absolute;
    z-index: 1;
    top: 140%;
    right: -127px;
    opacity: 0;
    font-size: 14px;
    transition: opacity 0.3s;
}

.infoText::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    right: 122px;
    top: -12px;
    border-left: 1px solid var(--Color__Neutral1);
    border-top: 1px solid var(--Color__Neutral1);
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
}

.ownServInfo:hover .infoText {
    visibility: visible;
    opacity: 1;
}

.affirmCheckLabel a,
.infoText a {
    display: contents;
    color: var(--LinkColor) !important;
    cursor: pointer;
}

.affirmCheckLabel {
    margin-bottom: 0rem;
    text-align: left !important;
}