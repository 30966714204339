.flexContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.chatEndContainer {
    box-shadow: none;
    border: none;
    top: 200px;
}

@media (max-width: 575.98px) {
    .chatEndContainer {
        top: 20px;
    }
}
