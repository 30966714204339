@media (min-width: 768px) and (max-width: 991px) {
    .mapsvg svg {
        width: 723px !important;
    }
}

path.map,
.DC2 {
    pointer-events: all;
    fill: #f0f0f0;
    opacity: 0.7;
    stroke: #343741;
    stroke-width: 0.77px;
}

path.map:hover,
.DC2:hover {
    fill: #b6cbf4;
    cursor: pointer;
    stroke: none;
    stroke-width: 0%;
    color: #fff !important;
}