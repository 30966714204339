@import url('../../../css/VARIABLES.css');

.questionCard {
    margin-bottom: 1rem;
    box-shadow: none;
    border: none;
}

.questionCard:last-of-type{
    margin-bottom: 0;
}

.questionCard .cardHeader {
    min-height: 67px;
    border: 1px solid var(--Color__Neutral3);
    cursor: pointer;
}

.questionCard .cardHeader:hover {
    border: 1px solid var(--ActiveTab);
}

.question p {
    margin-bottom: 0px;
}
.arrow{
    margin-top: 4px;
    width:20px;
    height:20px;
    fill: var(--IconColor);
}

.arrow.closed {
    transform: rotate(0deg) !important;
    transition: transform 0.3s;
    margin-left: 10px;
}

.arrow.active {
    transform: rotate(180deg) !important;
    transition: transform 0.3s;
    margin-left: 10px;
}

.question {
    display: flex;
}

.question p:first-of-type {
    color: var(--PrimaryTextColor);
}

.answer p:last-of-type {
    margin-bottom: 0;
}

.answer {
    border: 1px solid var(--Color__Neutral3);
    border-top: none
}

.accordionFaq {
    display: flex;
    justify-content: space-between;
}