@import url("../../../../css/VARIABLES.css");

.cardTitle h2.h3:first-child {
    color: #343741;
    margin-bottom: 0;
    font-weight: normal;
    font-size: 24px;
}

.amount {
    color: var(--SecondaryBrandColor);
    font-weight: 600;
    margin-bottom: 40px;
}

.infoIcon {
    height: 17px;
    fill: var(--IconColor);
    max-width: 25px;
}

.tooltiptext {
    width: 290px;
    top: 45px;
    left: -22.5px;
}

.inputTooltip, .inputContainer {
    position: relative;
}

.hasTooltip {
    position: absolute;
    right: 14px;
    bottom: 0;
    height: 100%;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inputTooltip .hasTooltip {
    border-bottom: none;
}

.tooltipButton svg {
    vertical-align: top;
}

.tooltipButton, .tooltipButton:hover {
    border: none;
    padding: 0 !important;
    display: inline !important;
    background: none;
    margin: -4px 0 0 5px;
    width: 16px !important;
    height: 16px !important;
    color: #333 !important;
}

    .tooltipButton:focus {
        width: 16px;
        height: 16px;
        background: none !important;
    }

.dedbtn {
    margin-top: 5px;
    height: 30px;
    border: none;
    padding: 8px 24px;
    height: 40px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    color: var(--PrimaryTextColor);
    background-color: var( --Color__Neutral2) !important;
}

.deductibleTotal {
    font-size: 1.125rem;
    line-height: 1.33;
    margin-left: 0.5rem;
    color: var(--PrimaryTextColor);
}

.ccPin {
    height: 45px;
    width: 68px;
    margin-bottom: 12px;
}

.payLogo {
    height: 25px;
    padding-right: 5px
}

.payOption {
    flex-grow: 1;
    margin: 0;
}
.payOption.applePay {
    margin-right: 16px
}

.payOption label {
    width: 100%
}

.payOption input[type="radio"] + label {
    padding: 24px 28px;
    font-weight: 600;
    margin:0px;
    min-width: 150px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.payOption:last-child input[type="radio"] + label {
    margin-right: 0;
}

.ml15 {
    margin-left: 15px;
}

.btnPay {
    width: 209px;
}

.mb24 {
    padding: 0 .5rem !important;
}

@media (max-width: 767px) {
    .dedbtn {
        margin-bottom: 32px;
    }

    .ml15 {
        margin-left: 0;
    }

    .mb24 {
        margin-bottom: 24px;
        padding: 0 .5rem !important;
    }
    .payOption input[type="radio"] + label {
        min-width: 100%;
    }
}

/* HTML */
/* <div class="apple-pay-button apple-pay-button-black"></div> */

.paymentOptionsWrapper {
    display: flex;
    justify-content: stretch;
    align-items: center;
    width: 100%
}

/* CSS */
@supports (-webkit-appearance: -apple-pay-button) { 
    .apple-pay-button {
        display: inline-block;
        -webkit-appearance: -apple-pay-button;
        /* height: 200px; */
        max-height: none;
        /* flex-grow: 1; */
        /* width: 50%; */
        /* margin-right: 16px; */
    }
    .apple-pay-button-black {
        -apple-pay-button-style: black;
    }
    .apple-pay-button-white {
        -apple-pay-button-style: white;
    }
    .apple-pay-button-white-with-line {
        -apple-pay-button-style: white-outline;
    }
}


@supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        background-size: 100% 60%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border-radius: 5px;
        padding: 0px;
        box-sizing: border-box;
        min-width: 200px;
        min-height: 32px;
        /* height: 200px; */
        max-height: 64px;
        /* flex-grow: 1 */

    }
    .apple-pay-button-black {
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-color: black;
    }
    .apple-pay-button-white {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
    }
    .apple-pay-button-white-with-line {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
        border: .5px solid black;
    } 
}