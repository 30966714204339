@import url('../../../css/VARIABLES.css');

.pageHeader {
    min-height: 229px;
    background-color: var(--Color__Neutral1);  
}

.pageHeaderContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 229px;
}

.pageHeader h1 {
  margin-bottom: 0rem;
}

.retailerSelection {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*margin-bottom: 18rem;*/
  margin-top: 3rem;
  text-align: center;
}

.retailerSelection .radioButton {
  margin-bottom: 3rem;
}

.radioGroup label {
  margin: .5rem .5rem 1rem .5rem !important;
  min-width: 148px;
}

@media (max-width: 767px) {
    .radioGroup label {
        min-width: auto;
    }

    .pageHeaderContent {
        align-items: flex-start !important
    }
}

/* Coverage Documents */

.paymentPlanSelect {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popOverLM {
  max-width: 509px !important;
  padding: 0.8rem;
}

.popOverLM ul {
  padding-left: 1rem;
  margin-bottom: 0px;
}

.popOverLM ul li {
  margin-bottom: 1rem;
}

.popOverLM ul li:last-child {
  margin-bottom: 0px;
}

.closeBtn {
  background-color: transparent !important;
  border: none !important;
}

.closeBtn img {
  height: 13px;
}

.popOverLMHead {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.btnLinkCustom {
  font-weight: normal;
}

.radioGroupStates label {
  margin: .5rem .5rem 1rem .5rem !important;
  padding: 3px 2rem 3px 2rem !important;
  min-width: 148px;
}