@import url("../../css/VARIABLES.css");

.notificationHeader {
    border: var(--Notification__Border);
    color: var(--Notification__HeaderText);
    padding: 16px 40px;
    background: var(--Notification__HeaderBg);
    font-size: calc(1.125rem * 1.173 * 1.173);
    font-weight: 600;
}

.notificationHeader.ET {
    padding: 8px 40px;
    margin-top: 2px;
    border: none;
    border-bottom: var(--Notification__Border);
}

.notificationCardbody div.row {
    margin-bottom: 8px;
}

.notificationCard a {
    color: var(--Notification__Link);
    text-decoration: underline;
}

.notificationTitle {
    color: var(--Notification__Text) !important;
    font-weight: 600;
    font-size: 16px;
}

.notificationDescription {
    color: var(--Notification__Text) !important;
}

.notificationCard {
    background: var(--Notification__Bg);
    color: var(--Notification__Text);
    box-shadow: none;
    border: none;
}

.notificationBtn {
    color: var(--Notification__Link);
}

.notificationCard.ET {
    border-radius: 10px;
    margin-top: 15px;
}

@media (max-width: 767px) {
    .notificationCard {
        margin-top: 24px;
    }
}
