.footerButtonsRowWrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.mainButtonsContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.mainButtonsContainer > .modalVersion {
    margin-left: auto;
}

.modalVersion {
    display: flex;
}
.mainButtonsContainer.modalVersion {
    justify-content: space-between;
}

