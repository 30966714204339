@import url('../../css/VARIABLES.css');

.verifyCodeBackground {
    background: url('../../assets/images/Vendor/Cutler/account-verification.png') #FFF no-repeat top right;
    background-size: 30%;
}

.verifyCodeCard {
    padding: 60px 70px;
    border: none;
    margin-left: 75px;
    margin-top: 70px;
    margin-bottom: 70px;
    box-shadow: 28px 24px 68px 36px rgba(50, 50, 50, 0.06);
}

    .verifyCodeCard input {
        flex-grow: 1;
        border: 1px solid var(--Color__Neutral3);
        flex-basis: 0;
        height: 58px;
    }

        .verifyCodeCard input:not(:last-child) {
            margin-right: 8px;
        }

.mainContainer {
    padding: 0;
}

.btnLink {
    margin-top: 40px;
    cursor: pointer;
}

@media screen and (max-device-width: 768px) {
    .verifyCodeBackground {
        background-size: 39% 60%;
        background-position-x: 103%;
    }

    .verifyCodeCard {
        padding: 60px 78px;
        margin: 30px 0;
    }
}

@media screen and (min-width: 819px) and (max-device-width: 1023px) {
    .verifyCodeCard {
        padding: 48px;
    }
}

@media screen and (max-width: 991px) {
    .verifyCodeCard {
        margin-left: 0px;
    }
}

@media screen and (max-device-width: 430px) {
    .verifyCodeBackground {
        background: none;
    }

    .verifyCodeCard {
        padding: 30px 24px;
        background: #f9f9f9;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
    }

    .btnLink {
        margin-top: 32px;
    }
}
