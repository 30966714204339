@import url('../../../css/VARIABLES.css');

.inputWrap {
    max-width: 300px; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: var(--Input__Border);
}

@media (max-width: 767px) {
    .inputWrap {
        max-width: 100%;
    }
}

.dateInput {
    padding: 16px 24px;
    border: none;
    width: 100%;
}

.calendarIcon {
    height: 32px;
    fill: var(--IconColor);
    margin: 0 16px;
    cursor: pointer;
}

.calendar {
    margin-top: 26px;
    transform: translateX(-1px);
    border-radius: 0;
}