@import url("./VARIABLES.css");
@import url('./buttons.css');
@import url('./inputs.css');
@import url('./forms.css');
@import url('./typography.css');
@import url('./svg.css');

/* =============================================================================
================================================================================
================================================================================
==================================== GLOBAL ==================================== 
================================================================================
================================================================================
============================================================================= */

body {
    margin: 0;
    font-family: var(--TextFont);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: inherit;
    color: var(--PrimaryTextColor);
    background: #fff;
    min-width: 320px;
    overflow-x: hidden;
}

html,
body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

.contentWrapper {
    flex: 1 0 auto;
    position: relative;
    margin: 35px auto;
}

footer {
    flex-shrink: 0;
}

.container {
    max-width: var(--Container__MaxWidth);
}

@media (max-width: 767px) {
    .container {
        max-width: 100%;
    }
}


/* =============================================================================
  Utilities
============================================================================= */

.va-align-parent,
.alignParent {
    position: relative;
}

.va-center,
.centeredY {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.centeredXY {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hasTooltip {
    position: relative;
    font-weight: 400;
    border-bottom: 1px dashed var(--LinkColor);
    letter-spacing: 0.44px;
}

.tooltiptext {
    visibility: hidden;
    height: auto;
    width: 200px;
    background-color: #fff;
    color: var(--PrimaryTextColor);
    padding: 24px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
    box-sizing: border-box;
    border: 1px solid var(--Color__Neutral2);
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
}

    .tooltiptext::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 12%;
        top: -14%;
        border-left: 1px solid var(--Color__Neutral2);
        border-top: 1px solid var(--Color__Neutral2);
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
    }

.hasTooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.flex-v-center {
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.fiveColWrapper,
.twoColWrapper,
.multiColWrapper {
    margin: 0 -.5rem;
}

    .fiveColWrapper div[class^="col"],
    .twoColWrapper div[class^="col"],
    .multiColWrapper div[class^="col"] {
        padding: 0 .5rem !important;
    }

.twoColWrapper {
    margin-bottom: 24px;
}

@media (max-width: 575px) {
    .twoColWrapper div[class^="col"]:first-child {
        margin-bottom: 24px;
    }
}

.pr-11 {
    padding-right: .6875rem;
}

.pl-11 {
    padding-left: .6875rem;
}

.spinner-border {
    width: 1.5rem;
    height: 1.5rem;
}

.btn-primary:focus .spinner-border {
    color: var(--PrimaryBrandColor);
}

.save {
    width: 116px;
}

ul.noBullets {
    list-style: none;
    padding-left: 0;
}

ul.pagination {
    display: flex;
    justify-content: flex-end !important;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--PrimaryBrandColor);
    background-color: transparent;
    border: 1px solid var(--Color__Neutral3);
}

.page-item.active .page-link {
    color: #fff;
    background-color: var(--PrimaryBrandColor);
    border-color: var(--PrimaryBrandColor);
}

.page-link:hover {
    z-index: 2;
    color: #fff;
    text-decoration: none;
    background-color: var(--PrimaryBrandColor);
    border-color: var(--PrimaryBrandColor);
}

.show-lg-up {
    display: block;
}

.show-md-up,
.show-sm-down {
    display: none;
}

@media (max-width: 1410px) {
    .show-lg-up {
        display: none;
    }
}

@media (max-width: 767px) {
    .show-md-up,
    .show-sm-down {
        display: block;
    }
}

@media (min-width: 768px) {
    .mb-0-md {
        margin-bottom: 0;
    }
}


/* =============================================================================
 Accessibility 
============================================================================= */

input[type="radio"]:focus,
input[type="radio"]:active,
input[type="password"]:focus,
input[type="radio"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="color"]:focus,
textarea:focus,
legend:focus,
label:focus,
h1:focus,
div:focus {
    outline: auto;
    outline-color: Highlight !important;
}

input[type="text"]:focus,
input[type="checkbox"]:focus,
input[type="checkbox"]:active,
input[type="text"]:active {
    outline-color: var(--FocusColor) !important;
    border-color: var(--FocusColor) !important;
}


/* =============================================================================
================================================================================
================================================================================
==================================== PAGE ====================================== 
================================================================================
================================================================================
============================================================================= */


/* =============================================================================
  Header
============================================================================= */

header {
    border-bottom: 1px solid;
}

.logo {
    height: 38px;
}

.logoClose {
    width: 1.5em;
    height: 1.5em;
    position: absolute;
    right: 0;
    margin: 20px;
}

.languageSelect .dropbtn.USA:before {
    display: inline-block;
    content: ' ';
    background-image: url('../assets/images/Vendor/Apple/US-flag.svg');
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
    position: absolute;
    left: 0;
    top: 3px;
}

.languageSelect .dropbtn,
.languageSelect.dropdown:hover .dropbtn {
    border: none;
    background: none;
    display: inline;
    padding: 0px 48px 0px 24px;
    height: auto;
    color: var(--PrimaryTextColor);
    font-size: 14px;
}

    .languageSelect .dropbtn:after {
        display: inline-block;
        content: ' ';
        background-image: url(../assets/images/arrow-cheveron-down.svg);
        background-size: 12px 12px;
        height: 12px;
        width: 12px;
        position: absolute;
        right: 24px;
        top: 8px;
    }

.dropdown.languageSelect {
    display: inline-block;
}

.dropdown .dropdown-content .dropDownSelectedInput {
    width: 100%;
    border: none;
    margin: 0;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    padding: 12px 10px;
    justify-content: flex-start;
    font-weight: 200;
    color: var(--PrimaryTextColor);
}

.dropdown .dropdown-content .dropDownSelectedInput:hover {
    background: var(--DropOption__HoverBg);
    color: #fff;
}

.dropdown .dropdown-content .dropDownSelectedInput:active {
    background: inherit;
}


header {
    border-bottom: var(--Input__Border);
}

.nav-links,
.nav-links:hover {
    text-decoration: none;
    font-size: 14px;
}

nav {
    margin-top: auto;
    margin-bottom: auto;
}

    nav a {
        color: var(--PrimaryTextColor);
    }


/* =============================================================================
  Card and Modal
============================================================================= */

.card {
    border: 1px solid var(--Color__Neutral2);
    -webkit-box-shadow: 0 12px 24px 5px rgba(0, 87, 184, 0.06);
    box-shadow: 0 12px 24px 5px rgba(0, 87, 184, 0.06);
    padding: 0;
}

.card-body,
.modal-body {
    padding: 24px 40px;
}

.card-header,
.modal-header,
.modal-footer {
    background-color: #fff;
    padding: 24px 40px;
}

@media (max-width: 767px) {
    .modal-body,
    modal-header,
    .modal-footer {
        padding: 12px 20px;
    }
}

.card-header h2,
.modal-header h5 {
    /*font-size: 28px;
    line-height: 1.05;
    font-weight: 600;
    color: var(--Heading__Color);*/
    margin-bottom: 0;
}

.modal-footer .btnWrapper button {
    margin-left: 24px;
}

.modal-footer .btnWrapper .btnLink {
    display: inline;
}

.modal-footer > * {
    margin: 0;
}

.md {
    max-width: 764px;
}

.modal-md {
    max-width: 660px;
}

.modal-header.noLine {
    border-bottom: none;
}

.modal-body {
    text-align: center;
}

.multiBtnLayout {
    justify-content: space-between !important;
}

@media (max-width: 767px) {
    .modal-body {
        text-align: left;
    }

    .stepsFormBlock .btnWrapper {
        display: flow-root !important;
    }

    .multiBtnLayout {
        flex-direction: column;
        justify-content: space-between !important;
    }

        .multiBtnLayout .btnWrapper,
        .linkBtnLayout .btnWrapper {
            display: flex;
            flex-direction: column;
            padding: 0;
        }

            .multiBtnLayout .btnWrapper .btnLink,
            .linkBtnLayout .btnWrapper .btnLink {
                margin: 16px 0 0;
                font-size: 1rem;
                line-height: inherit;
                padding: 15px 40px;
                transition: all .3s ease;
                align-items: center;
                border-radius: 100px;
                font-weight: 600;
                cursor: pointer;
                justify-content: center;
                letter-spacing: .5px;
                text-align: center;
                color: var(--BtnDisabled__Text);
                background: #fff;
                border: var(--BtnSecondary__Border);
            }

            .multiBtnLayout .btnWrapper button,
            .linkBtnLayout .btnWrapper button,
            .stepsFormBlock .btnWrapper button {
                margin: 16px 0 0;
            }
}


/* =============================================================================
  FOOTER
============================================================================= */

footer {
    background: #4A4A49;
    padding: 48px 0;
    font-size: 14px;
    color: #fff;
    margin-top: 24px;
}

    footer p {
        margin-bottom: 0;
    }

    footer a {
        padding: 0 1rem 0 1rem;
        text-decoration: none;
        color: #fff;
    }

.horzList li:first-child a {
    padding-left: 0;
}

footer a:hover {
    text-decoration: underline;
    color: #fff;
}

footer a:focus {
    outline-color: Highlight;
}

.horzList {
    list-style-type: none;
    display: inline-flex;
    padding-left: 0;
    text-decoration: none;
}

ul.horzList li:after,
footer ul.horzList li:after,
ul.horzList li:after {
    content: "|";
    color: #fff;
}

ul.horzList li:last-child:after,
footer ul.horzList li:last-child:after {
    padding: 0 .25rem 0 0.500rem;
    content: "";
}

.preFooterText {
    color: var(--PrimaryTextColor);
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 20px;
    font-size: 14px;
  }

@media (max-width: 992px) {
    footer {
        padding: 24px 0;
    }
}


/* =============================================================================
================================================================================
================================================================================
================================ MISCELLANEOUS ================================= 
================================================================================
================================================================================
============================================================================= */


/* =============================================================================
  Progress Bar
============================================================================= */

.circle {
    width: 21px;
    height: 21px;
    position: relative;
    border-radius: 50%;
}

.progressStep.active .circle {
    border: 1px solid var(--ProgressActive);
}

.progressStep.completed .circle {
    border: 1px solid (var(--ProgressActive));
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=);
    background-color: var(--ProgressActive);
    background-position: center;
    background-repeat: no-repeat;
}

.progressStep.completed .circlePoint,
.progressStep.completed .selectedPoint,
span.circle.last::after {
    display: none;
}

.circlePoint {
    width: 5px !important;
    height: 5px !important;
    border-radius: 50%;
    background: var(--ProgressActive);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pageTxt {
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: .65rem;
}

.progressStep.active .pageTxt {
    background: var(--ProgressActive);
    color: var(--ProgressActive__Text);
    position: relative;
    padding: 4px 12px 4px 12px;
}

    .progressStep.active .pageTxt::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: var(--ProgressActive);
        box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
    }

.progressStep.active .selectedPoint {
    display: block;
}

span.circle::after {
    content: " ";
    width: 6.1rem;
    height: 1px;
    top: 0.64rem;
    left: 1.8rem;
    background: #979797;
    position: absolute;
}

.progressBar {
    margin: 0rem;
    margin-bottom: 0.8rem;
    justify-content: center;
}

.progressStep {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 3.77rem;
}

    .progressStep:last-child {
        margin-right: 0;
    }

    .progressStep div {
        display: flex;
        justify-content: center;
    }

/* =============================================================================
 Badges
============================================================================= */
.badge {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem;
    /*margin-left: 10px;*/
    font-weight: 600;
}

.badge-success,
.success {
    background-color: #13AA41;
}

.badge-warning,
.warning {
    background-color: #FFBF3F;
}

.badge-danger,
.danger {
    background-color: #E03C31;
}

.warning {
    color: #212529;
}

.circleBadge {
    height: 8px;
    width: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
}


/* =============================================================================
  Skip to Content
============================================================================= */

.skip_nav {
    background: var(--Color__Neutral5);
    text-align: center;
}

    .skip_nav a {
        width: 100%;
        font-size: 20px;
        background: transparent;
        letter-spacing: 0.5px;
        color: var(--ProgressActive__Text);
        line-height: 25px;
        position: absolute;
        left: -10000px;
        display: block;
        text-decoration: underline;
        font-weight: 500;
    }

        .skip_nav a:focus {
            position: static;
            color: black !important;
        }

header.header {
    padding: 10px 0;
}

@media (min-width: 768px) {
    .show-md-up {
        display: block;
    }
}

@media (min-width: 992px) {
    .show-lg-only {
        display: block;
    }
}


/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1200px) {
}


/*Large devices (desktops, 992px and up)*/

@media (min-width: 992px) {
}


/*Medium devices (tablets, 768px and up)*/

@media (min-width: 768px) {
}


/*Small devices (landscape phones, 767px and down)*/

@media screen and (max-width: 767px) {
    a.callLink,
    .validation-summary a.callLink {
        text-decoration: underline;
        cursor: pointer;
    }
}


/*Small devices (landscape phones, 576px and up)*/

@media screen and (max-width: 576px) {
    .pr-11,
    .pl-11 {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.nav-tabs .nav-link {
    text-align: center;
    padding: 1.5rem 0;
    border: var(--Table__Border);
    border-right: none;
    border-top: 3px solid #fff;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 0;
}

.my-short-and-sweet-counter {
    display: block;
    text-align: right;
}

.header-content {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: (var(--Heading__Color));
    z-index: 99;
}

    .header-content .modal-content {
        height: 100%;
        background: var(--PrimaryBrandColor);
    }
    .et-header-content .modal-content {
        height: 100%;
        background: var(--SecondaryBrandColor);
    }

    .header-content .nav-links {
        text-decoration: none;
        font-size: 22px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
    }

    .header-content .navbar-nav {
        align-items: center;
        margin-top: 50px !important;
    }

    .header-content .nav-item {
        margin-top: 16px;
    }


/* 
Moved to module css of Apple header component.
.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border: none !important;
    padding: 0;
}

.navbar {
    padding: 0.5rem 0;
} */

input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
    color: transparent;
}

.close {
    background-color: transparent;
    border: none;
    background: url('../assets/images/close.svg');
    background-repeat: no-repeat;
    height: 13px;
    width: 13px;
    margin: auto 0.35rem auto 0 !important;
    position: absolute;
    top: 20px;
    right: 6px;
    background-size: 13px;
    opacity: 1;
}

    .close span {
        opacity: 0;
    }

.visually-hidden {
    visibility: hidden;
    width: 0;
}

.customRadio {
    display: block !important;
    /*max-width: 120px !important;*/
}

.paymentCustomRadio {
    display: inline-block !important;
    margin-right: 16px;
}

.programRadio {
    display: inline-block !important;
    margin-right: 16px;
}

    .programRadio label {
        margin: 0 !important;
    }

.discl {
    font-size: 14px !important;
    text-align: center;
}

.symptomGroup .radioButton input[type="radio"] + label {
    width: 100%;
    margin-bottom: .5rem;
}

.symptomGroup {
    margin: 0 -.5rem;
}

    .symptomGroup .col-md-6 {
        padding: 0 .5rem !important;
    }

#troubleshootingsteps ul {
    padding-left: 1rem;
    margin-bottom: 2rem !important;
    text-align: left !important;
}

    #troubleshootingsteps ul li {
        margin-bottom: .25rem;
    }

#troubleshooting ul {
    padding-left: 1rem;
}

    #troubleshooting ul li {
        margin-bottom: .25rem;
    }

#payOption .radioButton input[type="radio"] + label {
    padding: 24px 28px;
    margin: .5rem 1rem 1rem 0;
    font-weight: 600;
}

#payOption .radioButton:last-child input[type="radio"] + label {
    margin-right: 0;
}

.navbar-light .navbar-toggler {
    border: none !important;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .paymentCustomRadio {
        margin-bottom: 1rem;
    }

    .navbar-light .navbar-toggler {
        border: none !important;
    }
}

@media screen and (max-width: 768px) {
    .ascending:after,
    .descending:after {
        top: 59px !important;
    }

    .header-content .navbar-nav {
        margin-top: 0 !important;
    }
}

.ascending:after {
    content: ' ';
    background-image: url(../assets/images/blue-arrow.svg);
    background-color: transparent;
    width: 8px !important;
    background-repeat: no-repeat;
    margin-left: 8px;
    top: 35px;
    position: absolute;
    height: 12px;
}

.descending:after {
    content: ' ';
    background-image: url(../assets/images/blue-arrow.svg);
    background-color: transparent;
    width: 8px !important;
    background-repeat: no-repeat;
    margin-left: 8px;
    top: 35px;
    transform: rotate(-180deg);
    position: absolute;
    height: 12px;
}
