.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.relative {
    position: relative
}
.relative:disabled {
    cursor: default;
}
  
.hideText {
    visibility: hidden;
}