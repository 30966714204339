@import url('../../../css/VARIABLES.css');

@media (max-width: 992px) {
    .hwaFooter {
        padding: 24px 0px!important;
    }
}

.hwaFooter {
    font-size: 12px;
    color: var(--PrimaryTextColor);
    background: #fff;
    padding: 48px 0px;
}

.horzList {
    list-style-type: none;
    display: inline-flex;
    padding-left: 0;
    text-decoration: none;
}

.horzList li:first-child ::after {
    content: "|";
    color: var(--PrimaryTextColor);
    margin-left: 16px;
}

.horzList li a:first-child {
    padding-right: 16px;
}

.horzList li a {
    color: var(--LinkColor);
    padding: 0px;
}

.hwaFooter p {
    margin-bottom: 16px;
    letter-spacing: 0.43px;
}