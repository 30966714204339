.confirmSection {
    text-align: center;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    padding: 75px 0;
}

.errorIcon {
    margin-bottom: 26px;
    height: 120px !important;
}

.confirmBold {
    margin-bottom: .25rem !important;
    font-weight: 600;
}
