@import url('../../css/VARIABLES.css');

.overviewBody {
    flex: 1 0 auto;
    position: relative;
}

.searchIcon {
    fill: var(--LinkColor);
    height: 16px;
    width: 16px;
    margin-right: .25rem;
    transform: rotate(90deg);
}

.search {
    display: flex;
    align-items: center;
    color: var(--LinkColor) !important;
    font-size: 16px;
    font-weight: 600;
    margin: 0px 14px;
    cursor: pointer;
}

.rtCol {
    background: var(--Color__Neutral1);
    padding: 24px 32px;
    line-height: 1.8;
    font-size: 15px;
    border-radius: 6px;
    max-width: 416px;
}

.hoverHand {
    cursor: pointer;
}

.overviewSection {
    margin-top: 30px;
}

    .overviewSection:first-child {
        margin-top: 0;
    }

.allUpdate {
    color: var(--LinkColor);
    font-size: 15px;
    font-weight: bold;
}


.contentWrapper {
    flex: 1 0 auto;
    position: relative;
    margin: 38px auto;
    max-width: 1140px;
}


.btmLine {
    border-bottom: 1px solid var(--Color__Neutral3);
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.deviceInfo {
    position: relative;
    padding-left: 80px;
    margin-bottom: 1rem;
    min-height: 50px;
}

.deviceImg {
    position: absolute;
    left: 15px;
    top: 0;
    width: 67px;
    height: 67px;
    fill: var(--PrimaryTextColor)
}

.btmLine {
    border-bottom: 1px solid var(--Color__Neutral3);
    padding-bottom: 16px;
    margin-bottom: 32px;
}

.ccImage {
    width: 50px;
    margin-right: 8px;
}

.claimDetailsH6 {
    color: var(--PrimaryBrandColor);
    margin-bottom: .5rem;
    font-size: 1.125rem;
    line-height: 1.33;
}

.claimInfo {
    margin-bottom: 40px;
}

.caseTitle {
    color: #343741;
    text-align: right;
    margin: auto 0;
}

.estArrival {
    font-weight: normal;
    color: var(--PrimaryTextColor);
    margin-bottom: 0;
    font-size: calc(1.125rem * 1.173 * 1.173);
    line-height: 1.33;
}

.claimProgress {
    margin-top: 32px;
}

@media (max-width: 991px) {
    .rtCol {
        margin: 0 15px 32px;
    }
}

@media (max-width: 765px) {

    .claimInfo {
        margin-bottom: 16px;
    }

    .caseTitle {
        text-align: left;
    }
}

@media (max-width: 573px) {
    .rtCol {
        max-width: 100%;
    }
}
