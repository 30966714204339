@import url('../../../css/VARIABLES.css');
/**************************** 
 * Apple Landing page 
 ***************************/
.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  min-height: 200px;
  align-items: center;
}

.search {
  text-align: left;
  padding-bottom: 10px;
}

.appleform {
  text-align: center;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin: 24px 0;
}

.applelogo {
  margin-bottom: 30px;
}

.appleform h1 {
  color: #343741;
  margin-bottom: 40px;
}

.appleform h6 {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.carrierSelection {
    margin: 0;
    max-width: 550px;
    width: 100%
}

.carrier input[type="radio"] + label {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    padding: 12px;
    margin-bottom: 0;
    margin-top: 1rem;
}

    .carrier input[type="radio"] + label img {
        max-height: 28px;
        max-width: 86px;
    }

.carrier.col-sm-3 {
    padding: 0 .5rem !important;
}

.cardTitle {
    font-size: 18px;
}

.formBlock {
    justify-content: center !important;
}

/*Ignore header styling for Southbridge and bold Landing Page Text*/
.landingTextSB {
    font-weight: 600 !important;
}

.headerWrapper {
    width: 60%;
    font-size: 18px;
    margin: auto
}

.subHeaderWrapper {
    font-size: 18px;
}

@media screen and (max-width: 576px) {
    .contentWrapper {
        display: block;
    }

    .carrierSelection {
        min-width: 0;
    }
}



