.hero {
    min-height: 550px;
    background: linear-gradient(180deg,#f0f0f0,#d9e3ed);
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 24px 0;
}

.hero .card {
    padding: 32px;
}

.hero .card h1 {
    margin-bottom: 32px;
}

.hero button.btn-primary {
    margin: .5rem auto 1.5rem;
    background-color: #FDB813;
    border-color: #FDB813;
    color: #000000;
    font-weight: 600;
    width: 164px;
    height: 56px;
}
    .hero button.btn-primary:hover {
        background-color: #fba137;
        border-color: #fba137;
        color: #000;
    }

    .linkToPlanNum {
        text-align: center;
    }

    .linkToPlanNum a:not([href]):not([tabindex]), .linkToPlanNum a:not([href]):not([tabindex]):hover {
        color: #1352de;
    }

.hero .dropdown-content a:hover {
    color: #fff;
}
.phoneGroup{
    margin-top:-1.2rem !important;
}
 