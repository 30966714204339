@import url('../../../css/VARIABLES.css');
.onsiteInfo svg {
    fill: var(--IconColor);
    height: 17px;
    width: 17px;
}

.onsiteInfoTooltiptext {
    visibility: hidden;
    height: auto;
    width: 16rem;
    background-color: #fff;
    color: var(--PrimaryTextColor);
    padding: 16px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgb(0 87 184 / 1%);
    box-sizing: border-box;
    border: 1px solid var(--Color__Neutral2);
    position: absolute;
    z-index: 1;
    top: 30px;
    left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
    text-align: left;
    font-weight: normal !important;
    font-size: 14px !important;
    font-family: var(--TextFont) !important;
}

.onsiteInfoTooltiptext::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 109px;
    top: -13px;
    border-top: 1px solid var(--Color__Neutral2);
    border-left: 1px solid var(--Color__Neutral2);
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
}

.onsiteInfo:hover .onsiteInfoTooltiptext {
    visibility: visible;
    opacity: 1;
}

.onsiteInfo {
    position: relative;
    font-weight: normal !important;
    font-size: 14px;
}