@import url('../../css/VARIABLES.css');

.pageHeader {
    height: 229px;
    background-color: var(--Color__Neutral1);
}

.pageHeaderContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 229px;
}

.pageHeader p {
    max-width: 545px;
}

.termsForm {
    max-width: 400px;
    margin: 3rem auto;
}

.containerPadding {
    border: none;
    padding: 2rem 6rem;
}

.coverageHeader {
    color: var(--PrimaryBrandColor);
}

.coveredItemLabel {
    font-weight: bold;
    color: var(--PrimaryBrandColor);
}

.coverageCard {
    height: 100%;
    justify-content: flex-start;
}

.coverageCard .pageHeaderContent {
    justify-content: start;
}

.coverageItemDropdown {
    width: 300px;
}