
@import url('../../../css/VARIABLES.css');

.contactOptions {
    display: flex;
    justify-content: center;
    width: 100%
}

.contactOptions a {
    flex: 1 1 50%;
}

.optionButton {
    width: 100%;
    height: 100%;
    padding: 24px;
    background-color: white;
    border: var(--RadioAsButton__Border);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    max-width: 400px;
    cursor: pointer;
}

.optionButton div {
    display: flex;
    flex-direction: column;
}

.optionButton > div > p {
    margin-bottom: 0;
    color: var(--PrimaryTextColor);
} 

.optionButton > div > p:first-of-type {
    color: var(--Heading__Color);
} 

.moreQuestionsIcon {
    fill: var(--IconColor);
    margin-bottom: 8px;
}

.moreQuestionsIcon.phone {
    height: 32px
}

.moreQuestionsIcon.email {
    height: 28px
}

@media screen and (max-width: 767px) {
    .contactOptions {
        flex-direction: column-reverse;
    }

    .contactOptions a:first-of-type {
        margin-right: 0;
    }
    .optionButton {
        width: 100%;
        max-width: none;
        flex-direction: column;
        padding: 8px 16px;
        margin-bottom: 1rem;
    }

    .optionButton div {
        align-items: center;
    }
}

@media screen and (min-width: 767px) {
.contactOptions a:first-of-type {
    margin-right: 1.4rem;
}
}
