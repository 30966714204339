/* Common svg styles */
.primaryBrand{fill:#001871;}
.st0{fill-rule:evenodd;clip-rule:evenodd;}
.cls-1{fill:#3177a4;}.cls-2{fill:#fff;}
.cc-discover1{fill:#f8f8f8;}.cc-discover2{fill:#e6863c;}.cc-discover3{fill:url(#radial-gradient);}.cc-discover4{fill:#051721;}.cc-discover5{fill:#221f20;}
.cc-mastercard1{fill:#f8f8f8;}.cc-mastercard2{fill:#f0b34e;}.cc-mastercard2,.cc-mastercard3,.cc-mastercard4{fill-rule:evenodd;}.cc-mastercard3{fill:#d83738;}.cc-mastercard4{fill:#fff;}.cc-mastercard5{fill:none;stroke:#fff;stroke-miterlimit:2.61;stroke-width:0.22px;}
.cc-outline1{fill:none;}.cc-outline2{opacity:0.75;}.cc-outline3{fill:#4a4a49;fill-rule:evenodd;}
.cc-security1{fill:none;}.cc-security2,.cc-security3{fill:#b1c5d7;}.cc-security2{fill-rule:evenodd;}.cc-security4{isolation:isolate;}.cc-security5{fill:#134ee6;}
.cc-visa1{fill:#f8f8f8;}.cc-visa2{fill:#2866ab;}.cc-visa3{fill:#eda74d;}
.claimCancelled1{fill:none;}.claimCancelled2,.claimCancelled3,.claimCancelled9{fill:#2f7bf5;}.claimCancelled3{fill-opacity:0.52;}.claimCancelled4{fill:#1f58b7;}.claimCancelled5,.claimCancelled7{fill:#2058b1;}.claimCancelled5,.claimCancelled6{fill-rule:evenodd;}.claimCancelled6{fill:#73acf7;}.claimCancelled8{fill:#225dbb;}.claimCancelled9{fill-opacity:0.21;}.claimCancelled10{fill:#bf311c;}
.claimComplete1{fill:none;}.claimComplete2,.claimComplete3{fill:#2058b1;}.claimComplete3,.claimComplete4,.claimComplete6{fill-rule:evenodd;}.claimComplete4{fill:#73acf7;}.claimComplete5{fill:#225dbb;}.claimComplete6{fill:#1f58b7;}.claimComplete7{fill:#2f7bf5;fill-opacity:0.21;}.claimComplete8{fill:#86b8f8;}
.claimReview1{fill:none;}.claimReview2,.claimReview3,.claimReview9{fill:#2f7bf5;}.claimReview3{fill-opacity:0.52;}.claimReview4{fill:#1f58b7;}.claimReview5,.claimReview7{fill:#2058b1;}.claimReview5,.claimReview6{fill-rule:evenodd;}.claimReview6{fill:#73acf7;}.claimReview8{fill:#225dbb;}.claimReview9{fill-opacity:0.21;}
.claimSaved1{fill:none;}.claimSaved10,.claimSaved2,.claimSaved3{fill:#2f7bf5;}.claimSaved3{fill-opacity:0.52;}.claimSaved4{fill:#1f58b7;}.claimSaved5,.claimSaved7{fill:#2058b1;}.claimSaved5,.claimSaved6,.claimSaved9{fill-rule:evenodd;}.claimSaved6{fill:#73acf7;}.claimSaved8{fill:#225dbb;}.claimSaved9{fill:#4ea54f;}.claimSaved10{fill-opacity:0.21;}
.info1{fill:#1352de;fill-rule:evenodd;}.info2{fill:none;}
.sendButton1{fill: #00849b;stroke-linejoin: round;opacity: 0.8;}
.sendButton2{fill: #f5f4f4;font-size: 50px;font-family: PalatinoLinotype-Bold, Palatino Linotype;font-weight: 700;}
.sendButton3,.sendButton4{stroke: none;}.sendButton4 {fill: #707070;}
.toggle{fill:none;}
.chatPrimaryBrand{fill:#1352de;}
.chatAccent1{fill-rule:evenodd;clip-rule:evenodd;fill:#CCE3FD;}
.chatWarning{fill:#D01B00;}
.checkboxSelected{fill-rule:evenodd;clip-rule:evenodd;fill:url(#UI-_x2F_-Checkbox-_x2F_-Default_1_);}
.st1{fill:#FFFFFF;}
.checkbox{fill:#B9C6D4;}
.certified{fill-rule:evenodd;clip-rule:evenodd;}
.mobile{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
.close{fill-rule:evenodd;clip-rule:evenodd;fill:#231F20;}
.emailSecondaryBrad{fill:#1352de;}
.emailAccent1{fill:#CCE3FD;}
.emailAccent2{fill:#63ADFF;}
.emailGreenCheckBkg{fill:#4ea54f;}
.info0{fill-rule:evenodd;clip-rule:evenodd;fill:#2F7BF5;fill-opacity:0.21;}
.info1{fill-rule:evenodd;clip-rule:evenodd;fill:#1F58B7;}
.info2{fill:#1F58B7;}
.infoIcon1{fill:#1352de;fill-rule:evenodd}.infoIcon2{fill:none;}
.uploadAccent1CSC{fill-rule:evenodd;clip-rule:evenodd;fill:#EDF4F7;}
.uploadPrimaryBrandCSC{fill-rule:evenodd;clip-rule:evenodd;fill:#102247;}

/* Apple specific svg style */
.aigLogo{fill:#001871;}
.happyAccent1{fill-rule:evenodd;clip-rule:evenodd;fill:#CCE3FD;}
.happySecondaryBrand{fill:#1352DE;}
.neutralAccent1{fill-rule:evenodd;clip-rule:evenodd;fill:#CCE3FD;}
.neutralSecondaryBrand{fill-rule:evenodd;clip-rule:evenodd;fill:#1352DE;}
.sadEmojiAccent1{fill-rule:evenodd;clip-rule:evenodd;fill:#CCE3FD;}
.sadEmojiSecondaryBrand{fill:#1352DE;}
.flagDERed{fill:#FF0000;}
.flagDEYellow{fill:#FFCE00;}
.flagUSRed{fill:#BF0A30;}
.flagUSBlue{fill:#002868;}
.applePay1{fill:#231f20;}

/* ET Specific styles */
.calendarIllPrimaryET{fill:#102247;}
.calendarAccent1ET{fill:#00AEEF;}
.calendarAccent2ET{fill:#00AEEF;}
.phoneIllPrimaryET{fill:#102247;}
.phoneAccent1ET{fill:#00AEEF;}
.phoneAccent2ET{fill:#00AEEF;}
.chatIllPrimaryET{fill:#102247;}
.chatAccent1ET{fill-rule:evenodd;clip-rule:evenodd;fill:#00AEEF;}
.chatRedAlertET{fill:#D01B00;}
.checkboxBkgET{fill:#00AEEF;}
.checkboxBorderET{fill:#102247}
.checkET{fill:#102247;}
.checkboxET{fill:#102247;}
.cancelIllPrimaryET{fill:#102247;}
.cancelAccent1ET{fill:#00AEEF;}
.cancelAccent2ET{fill:#00AEEF;}
.cancelAlertRedET{fill:#D01B00;}
.replaceIllPrimaryET{fill:#102247;}
.replaceAccent1ET{fill:#00AEEF;}
.replaceAccent2ET{fill:#00AEEF;}
.successIllBrandET{fill:#102247;}
.successAccent1ET{fill:#00AEEF;}
.successAccent2ET{fill:#00AEEF;}
.successGreenCheckBkgET{fill:#4ea54f;}
.profileIllPrimaryET{fill:#102247;}
.profileAccent1ET{fill:#00AEEF;}
.profileAccent2ET{fill:#00AEEF;}
.emailIllPrimaryET{fill:#102247;}
.emailAccent1ET{fill:#00AEEF;}
.emailAccent2ET{fill:#00AEEF;}
.emailGreenCheckBkgET{fill:#4ea54f;}
.exclamationIllPrimaryET{fill:#102247;}
.exclamationAccent1ET{fill:#00AEEF;}
.contactIllPrimaryET{fill:#102247;}
.contactAccent1ET{fill:#00AEEF;}
.contactAccent2ET{fill:#00AEEF;}

/* HWA specific styles */
.st0Logo{fill:#f05d21;}
.st1Logo{clip-path:url(#SVGID_2_);fill:#f05d21;}
.st2Logo{fill:none;}
.st3Logo{fill:#DCDDDE;}
.calenderHWA0{fill:#00849B;}
.calenderHWA1{fill-rule:evenodd;clip-rule:evenodd;fill:#BFE0E6;}
.calenderHWA2{fill-rule:evenodd;clip-rule:evenodd;fill:#00849B;}
.calenderHWA3{fill-rule:evenodd;clip-rule:evenodd;fill:#97E0E6;}
.calenderHWA4{fill:#BFE0E6;}
.calenderHWA5{fill:#13AA41;}
.call0{fill:#BFE0E6;}
.call1{fill:#00849B;}
.call2{fill-rule:evenodd;clip-rule:evenodd;fill:#00849B;}
.call3{fill-rule:evenodd;clip-rule:evenodd;fill:#BFE0E6;}
.chatPrimaryBrandHWA{fill:#00849B;}
.chatAccent1HWA{fill-rule:evenodd;clip-rule:evenodd;fill:#BFE0E6;}
.chatWarningHWA{fill:#D01B00;}
.checkboxSelected0{fill-rule:evenodd;clip-rule:evenodd;fill:#DFDFDF;}
.checkboxSelected1{fill:#00849B;}
.checkboxSelected2{opacity:0.26;}
.checkboxHWA{fill:#B9C6D4;}
.claimCancelledHWA0{fill:none;}
.claimCancelledHWA1{fill:#BFE0E6;}
.claimCancelledHWA2{fill:#00849B;}
.claimCancelledHWA3{fill-rule:evenodd;clip-rule:evenodd;fill:#00849B;}
.claimCancelledHWA4{fill-rule:evenodd;clip-rule:evenodd;fill:#BFE0E6;}
.claimCancelledHWA5{fill:#BF311C;}
.claimCompleteHWA0{fill:none;}
.claimCompleteHWA1{fill:#00849B;}
.claimCompleteHWA2{fill-rule:evenodd;clip-rule:evenodd;fill:#00849B;}
.claimCompleteHWA3{fill-rule:evenodd;clip-rule:evenodd;fill:#BFE0E6;}
.claimCompleteHWA4{fill:#BFE0E6;}
.claimCompleteHWA5{fill:#2F7BF5;fill-opacity:0.21;}
.claimSavedHWA0{fill:none;}
.claimSavedHWA1{fill:#00849B;}
.claimSavedHWA2{fill:#BFE0E6;}
.claimSavedHWA3{fill-rule:evenodd;clip-rule:evenodd;fill:#00849B;}
.claimSavedHWA4{fill-rule:evenodd;clip-rule:evenodd;fill:#BFE0E6;}
.claimSavedHWA5{fill-rule:evenodd;clip-rule:evenodd;fill:#4EA54F;}
.st0Person{fill:#BFE0E6;}
.st1Person{fill-rule:evenodd;clip-rule:evenodd;fill:#00849B;}
.st2Person{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
.st3Person{fill-rule:evenodd;clip-rule:evenodd;fill:#BFE0E6;}
.st4Person{fill:#00849B;}
.emailSentHWA0{fill-rule:evenodd;clip-rule:evenodd;fill:#00849B;}
.emailSentHWA1{fill-rule:evenodd;clip-rule:evenodd;fill:#BFE0E6;}
.emailSentHWA2{fill-rule:evenodd;clip-rule:evenodd;fill:#01A740;}
.emailSentHWA3{fill:#BFE0E6;fill-opacity:0.21;}
.emailSentHWA4{fill:#BFE0E6;}
.emailSentHWA5{fill:#00849B;}
.exclaimationHWA0{fill-rule:evenodd;clip-rule:evenodd;fill:#BFE0E6;}
.exclaimationHWA1{fill-rule:evenodd;clip-rule:evenodd;fill:#00849B;}
.exclaimationHWA2{fill:#00849B;}
.servicerIconHWA0{fill-rule:evenodd;clip-rule:evenodd;fill:#BFE0E6;}
.servicerIconHWA1{fill:#00849B;}
.servicerIconHWA2{fill-rule:evenodd;clip-rule:evenodd;fill:#00849B;}
.servicerIconHWA3{fill:#BFE0E6;}
.servicerIconHWA4{fill:#CCE3FD;}


/* NJR Specific styles */
.njrBlue{fill:#0065A4;}
.njr{fill-rule:evenodd;clip-rule:evenodd;fill:#004712;stroke:#FFFFFF;stroke-width:1.0829;stroke-linecap:round;}
.njrGreen{fill-rule:evenodd;clip-rule:evenodd;fill:#439539;}

/* SB specific styles */
.calendarIllPrimarySB{fill:#475059;}
.calendarAccent1SB{fill:#99CEFF;}
.calendarAccent2SB{fill:#DCDCDC;}
.phoneIllPrimarySB{fill:#475059;}
.phoneAccent1SB{fill:#99CEFF;}
.phoneAccent2SB{fill:#DCDCDC;}
.chatIllPrimarySB{fill:#475059;}
.chatAccent1SB{fill-rule:evenodd;clip-rule:evenodd;fill:#99CEFF;}
.chatRedAlertSB{fill:#EC1944;}
.checkboxBkgSB{fill:#4F74E3;}
.checkboxBorderSB{fill:#4F74E3}
.checkSB{fill:#FFFFFF;}
.checkboxBorderSB{fill:#D0D0D0;}
.cancelIllPrimarySB{fill:#475059;}
.cancelAccent1SB{fill:#99CEFF;}
.cancelAccent2SB{fill:#DCDCDC;}
.cancelAlertRedSB{fill:#EC1944;}
.replaceIllPrimarySB{fill:#475059;}
.replaceAccent1SB{fill:#99CEFF;}
.replaceAccent2SB{fill:#DCDCDC;}
.reviewClaimPrimarySB{fill:#475059;}
.reviewClaimAccent1SB{fill:#99CEFF;}
.reviewClaimAccent2SB{fill:#DCDCDC;}
.successIllBrandSB{fill:#475059;}
.successAccent1SB{fill:#99CEFF;}
.successAccent2SB{fill:#DCDCDC;}
.successGreenCheckBkgSB{fill:#13AA41;}
.profileIllPrimarySB{fill:#475059;}
.profileAccent1SB{fill:#99CEFF;}
.profileAccent2SB{fill:#DCDCDC;}
.emailIllPrimarySB{fill:#475059;}
.emailAccent1SB{fill:#99CEFF;}
.emailAccent2SB{fill:#DCDCDC;}
.emailGreenCheckBkgSB{fill:#13AA41;}
.exclamationIllPrimarySB{fill:#475059;}
.exclamationAccent1SB{fill:#99CEFF;}
.contactIllPrimarySB{fill:#475059;}
.contactAccent1SB{fill:#99CEFF;}
.contactAccent2SB{fill:#DCDCDC;}
.uploadAccent1SB{fill-rule:evenodd;clip-rule:evenodd;fill:#F2F2F2;}
.uploadPrimaryBrandSB{fill-rule:evenodd;clip-rule:evenodd;fill:#4F74E3;}