@import url('../../../css/VARIABLES.css');

.pageHeader {
    height: 229px;
    background-color: var(--Color__Neutral1);
}

.pageHeaderContent {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    height: 229px;
}

.faqNavs {
    margin-top: 3rem;
    border-bottom: 1px solid #b9c6d4;
    margin-bottom: 2rem;
}

    .faqNavs .nav-link.active {
        color: #001871;
        background-color: #fff;
        border-bottom: 3px solid #001871;
        font-weight: bold;
    }

    .faqNavs .nav-link {
        font-size: 18px;
        color: #343741;
        border-radius: 0;
        text-align: center;
        margin-bottom: 0rem;
        padding-top: 22px;
    }

.helpLine {
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

    .helpLine p {
        text-align: center;
    }

    .helpLine h4 {
        margin-bottom: 0rem;
    }

.count {
    color: #5D738D;
    text-align:center;
    font-weight:normal;  
}

.searchBox {
    display: flex;
    position: relative;
}

    .searchBox span {
        height: 53px;
        width: 40px;
        padding: 12px 0px 0px 0px;
    }

.helpLineNum {
    height: 145px;
    border: 1px solid #b9c6d4;
    text-align: center;
    padding-top: 2rem;
}

@media (max-width: 991px) {
    .helpLineNum:first-child {
        margin-bottom: 24px;
    }
}

.helpLine .h4 {
    text-align: center;
}

    .helpLineNum img {
        margin-bottom: 1rem;
    }

    .helpLineNum p:first-of-type {
        font-weight: 600;
        margin-bottom: 0rem;
    }

/* Accordian */

.questionCard {
    margin-bottom: 1rem;
    box-shadow: none;
    border: none;
}

    .questionCard .card-header {
        min-height: 67px;
        border: 1px solid #b9c6d4;
        border-radius: 0px;
        cursor: pointer;
    }

    .questionCard .card-body {
        border: 1px solid #b9c6d4;
        border-top: none;
    }

    .questionCard .card-header:hover {
        border: 1px solid #1755de;
    }

    .questionCard .card-header Button {
        color: #001871;
        font-size: 16px;
        background-color: #fff;
        border: none;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        font-weight: normal;
    }

        .questionCard .card-header Button:focus {
            border: none;
        }

        .questionCard .card-header Button[aria-expanded="true"] {
            font-weight: 600;
        }

.collapsed.question p:first-of-type {
    font-weight: 600;
}

.question p {
    margin-bottom: 0px;
}
.arrow{
    width:15px;
    height:15px;
}
.active.question {
    font-weight: bold;
}

.closed.arrow {
    
    transform: rotate(0deg) !important;
    transition: transform 0.3s;
    margin-left: 10px;
}

.active.arrow {
    transform: rotate(180deg) !important;
    transition: transform 0.3s;
    margin-top: 10px;
    margin-left: 10px;
}

.question {
    display: flex;
}

    .question p:first-of-type {
        color: #1352de;
        margin-right: 0.4rem;
    }
    .question p:last-of-type {
        color: #001871;
    }
.accordionFaq {
    display: flex;
    justify-content: space-between;
}


.faqs {
    height: 0;
    overflow: hidden;
}

.searchResultsContainer {
    margin-top: 40px;
}

.searchIcon {
    position: absolute;
    top: 1px;
    right: 1px;
}

  
.icon {
    height: 18px;
    width: 18px;
    object-fit: contain;
   
    color: #1352DE;
}

.search-svg {
    height: 20px!important;
    width: 20px!important;
}