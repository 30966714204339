@media (min-width: 320px) and (max-width: 425px) {
    .cancelModalDropDown {
        margin-left: -1rem !important;
    }
}

@media (min-width: 426px) {
    .cancelModalDropDown {
        margin-left: 14rem !important;
    }
}
