.pageHeader {
    margin-bottom: 3rem;
    background-color: var(--Color__Neutral1);
}

.pageHeaderContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 229px;
    align-items: center;
}

.pageHeader p {
    font-size: 18px;
}

.question {
    font-size: 18px;
    color: var(--PrimaryTextColor);
    font-weight: 600;
}

.radioBtn {
    text-align: center;
    padding: 12px 0px;
}

.borderTopBottom {
    border-top: 1px solid #E0E5EB;
    border-bottom: 1px solid #E0E5EB;
    max-width: 166px;
}

.borderLeft {
    border-left: 1px solid #E0E5EB;
}

.borderRight {
    border-right: 1px solid #E0E5EB;
}

.ratings {
    max-width: 166px;
    text-align: center;
}

.ratings p {
    margin-bottom: 16px;
    font-size: 16px;
}

.recommend, .description {
    margin-top: 72px;
    padding: 0px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.emoji svg {
    height: 28px;
    width: 28px;
}

.recommendBtn {
    margin-right: 16px;
}

@media (max-width: 768px) {
    .recommendBtn {
        margin-right: 0px;
        width: 100%;
    }
    .pageHeaderContent {
        text-align: center;
    }

    .borderTopBottom {
        margin-bottom: 16px;
    }

    .borderTopBottom:last-of-type(1) {
        margin-bottom: 0px;
    }

    .recommend{
        margin-top: 56px;
    }
}