@import url("../../css/VARIABLES.css");

.orderSummaryHeading {
  border-bottom: 1px solid var(--Color__Neutral3);
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
  padding-bottom: 12px;
  color: var(--PrimaryTextColor);
}

.orderSummaryContainer {
  padding: 15px 20px;
  background-color: var(--Color__Neutral2);
  letter-spacing: 0.5px;
  max-width: 360px;
}

.dueContainer {
  margin-left: 0 !important;
  margin-right: 0 !important;
  border-bottom: 1px solid var(--Color__Neutral3);
  border-top: 1px solid var(--Color__Neutral3);
  font-size: 24px;
}

.dueContainer div {
  padding: 20px 0px;
}

.textAlignRight {
  text-align: right;
}

.dueContainer div:nth-child(2) {
  text-align: right;
}

.applyBtn {
  width: 100%;
  border-radius: 0 !important;
  margin-top: 30px;
}

.applyBtn:disabled {
  border: var(--InputDisabled__Border) !important;
}

@media (max-width: 767px) {
  .applyBtn {
    margin-top: 15px;
  }
}
