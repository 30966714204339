@import url('../../../../css/VARIABLES.css');

.radioCustomBg input:checked + label {
    background-color: #fff !important;
}
.radioCustomBg input[type="radio"]+label:hover {
    background-color: #fff !important;
}

.watchButton {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    flex-direction: column;
    border-radius: 0px !important;
    margin: 0px !important;
    width: 122px !important;
    height: 255px;
    padding: 20px !important;
}
.watchColor {
    font-size: 16px;
    color: var(--PrimaryBrandColor);
}
.watchBand {
    font-size: 12px;
    color: var(--PrimaryTextColor);
    margin-bottom: 4px;
}
.bandImage {
    width: 44px;
    height: 170px;
    object-fit: cover;
    object-position: center;
}

.formError {
    border-color: var(--ErrorColor)!important;
    background-color: #fff;
    color: var(--ErrorColor)!important;
}
.formError div {
    color: var(--ErrorColor)!important;
}