@import url("../../css/VARIABLES.css");

.claimsTable>tbody td {
    vertical-align: middle;
    padding: 0.75rem 1.75rem;
    text-overflow: ellipsis;
    border: none !important;
}

.claimsTable>thead th {
    padding: 1.75rem;
    position: relative;
    border: none !important;
}

.claimsTable>tbody tr:nth-of-type(odd) {
    background-color: var(--Table__RowBg);
}

.claimsTable {
    border: var(--Table__Border);
    color: var(--PrimaryTextColor);
}

.claimActions {
    text-align: center;
}

.claimActions button {
    width: 138px;
}

.claimsTable a {
    cursor: pointer;
}

.arrowIcon {
    fill: var(--LinkColor);
    height: 16px;
    width: 16px;
    margin-right: .25rem;
    transform: rotate(90deg);
}

.viewLink {
    display: flex;
    align-items: center;
    color: var(--LinkColor) !important;
    font-size: 16px;
    font-weight: 600;
    margin: 20px 8px;
    cursor: pointer;
}

.reprintAlert {
    position: absolute;
    top: -23px;
    right: 18px;
    color: #001871;
    background-color: #13AA41;
}

.assetIcon svg {
    height: 68px;
    width: 68px;
    fill: white;
}

.contractActionsWrapper {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.btnWrapper {
    display: flex;
    gap: 20px;
}

.cancelHelp {
    text-align: right;
}

.checkmarkIcon {
    fill: #13AA41;
    height: 16px;
    width: 16px;
}

.assetContainer {
    background: var(--Hero__Bg);
    color: var(--Hero__Text);
    display: flex;
    padding: 20px 24px;
    justify-content: space-between;
    align-items: center;
}

.assetContainer h4 {
    color: var(--Hero__Heading);
}

.tableHeader {
    border-bottom: none;
    padding: 24px 24px 0px;
}

.tableBody {
    padding: 12px 24px 8px;
}

.coveredInfoContainer {
    margin: -20px -40px;
}

.coveredInfoContainer .coveredItemsRow:last-child {
    border-bottom: none;
}

.coveredItemsRow {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--Color__Neutral2);
    padding: 5px 40px;
}

.coveredItemsRow div:first-child {
    padding-left: 18px;
}

/* Reprint Contract Modal Styles */

.documentsEmail {
    max-width: 300px;
}

.textAlignLeft {
    text-align: left;
}

.contractInfo {
    display: flex;
    gap: 10px;
}

.reprintModalButtonWrapper {
    gap: 16px;
}

.newAddressWrapper {
    width: 80%;
}

@media (max-width: 767px) {
    .planContainer {
        padding: 0px !important;
        margin: 0px;
    }

    .viewLink {
        margin: 20px;
    }

    .contractActionsWrapper {
        flex-direction: column;
    }

    .btnWrapper {
        gap: 16px;
        flex-direction: column;
    }

    .cancelHelp {
        text-align: center;
        margin-top: 16px;
    }

    .contractInfo {
        flex-direction: column;
        gap: 0;
    }

    .reprintModalButtonWrapper {
        flex-direction: column-reverse;
    }

    .controlsLabelRadio:after {
        top: 25% !important;
    }

    .reprintAlert {
        top: 12px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
    }

    .coveredItemsRow {
        padding: 8px 20px;
    }

    .notCoveredInfoContainer {
        margin: -6px -18px;
    }

    .notCoveredInfoContainer li {
        margin-bottom: 8px;
    }

    .newAddressLabelRadio:after {
        top: 52% !important;
    }
}