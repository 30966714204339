/***AutoComplete DropDown*/
@import url("./VARIABLES.css");

.autoDrop__control,
.css-1pahdxg-control {
    background-color: #fff !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-color: #fff;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: .5px;
    border-radius: 0px !important;
}

.autoDrop__option,
.css-yt9ioa-option {
    border-top: 1px solid #b9c6d4;
}

.autoDrop__menu-list,
.css-4ljt47-MenuList {
    padding-top: 0px !important;
}

.autoDrop__indicator-separator,
.css-1okebmr-indicatorSeparator {
    width: 0px;
    margin: 0px!important;
    background-color: transparent!important;
}

.autoDrop__control,
.autoDrop__control--is-focused,
.autoDrop__control--menu-is-open,
.css-1pahdxg-control,
.css-2b097c-container {
    border-radius: 0px !important;
    transition: border-color border-width ease-in-out .2s;
    height: 54px;
}


.css-1pahdxg-control{
    box-shadow: 0 0 0 1px var(--FocusColor) !important;
}

.form-error .autoDrop__control, 
.form-error .css-yk16xz-control, 
.form-error .autoDrop__placeholder {
    border-color: var(--ErrorColor) !important;
    background-color: white;
    color: var(--ErrorColor);
}

.autoDrop__control,
.css-yk16xz-control {
    border-radius: 0px !important;
    border: var(--Input__Border) !important;
}

.autoDrop__placeholder {
    color: #767676 !important;
}

.autoDrop__single-value {
    color: #767676;
    margin-left: 2px;
    margin-right: 2px;
    max-width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    top: 35%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
}

.autoDrop__value-container,
.autoDrop__value-container--has-value {
    color: #767676;
    font-size: 16px !important;
    text-align: center;
    padding: 15px 4px !important;
    height: 54px;
    padding-right: 20px;
    border-radius: 0px!important;
}

.autoDrop__indicator,
.css-tlfecz-indicatorContainer {
    fill: none !important;
    display: inline-block;
    content: ' ';
    background-image: url('../assets/images/arrow-cheveron-down.svg');
    background-size: 16px 18px;
    height: 15px;
    width: 15px;
    position: absolute;
    right: 20px;
    top: 19.5px;
    color: #fff !important;
}

.autoDrop__indicator,
.css-tlfecz-indicatorContainer:hover {
    color: #fff !important;
}

.autoDrop__option:hover {
    background: var(--SecondaryBrandColor) !important;
    color: #fff;
    text-decoration: none;
}


.autoDrop__option--is-focused,
.autoDrop__option--is-selected {
    background: var(--SecondaryBrandColor) !important;
    color: #fff !important;
    text-decoration: none;
}