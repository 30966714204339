.confirmSection {
    text-align: center;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    margin: 75px 0px;
}

.confirmIcon {
    margin-bottom: 26px;
    height: 151px;
}

.confirmSection h2 {
    font-size: 16px;
    font-weight: 600;
    color: #343741;
}

.confirmSection p {
    margin-bottom: 0rem;
}
