@import url('./VARIABLES.css');

/* =============================================================================
INPUTS
============================================================================= */

input,
select,
textarea {
    font-family: var(--TextFont);
    font-size: 100%;
    margin: 0;
    line-height: var(--Input__LineHeight);
}

input:focus {
    outline-color: var(--InputFocus__Border);
}

.required-indicator {
    color: var(--Input__RequiredAsterisk);
    font-size: var(--FontSize);
    font-weight: var(--Input__LabelWeight);
    margin: 0 8px 0 4px;
}

.form-group {
    margin-bottom: 24px;
    position: relative;
}

.form-group .control-label {
    display: block;
    margin-bottom: 8px;
}

.form-label,
.form-label-radio {
    color: var(--Input__LabelColor);
    font-weight: var(--Input__LabelWeightinputLabelWeight);
    font-size: var(--BtnAndInput__FontSize);
    line-height: var(--Input__LineHeight);
    display: inline-block;
    margin-bottom: var(--Input__LabelMarginBottom);
    max-width: 100%;
}

.form-control {
    background-color: #fff;
    background-image: none;
    border: var(--Input__Border);
    color: var(--PrimaryTextColor);
    display: block;
    font-size: var(--BtnAndInput__FontSize);
    font-weight: 400;
    letter-spacing: var(--LetterSpacing);
    line-height: var(--Input__LineHeight);
    width: 100%;
    height: auto;
    padding: var(--Input__Padding);
    border-radius: 0;
    transition: border-color, border-width ease-in-out .2s;
}

.form-control:focus {
    border-color: var(--FocusColor);
    outline: 0;
    box-shadow: inset 0 0 0 1px var(--FocusColor);
}

.form-control[disabled] {
    background-color: var(--InputDisabled__Bg);
    border: var(--InputDisabled__Border) !important;
    color: var(--InputDisabled__Text);
    opacity: 1;
    cursor: not-allowed;
}

.form-control-static {
    min-height: 56px;
}

.textarea.form-control {
    min-height: 100px;
    max-height: 172px;
    padding: var(--Input__Padding);
}

.textAreaGroup {
    width: 100%
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}


/* =============================================================================
DROPDOWNS
============================================================================= */

.dropbtn {
    display: inline-block;
    border-radius: 0;
    border: var(--Input__Border);
    padding: var(--Input__Padding);
    padding-right: 48px;
    width: 100%;
    vertical-align: middle;
    /* height: 56px; */
    background-color: #fff;
    font-size: var(--BtnAndInput__FontSize);
    font-weight: 400;
    text-align: left;
    line-height: var(--Input__LineHeight);
    color: var(--PrimaryTextColor);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: border-color ease-in-out .2s;
}


/*.dropbtn-placeholder {
    color: var(--Input__Placeholder);
}*/

.dropbtn:after {
    display: inline-block;
    content: ' ';
    background-image: url('../assets/images/arrow-cheveron-down.svg');
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 24px;
}

.optionsBtn:after {
    margin-right: -4px !important;
}


/* The container <div> - needed to position the dropdown content */

.dropdown {
    position: relative;
    display: block;
}

.full-width {
    width: 100%;
}


/* Dropdown Content (Hidden by Default) */

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 4;
    border: solid var(--Color__Neutral3);
    border-width: 0 1px 1px;
    font-size: 1rem;
    width: 100%;
    max-height: 200px;
    overflow: auto;
}

.dropdown-content .dropdown-search {
    border: none;
    padding: 8px 24px;
    display: block;
    margin-bottom: 0;
    width: 100%;
}


/* Links inside the dropdown */

.dropdown-content a {
    border-top: var(--Input__Border);
    color: var(--PrimaryTextColor);
    cursor: pointer;
    display: block;
    margin-bottom: 0;
    font-weight: 400;
    padding: 8px 24px;
}


.dropdown-menu {
    margin: 0rem;
    padding: 0rem;
    border-radius: 0rem;
    min-width: 5rem;
    top: 
}

/* Change color of dropdown links on hover */

.dropdown-content a:not([href]):not([tabindex]):hover,
.dropdown-content a:not([href]):not([tabindex]):focus {
    background: var(--DropOption__HoverBg);
    color: #fff;
    text-decoration: none;
}


/* Show the dropdown menu on hover */


/* .dropdown:hover .dropdown-content {
    display: block;
}*/


/* Change the background color of the dropdown button when the dropdown content is shown */

.dropdown:hover .dropbtn {
    border: 1px solid var(--FocusColor);
}

.dropdown-item {
    color: var(--Heading__Color);
    background-color: #fff;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: left;
    border-bottom: 1px #ccc;
}

.dropdown button.dropdown-item {
    padding: 24px 32px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--Color__Neutral2) !important;
}


/* =============================================================================
RADIO BUTTONS
============================================================================= */

.radio {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin-top: 16px;
}

.radio input[type=radio] {
    left: -9999px;
    opacity: 0;
    position: absolute;
}

.radio .controls-label {
    -ms-flex-align: baseline;
    align-items: baseline;
    color: var(--PrimaryTextColor);
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: var(--FontSize);
    font-weight: 400;
    line-height: 1.5;
    padding-left: 16px;
    position: relative;
    text-align: left;
    margin-bottom: 0;
}

.radio .controls-label:before {
    border-radius: 50%;
}

.radio input[type=radio]:checked+.controls-label:before {
    border-color: var(--RadioAsButton__CheckedBorder);
}

.radio input[type=radio]:checked+.controls-label:after {
    background: var(--RadioAsButton__CheckedBorder);
    border-radius: 50%;
    content: '';
    height: 16px;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(25%, -50%);
    -ms-transform: translate(25%, -50%);
    transform: translate(25%, -50%);
    width: 16px;
}

.controls-label:before {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex: 0 0 24px;
    flex: 0 0 24px;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 0;
    border: var(--Input__Border);
    content: '';
    height: 24px;
    margin: 0 8px 0 -16px;
    max-width: 24px;
    transition: background .3s ease;
    width: 24px;
}

div.radioButton,
.form-group .control-label.inline,
.radioButtonWrapper {
    display: inline;
}

.radioButton input[type="radio"] {
    display: none;
    width: 0px;
    height: 0px;
}

/* TODO: 
    THIS SYMPTOMS BUTTON DOES NOT BELONG ON GLOBAL! MOVE IT ELSEWHERE
*/
.radioButton.symptoms{
    display: flex;
    justify-content: center;
    align-items: stretch;
}


/* TODO: figure out what this radio button even is */


/* NICK: This is too the symptom radiobox selctions */

.radioButton input[type="radio"]+label {
    margin: .5rem 0 1rem 0;
    padding: 15px 2rem;
    border: var(--RadioAsButton__Border);
    background-color: var(--RadioAsButton__Bg);
    color: var(--RadioAsButton__Text);
    display: inline-block;

    text-align: center;
}

/* 
============== TODO: ================== 
    THIS SYMPTOMS BUTTON DOES NOT BELONG ON GLOBAL! MOVE IT ELSEWHERE
*/
.radioButton.symptoms input[type="radio"]+label {
    padding: 15px 1rem;
    display: flex;
    justify-content: center;
    align-items: center;}

.radioButton input[type="radio"] {
    display: none;
    width: 0px;
    height: 0px;
}

.radioButton input[type="radio"]:focus {
    outline-color: Highlight;
}

.radioButton input:checked+label,
.radioButton label.selected,
.radioButton input[type="radio"]+label:hover,
.radioButton input[type="radio"]+label:focus {
    color: var(--RadioAsButton__CheckedText);
    background-color: var(--RadioAsButton__CheckedBg);
    border-color: var(--RadioAsButton__CheckedBorder);
}


/* =============================================================================
CHECKBOXES
============================================================================= */

.checkbox {
    margin-bottom: 24px;
    display: inline-flex;
}

.checkbox.scriptBox {
    display: block;
}

.checkboxLabel {
    color: var(--Input__LabelColor);
    font-family: var(--TextFont);
    letter-spacing: var(--LetterSpacing);
    margin-bottom: 0;
    font-weight: 500;
    display: flex;
    /* height: -webkit-fill-available; */
    align-items: center;
    color: var(--PrimaryTextColor);
}

.checkboxLabel:before {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 0;
    border: var(--Checkbox__Border);
    content: '';
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex: 0 0 24px;
    flex: 0 0 24px;
    height: 24px;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 24px;
    width: 24px;
    height: 24px;
    margin: 0px;
    margin-right: 10px;
    align-items: center;
}

.checkbox input[type=checkbox] {
    left: -9999px;
    opacity: 0;
    position: absolute;
}

.checkbox input[type=checkbox]:checked+.checkboxLabel:before {
    margin-top: 0;
    background: var(--Checkbox__CheckedBg);
    background-image: url('../assets/images/checkbox-selected.svg');
    /* background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=); /* fallback */
    /* background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=), linear-gradient(225deg, #1352de, #001871); W3C */
    background-position: center;
    background-repeat: no-repeat;
}


/*  
    ========== HWA CUSTOM CHECKBOX =========== 
    add more of these rules for future clients
*/

.checkbox input[type=checkbox]:checked+.checkboxLabel.hwa:before {
    background-image: url('../assets/images/Vendor/HWA/checkbox-selected.svg');
}

.checkbox input[type=checkbox]:focus+.checkboxLabel:before {
    border-color: var(--FocusColor) !important;
    border-width: 2px;
}

:focus {
    outline: -webkit-focus-ring-color auto 1px;
}

.checkboxinput[type=checkbox]:hover+.checkboxLabel:before {
    border-color: var(--FocusColor) !important;
    border-width: 1px;
}

.inputGroup {
    display: -ms-flexbox;
    position: relative;
    width: 100%;
}

.input-group-text {
    background-color: #fff;
    border: var(--Input__Border);
    padding: 6px 24px 6px 1px;
    border-radius: 0;
}

.input-group>.input-group-append>.input-group-text {
    border-left: 0;
}

#stepsDate .form-control {
    border-right: 0;
}


/*.input-group .form-control {
    border-right: 0;
}*/

.inputCalendar {
    fill: var(--SecondaryBrandColor);
    width: 32px;
    height: 32px;
}


/*.dateInput {
    border-right-width: 0;
}*/


/* =============================================================================
DATE PICKER
============================================================================= */

.react-date-picker__inputGroup {
    color: var(--TextFont);
    font-size: 14px;
    font-weight: 400;
    font-family: var(--TextFont);
    width: 160px;
}

.dateInput {
    max-width: 250px;
}

@media screen and (max-width: 767px) {
    .dateInput {
        max-width: none;
    }
}
    .react-calendar__navigation__arrow {
        color: var(--IconColor) !important;
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
        display: none;
    }

    .react-calendar__navigation__label {
        color: var(--IconColor) !important;
        border: 1px solid var(--IconColor) !important;
        border-radius: 18px;
        margin-top: 15px !important;
        margin-left: 13px;
        margin-right: 13px;
        font-weight: 600;
    }

    .react-calendar__navigation button {
        background-color: #fff !important;
        height: 34px;
        font-family: var(--TextFont);
    }

    .react-calendar__month-view__days__day {
        color: var(--Heading__Color);
    }

    .react-calendar {
        background: #FFFFFF;
        border: var(--Input__Border) !important;
        box-shadow: 0 4px 8px 0 rgba(0, 87, 184, 0.06) !important;
        border-radius: 3px;
        width: 300px !important;
        max-width: 300px !important;
        min-height: 296px;
        padding: 16px;
        top: 55px;
        position: absolute;
        z-index: 99;
        padding: 16px;
    }

    .dateInput .react-calendar {
        top: 0px;
        left: -2px;
    }

    .react-calendar__month-view__weekdays {
        color: var(--PrimaryTextColor) !important;
        line-height: 26px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.75em;
        text-decoration: none;
    }

    abbr[title],
    abbr[data-original-title] {
        text-decoration: none;
        border-bottom: 0;
        font-weight: normal;
        font-size: 14px;
        color: var(--PrimaryTextColor) !important;
        font-family: var(--TextFont);
        opacity: 100;
        letter-spacing: 0.24px;
    }

    .react-calendar__month-view__days {
        font-family: var(--TextFont);
        font-size: 12px;
        color: var(--PrimaryBrandColor) !important;
        letter-spacing: 0.43px;
        text-align: center;
        line-height: 24px;
    }

    .react-calendar__tile {
        font-family: var(--TextFont);
        font-size: 12px;
        letter-spacing: 0.43px;
        text-align: center;
    }

    .react-calendar__month-view__days__day {
        font-family: var(--TextFont);
        font-size: 12px;
        letter-spacing: 0.43px;
        text-align: center;
        font-weight: normal
    }

    .react-calendar__month-view__days__day--weekend {
        color: var(--Heading__Color) !important;
    }

        .react-calendar__tile:enabled:hover,
        .react-calendar__month-view__days__day:enabled:hover,
        .react-calendar__month-view__days__day--weekend:enabled:hover .react-calendar__tile:enabled:focus,
        .react-calendar__month-view__days__day:enabled:focus,
        .react-calendar__month-view__days__day--weekend:enabled:focus {
            color: #fff;
        }

    .react-calendar__tile--active {
        border-radius: 50px;
        color: #fff !important;
        background-color: var(--DropOption__HoverBg) !important;
    }

    .react-date-picker__clear-button__icon {
        display: none !important;
    }

    .react-date-picker__calendar-button:after {
        display: inline-block;
        content: ' ';
        background-image: url('../assets/images/calendar.svg');
        background-size: 32px 32px;
        background-repeat: no-repeat;
        height: 32px;
        width: 32px;
        position: absolute;
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .dateInput.hwa .react-date-picker__calendar-button:after {
        background-image: url('../assets/images/Vendor/HWA/calendar.svg');
    }

    .react-date-picker__calendar-button__icon.react-date-picker__button__icon {
        display: none;
    }

    .react-calendar__tile--hasActive {
        background-color: var(--IconColor) !important;
        border-radius: 33px;
        color: #fff;
        -webkit-appearance: button;
        line-height: 1;
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
        background-color: var(--IconColor) !important;
        border-radius: 33px;
        color: #fff !important;
    }

    .react-calendar__tile--now,
    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile-now:enabled:focus {
        background-color: var(--Color__Neutral2) !important;
        border-radius: 33px;
        justify-content: center;
        line-height: 1;
        margin: 0;
        padding: 8px;
    }

    .react-date-picker__wrapper {
        border: none !important;
    }

    .react-calendar__year-view__months > .react-calendar__tile,
    .react-calendar__decade-view__years__year > .react-calendar__tile,
    .react-calendar__year-view__months > .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__year-view__months > .react-calendar__century-view .react-calendar__tile {
        padding: 5px !important;
        margin-top: 19px;
        margin-bottom: 2px;
        font-size: 13px !important;
        font-weight: normal;
    }

        .react-calendar__year-view__months > .react-calendar__tile--now,
        .react-calendar__year-view__months > .react-calendar__tile:enabled:hover,
        .react-calendar__year-view__months > .react-calendar__tile--now:enabled:hover,
        .react-calendar__year-view__months > .react-calendar__tile-now:enabled:focus,
        .react-calendar__year-view__months > .react-calendar__tile:enabled:focus {
            padding: 5px !important;
            margin-top: 19px !important;
            margin-bottom: 2px !important;
            font-size: 13px !important;
            font-weight: normal;
        }

    .react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
    .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
        font-size: 24px;
        padding-top: 16px;
    }

    .react-date-picker__inputGroup {
        height: 22px;
        color: var(--PrimaryTextColor);
        font-size: 14px;
        font-weight: 400;
        font-family: var(--TextFont);
        width: 160px;
    }

    abbr[title],
    abbr[data-original-title] {
        font-size: 12px;
    }

    .react-calendar__month-view__weekdays__weekday {
        padding: 0;
    }

    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__tile-now:enabled:focus,
    .react-calendar__century-view .react-calendar__tile,
    .react-calendar__tile--now:enabled.react-calendar__decade-view__years__year:hover,
    .react-calendar__decade-view__years__year {
        padding: 10px 0.5em;
        margin-top: 15px;
    }

    .react-calendar__tile:disabled {
        background: none !important;
        color: var(--InputDisabled__Text) !important;
    }

    /*  
    ========== ET CUSTOM CHECKBOX =========== 
    add more of these rules for future clients
    */

    .checkbox input[type=checkbox]:checked+.checkboxLabel.et:before {
        background-image: url('../assets/images/Vendor/ET/checkbox-selected.svg');
    }
    .dateInput.et .react-date-picker__calendar-button:after {
        background-image: url('../assets/images/Vendor/ET/Calender_Icon.svg');
    }

/*
    ========== Southbridge CUSTOM CHECKBOX =========== 
    add more of these rules for future clients
    */

.checkbox input[type=checkbox]:checked + .checkboxLabel.southbridge:before {
    background-image: url('../assets/images/Vendor/SB/checkbox-selected.svg');
}

/*
    ========== Cutler CUSTOM CHECKBOX =========== 
    add more of these rules for future clients
*/

.checkbox input[type=checkbox]:checked + .checkboxLabel.cutler:before {
    background-image: url('../assets/images/Vendor/Cutler/checkbox-selected.svg');
}

/*
    ========== Constellation CUSTOM CHECKBOX =========== 
    add more of these rules for future clients
*/

.checkbox input[type=checkbox]:checked+.checkboxLabel.constellation:before {
    background-image: url('../assets/images/Vendor/Constellation/checkbox-selected.svg');
}