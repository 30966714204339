@import url("../../../css/VARIABLES.css");

.accountDetails {
    max-width: 900px;
    margin: 0 10rem;
    padding: 30px 0;
}

.headerText {
    font-size: 15px;
}

.viewLink {
    color: var(--LinkColor) !important;
    font-size: 15px;
}

.borderStyle {
    border-bottom: none;
}

.searchIcon {
    fill: var(--LinkColor);
    height: 24px;
    width: 24px;
    margin-right: .25rem;
    transform: rotate(90deg);
    vertical-align: bottom;
}