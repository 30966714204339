@import url('../../../css/VARIABLES.css');
.callUSSm {
    color: var(--PrimaryBrandColor);
    font-size: 1.2rem;
    padding: 10px;
    text-align: center;
    background-color: #F7F7F7;
}

.callUS {
    color: var(--PrimaryBrandColor);
    font-size: 1.2rem;
}

.callUS img,
.callUSSm img {
    margin-right: 8px;
}

.navTabsHWA li a {
    padding: 0.5rem 0.7rem !important;
    font-size: 0.8rem !important;
    font-weight: normal !important;
    color: var(--PrimaryBrandColor) !important;
    border: none !important;
    background: transparent !important;
    cursor: pointer;
}

.navTabsHWA li a:hover,
.navTabsHWA li a:active {
    background: #f05d21 !important;
    color: #fff !important;
}

.hwaHeaderLg {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hwaContainer {
    width: 90%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.hwaLogo a svg {
    height: 76px;
}

.hwaLogoMobile a svg {
    height: 3.25rem;
}

.hwaLogoMobile {
    right: 50%;
    position: absolute;
    transform: translate(50%, 5%);
}

.mobileNav button {
    border: 1px solid rgba(0, 0, 0, .1) !important;
    padding: .25rem .75rem !important;
}

.navContainer {
    display: flex;
}

.hwaHeader {
    border-bottom: 2px solid #f05d21;
}

.navBarContainer {
    background-color: #F7F7F7;
}

@media (max-width: 767px) {
    .hwaHeaderLg {
        display: none;
    }

    .hwaContainer {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
    }

    .hwaHeader {
        border-bottom: none !important;
    }
    .navContainer {
        border-bottom: 2px solid #f05d21 !important;
        padding-left: 0;
    }
}

@media (min-width: 767px) {
    .hwaLogoMobile,
    .callUSSm {
        display: none;
    }
    .mobileNav {
        padding: 0px !important;
    }
}