@import url('../../css/VARIABLES.css');
.pageHeader {
    height: 229px;
    background-color: var(--Color__Neutral1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

h2.cardTitle {
    margin-bottom: 1rem;
}

.cardTitle {
    color: var(--PrimaryTextColor);
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 1rem;
    text-align: center;
    display: block;
}

.radioButton input[type="radio"]+label:hover {
    background-color: var(--RadioAsButton__CheckedBg);
    color: #fff;
}

.affirm {
    padding-right: 15px;
    padding-left: 15px;
}

.mw275 {
    max-width: 275px;
}

.maxLength {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    text-align: right;
}

.troubleshootMW {
    max-width: fit-content;
}

.dateInput {
    max-width: 200px;
    border-right: 0 !important;
}

.datePicker {
    width: 65px;
    border: var(--Input__Border);
    border-left: 0;
    padding: 13px 0 13px 0;
    height: 56px;
}

.datePicker.formError {
    border-color: var(--ErrorColor)
}


/* ADD MORE BRAND-SPECIFIC CLASSES AS NEEDED TO COLOR CALENDAR ICON */

.datePicker.hwa {
    fill: #008da6;
}

.btnWrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

/* Email Modal css */

.emailModalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .emailModalBody div {
    text-align: left;
    margin-bottom: 1.5rem;
  }
  
  .emailModalBody p {
    margin-bottom: 24px;
  }
  
  @media (max-width: 425px) {
    .emailModalBody div {
      padding: 0px;
    }
  }

@media (max-width: 767px) {
    .pageHeader {
        padding: 0rem 1rem !important;
        height: 290px;
    }
    .btnWrapper {
        display: block !important;
        width: 100% !important;
    }
    .btnWrapper button {
        margin-bottom: 1rem;
        margin-top: 24px;
    }
}

@media (min-width: 992px) {
    #affirm .checkbox {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
}
