.formGroupWrapper {
  display: flex;
  align-items: center;
}

.formLabel {
  color: #001871;
  line-height: 1.5;
  margin-bottom: 0;
  margin-right: 8px;
  max-width: 100%;
}
    
.switchWrapper{
  display: inline-flex;
  padding-top: 8px;
}

.switch {
  position: relative;
  width: 37px;
  height: 18px;
}

.switch::before {
  top: -4%;
  position: absolute;
  right: 115%;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f7fc;
  border: 1px solid #B9C6D4;
  -webkit-transition: .4s;
  transition: all .2s ease-in-out;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 13%;
  background-color: white;
  border: 1px solid #B9C6D4;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background: linear-gradient(0deg, #0057B8 0%, #017AD0 100%);
}

input:checked+.slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
  border: none;
}

.slider.round {
  border-radius: 16px;
}

.slider.round:before {
  border-radius: 50%;
}