@import url('../../css/VARIABLES.css');

@media screen and (max-width: 767px) {
    .btnWrapperNew button:first-child {
        margin-bottom: 24px;
    }
    .infoText {
        width: 290px !important;
        right: -67px !important;
    }
    .infoText::after {
        right: 63px !important;
    }
}

@media screen and (min-width: 768px) and (max-width) {
    .infoText {
        width: 400px !important;
    }
}

.serviceOptions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.serviceOptions p {
    font-size: 24px;
}

.newRadioBtn input:checked + label,
.newRadioBtn label.selected,
.newRadioBtn input[type="radio"] + label:hover,
.newRadioBtn input[type="radio"] + label:focus {
    color: #fff !important;
    background-color: var(--RadioAsButton__CheckedBg) !important;
    border: var(--RadioAsButton__CheckedBorder) !important;
}

.newRadioBtn input[type="radio"] + label {
    padding: 15px 2rem 15px 2rem;
    border: var(--RadioAsButton__Border);
    background-color: #fff;
    color: var(--RadioAsButton__Text);
    /* display: inline-block; */
    text-align: center;
}

.newRadioBtn input[type="radio"] {
    display: none;
    width: 0px;
    height: 0px;
}

.newRadioBtn {
    margin-bottom: 15px;
}

.btnWrapperNew {
    margin-bottom: 15px;
    margin-top: 25px;
}

.affirmCheckLabel a,
.infoText a {
    display: contents;
    color: var(--LinkColor) !important;
    cursor: pointer;
}

.affirmCheckLabel {
    margin-bottom: 0rem;
    text-align: left !important;
}

.recommendedServicerForm,
.findServicerForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.maxLength {
    text-align: right;
    color: var(--PrimaryTextColor);
}

.intialText {
    text-align: center;
    margin-bottom: 32px;
}

.ownServInfo svg {
    height: 17px;
    width: 17px;
    max-width: 17px;
    fill: var(--IconColor);
    margin-bottom: 3px;
}

.newRadioBtn:hover .ownServInfo svg,
.newRadioBtn:focus .ownServInfo svg,
.newRadioBtn input[type="radio"]:checked .ownServInfo svg {
    fill: #fff;
    /* stroke: #fff; */
}

.infoIcon {
    fill: #fff !important;
}

.ownServInfo {
    border-bottom: none;
    margin-left: 6px;
    position: relative;
    text-align: left;
}

.infoText {
    visibility: hidden;
    height: auto;
    width: 500px;
    background-color: #fff;
    color: var(--PrimaryTextColor);
    padding: 24px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
    box-sizing: border-box;
    border: 1px solid var(--Color__Neutral2);
    position: absolute;
    z-index: 1;
    top: 140%;
    right: -127px;
    opacity: 0;
    font-size: 14px;
    transition: opacity 0.3s;
}

.infoText::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    right: 122px;
    top: -12px;
    border-left: 1px solid var(--Color__Neutral2);
    border-top: 1px solid var(--Color__Neutral2);
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
}

.ownServInfo:hover .infoText {
    visibility: visible;
    opacity: 1;
}