@import url('../../../css/VARIABLES.css');
.navTabs {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

a.navLink:not([href]):not([tabindex]):hover,
a.navLink:not([href]):not([tabindex]):focus {
    color: var(--PrimaryTextColor);
}

a.navLink:not([href]) {
    text-align: center;
    border-top: 2px solid var(--Color__Neutral3);
    font-size: 1rem;
    font-weight: normal;
    border-radius: 0;
    color: var(--PrimaryTextColor);
    display: block;
    padding: 16px 0 0;
}

a.navLink.active:not([href]) {
    border-top: 2px solid var(--ActiveTab);
    border-bottom: none;
    color: var(--ActiveTab__Text);
    font-weight: var(--Heading__FontWeight);
}

a.navLink.active:not([href]):hover {
    color: var(--ActiveTab__Text);
}


.navTabs {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    padding-left: 0;
}

.navTabs,
.navTabs .navItem.active,
.navTabs .navItem:focus {
    border-bottom: none;
}

.navItem {
    text-align: center;
    /*border-left: 1px solid var(--Color__Neutral2);*/
    min-width: 140px;
    flex: 1 0 20%;
}

.infoIcon svg {
    margin-bottom: 5px;
}

.flexDirectionRow {
    flex-direction: row;
}

.flexDirectionCol {
    flex-direction: column;
}

.activeAsset {
    outline: var(--PrimaryTextColor) auto;
}

@media screen and (max-width: 1199px) {
    .navItem {
        flex-basis: 33%;
    }
    a.navLink.active:not([href]) {
        /*border-bottom: 1px solid var(--Color__Neutral2);*/
    }
}
/* ===== make mobile break take buttons full-width ===== */
        @media screen and (max-width: 359px) {
            .navItem {
                flex-basis: 33%;
                min-width: 90px;
            }

            .navLink {
                padding: 8px 0 !important;
            }
        }

.btnPopover {
    font-size: 1rem;
    line-height: inherit;
    padding: 48px 0;
    transition: all .3s ease;
    align-items: center;
    border-radius: 0;
    font-weight: 700;
    cursor: pointer;
    justify-content: center;
    letter-spacing: .5px;
    text-align: center;
    color: var(--PrimaryTextColor);
    width: 100%;
    background: none;
    border: none;
}

.assetIcon {
    fill: var(--CategoryTab__Color);
    height: 55px;
    width: 55px;
    display: block;
    margin: 0 auto 8px;
    vertical-align: bottom;
    fill: var(--PrimaryTextColor);
    width: 68px;
    height: 68px;
}

.popoverHeader {
    background-color: var(--Color__Neutral2);
    border-bottom: none;
    padding: 24px 40px;
    /*display: none;*/
    width: 100%;
}

@media screen and (max-width: 640px) {
    .popoverHeader {
        background-color: transparent;
        padding: 24px 0;
    }
}

    .popover {
        max-width: 665px;
        border-radius: 0;
        outline: 9999px solid rgba(0, 0, 0, 0.5);
        font-family: var(--TextFont);
    }

    .popoverBody {
        padding: 40px;
    }

    .assetForm {
        width: 100%;
    }

    .btnWrapper {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: row-reverse;
    }

    @media screen and (max-width: 767px) {
        .btnWrapper {
            display: block;
            margin-top: 48px;
        }

            .btnWrapper button {
                margin-bottom: 16px;
            }
    }

    .assetModal {
        height: 100vh;
        width: 100vw;
        padding: 16px;
        position: fixed;
        left: 0;
        top: 0;
        background-color: white;
        z-index: 100;
        overflow-y: scroll;
        overflow-x: hidden
    }

    .tradeFeeWrapper {
        display: flex;
        align-items: center;
        padding-left: 11px
    }

    @media screen and (max-width: 767px) {
        .tradeFee {
            display: flex;
            align-items: center;
        }
    }

    .close {
        background-color: transparent;
        border: none;
        background: url('../../../assets/images/close.svg');
        background-repeat: no-repeat;
        height: 13px;
        width: 13px;
        margin: auto 0.35rem auto 0;
        position: absolute;
        top: 16px;
        right: 20px;
    }

    .whatsCoveredLink {
        display: inline-block;
        color: var(--PrimaryBrandColor);
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
        border-bottom: var(--PrimaryBrandColor) solid 2px;
        margin-top: 2rem;
    }