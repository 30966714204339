@import url('../../css/VARIABLES.css');


/* =======================================
=============== HEADER ===================
======================================= */

.pageHeader {
    height: 229px;
    background-color: var(--Color__Neutral1);
    /* background-color: red; */

    display: flex;
    justify-content: center;

    align-items: center;
}

.pageHeaderContent {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 480px;
    height: 100%;
    
}

.searchBox {
    display: flex;
    position: relative;
}

.searchInput {
    border-radius: var(--Input__BorderRadius);
}

.searchBarIcon {
    position: absolute;
    top: 18px;
    right: 18px;
    height: 20px;
    width: 20px;
}

.searchBarIcon.close {
    cursor: pointer;
}

/* =======================================
================ BODY ====================
======================================= */

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.resultsCounter {
    color: var(--PrimaryTextColor)
}

.searchResultsContainer h2 {
    font-weight: 400;
}
.faqNavs {
    margin-bottom: 2rem;
    display: flex;
}
.faqNavs .navItem {
    cursor: pointer;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.faqNavs .navLink {
    width:100%;
    min-height: 50px;
    padding: 0px 24px 16px 24px;
    font-size: 18px;
    color: var(--PrimaryTextColor);
    text-align: center;
    border-bottom: 1px solid var(--Color__Neutral2);
    margin-bottom: 0;
}

.faqNavs .navLink.active {
    color: var(--ActiveTab__Text);
    background-color: #fff;
    border-bottom: 3px solid var(--ActiveTab);
}

.moreQuestions {
    display: flex;
    flex-flow: column;
    max-width: 767px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.moreQuestions > p {
    font-size: 18px;
    max-width: 454px;
}

.contactOptions {
    display: flex;
    justify-content: center;
    width: 100%
}

.contactOptions a {
    flex: 1 1 50%;
}

.contactOptions a:first-of-type {
    margin-right: 1rem;
}

.optionButton {
    width: 100%;
    height: 100%;
    padding: 24px;
    background-color: white;
    border: var(--RadioAsButton__Border);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    max-width: 400px;
    cursor: pointer;
}

.optionButton div {
    display: flex;
    flex-direction: column;
}

.optionButton > div > p {
    margin-bottom: 0;
    color: var(--PrimaryTextColor);
} 

.optionButton > div > p:first-of-type {
    color: var(--Heading__Color);
} 

.moreQuestionsIcon {
    fill: var(--IconColor);
    margin-bottom: 8px;
}

.moreQuestionsIcon.phone {
    height: 32px
}

.moreQuestionsIcon.email {
    height: 28px
}

@media screen and (max-width: 767px) {
    .contactOptions {
        flex-direction: column-reverse;
    }

    .contactOptions a:first-of-type {
        margin-right: 0;
    }
    .optionButton {
        width: 100%;
        max-width: none;
        flex-direction: row;
        padding: 8px 16px;
        margin-bottom: 1rem;
    }

    .optionButton div {
        align-items: flex-start;
    }

    .moreQuestionsIcon {
        margin-right: 1rem;
        margin-bottom: 0
    }
}


.ratesTable table tbody tr:nth-child(odd) {
    background-color: var(--Table__RowBg);
}

.ratesTable table tbody tr td:nth-child(2),
.ratesTable table thead tr th:nth-child(2) {
    text-align: left;
}

@media screen and (max-width: 991px) {
    .ratesTable table thead th:nth-child(1) {
        width: 16% !important;
    }
}