/* Separaying React Tooltip CSS here as CSP not allowing dynamic css added by react-tooltip */
.__react_component_tooltip {
    padding: 24px !important;
    font-size: 14px !important;
    background: #fff !important;
}
    .__react_component_tooltip.show {
        opacity: 1 !important;
    }
.__react_component_tooltip.place-right {
    margin-left: 10px !important;
}
    .__react_component_tooltip.place-right::before {
        content: "" !important;
        background-color: inherit !important;
        position: absolute !important;
        z-index: -1 !important;
        width: 10px !important;
        height: 18px !important;
    }
    .__react_component_tooltip.place-right::after {
        content: "" !important;
        position: absolute !important;
        width: 10px !important;
        height: 10px !important;
        border-top-right-radius: 0px !important;
        border: 1px solid black !important;
        background-color: #fff !important;
        z-index: -2 !important;
        left: -6px !important;
        top: 50% !important;
        margin-top: -6px !important;
        transform: rotate(-135deg) !important;
    }
.__react_component_tooltip.place-left {
    margin-left: -10px !important;
}
    .__react_component_tooltip.place-left::before {
        content: "" !important;
        background-color: inherit !important;
        position: absolute !important;
        z-index: -1 !important;
        width: 10px !important;
        height: 18px !important;
    }
    .__react_component_tooltip.place-left::after {
        content: "" !important;
        position: absolute !important;
        width: 10px !important;
        height: 10px !important;
        border-top-right-radius: 0px !important;
        border: 1px solid black !important;
        background-color: #fff !important;
        z-index: -2 !important;
        right: -6px !important;
        top: 50% !important;
        margin-top: -6px !important;
        transform: rotate(45deg) !important;
    }
.__react_component_tooltip.place-top {
    margin-top: -10px !important;
}
    .__react_component_tooltip.place-top::before {
        content: "" !important;
        background-color: inherit !important;
        position: absolute !important;
        z-index: 2 !important;
        width: 20px !important;
        height: 12px !important;
    }
    .__react_component_tooltip.place-top::after {
        content: "" !important;
        position: absolute !important;
        width: 10px !important;
        height: 10px !important;
        border-top-right-radius: 0px !important;
        border: 1px solid black !important;
        background-color: #fff !important;
        z-index: -2 !important;
        bottom: -6px !important;
        left: 50% !important;
        margin-left: -6px !important;
        transform: rotate(135deg) !important;
    }
.__react_component_tooltip.place-bottom {
    margin-top: 10px !important;
}
    .__react_component_tooltip.place-bottom::before {
        content: "" !important;
        background-color: inherit !important;
        position: absolute ;
        z-index: -1 !important;
        width: 18px !important;
        height: 10px !important;
    }
    .__react_component_tooltip.place-bottom::after {
        content: "" !important;
        position: absolute !important;
        width: 10px !important;
        height: 10px !important;
        border-top-right-radius: 0px !important;
        border: 1px solid black !important;
        background-color: #fff !important;
        z-index: -2 !important;
        top: -6px !important;
        left: 50% !important;
        margin-left: -6px !important;
        transform: rotate(45deg) !important;
    }