.forgotPasswordBackground {
    background: url('../../assets/images/Vendor/Constellation/forgot-password.png') #FFF no-repeat top right;
    background-size: 30%;
}

.forgotPasswordCard {
    padding: 60px 75px;
    border: none;
    margin-top: 70px;
    margin-bottom: 70px;
    box-shadow: 28px 24px 68px 36px rgba(50, 50, 50, 0.06);
}

.mainContainer {
    padding: 0;
}

@media screen and (max-device-width: 820px) {
    .forgotPasswordBackground {
        background-size: 45% 52%;
        background-position-x: 105%;
    }

    .forgotPasswordCard {
        padding: 60px 78px;
        margin: 32px 0;
    }
}

@media screen and (max-device-width: 430px) {
    .forgotPasswordBackground {
        background: none;
    }

    .forgotPasswordCard {
        padding: 30px 24px;
        background: #f9f9f9;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
    }
}