.bodyWrapper {
    text-align: start;
}
.bodyWrapper li {
    list-style-type: none;
}

.headerWrapper {
    margin: 55px auto 0px;
    padding: 0px;
}