@import url('../../../css/VARIABLES.css');

.rtCol {
    background: var(--Color__Neutral1);
    padding: 24px 32px;
    line-height: 1.8;
    font-size: 15px;
    border-radius: 6px;
    max-width: 416px;
}

.hoverHand {
    cursor: pointer;
}

.allUpdate {
    color: var(--LinkColor);
    font-size: 15px;
    font-weight: bold;
}


.contentWrapper {
    flex: 1 0 auto;
    position: relative;
    margin: 38px auto;
    max-width: 1140px;
}

.estArrival {
    font-weight: normal;
    color: var(--PrimaryTextColor);
    margin-bottom: 0;
    font-size: calc(1.125rem * 1.173 * 1.173);
    line-height: 1.33;
}

.claimProgress {
    margin-top: 32px;
}

.progressItem {
    position: relative;
    min-height: 75px;
    list-style: none;
    padding-left: 0.5rem;
    margin: 0;
    font-size: 1.125rem;
    font-weight: bold;
}

.trackingNumber {
    font-size: 14px;
    color: var(--LinkColor);
}

    .progressItem:before {
        content: "";
        position: absolute;
        left: -30px;
        top: 22px;
        height: 73%;
        width: 1px;
        border-left: 1px dotted #979797;
    }

    .progressItem:after {
        content: '';
        position: absolute;
        top: 2px;
        left: -2.5rem;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid var(--ProgressActive);
    }

    .progressItem:last-child:before {
        border: none;
    }

    .progressItem.progressItemCompleted:after {
        border: 1px solid var(--ProgressActive);
        background-image: url('../../../assets/images/Completed.svg');
        background-color: var(--ProgressActive);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
    }

    .progressItem.progressItemPending:after {
        border: 1px solid var(--ProgressPending);
        background-image: url('../../../assets/images/Pending.svg');
        background-color: var(--ProgressPending);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
    }

    .progressItem.progressItemWarning:after {
        border: 1px solid var(--ProgressPending);
        background-image: url('../../../assets/images/Warning.svg');
        background-color: var(--ProgressPending);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
    }

    .progressItem.progressItemAlert:after {
        border: 1px solid var(--ProgressAlert);
        background-image: url('../../../assets/images/Error.svg');
        background-color: var(--ProgressAlert);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
    }

    .progressItem p {
        margin-bottom: 0;
    }

        .progressItem p:nth-child(2) {
            margin-bottom: 0;
            font-weight: normal;
            line-height: 1.625;
        }

.statusYellow, .statusRed, .statusGreen {
    background-color: var(--ProgressPending);
    padding: 2px 8px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 14px;
    color: var(--PrimaryBrandColor);
}
.statusRed, .statusGreen {
    background-color: var(--ErrorColor);
    font-weight: normal;
    color: #ffff;
}
.statusGreen {
    background-color: var(--ProgressSuccess);
}

.stepSecondaryText {
    font-size: 16px;
}

@media (max-width: 991px) {
    .rtCol {
        margin: 0 15px 32px;
    }
}

@media (max-width: 573px) {
    .rtCol {
        max-width: 100%;
    }
}
