@import url('../../../css/VARIABLES.css');

.planCard {
    border-top: 4px solid var(--SecondaryBrandColor);
}

.cardHeader {
    padding: 24px 35px !important;
    border-bottom: none;
}

.cardBody {
    padding: 0 35px 15px 35px !important;
}

.listDetails li {
    font-size: 13px;
}

.toggleIcon {
    margin-right: 20px;
    color: var(--PrimaryBrandColor);
    height: 32px;
    object-fit: contain;
    width: 20px;
}

.assetIcon {
    fill: var(--CategoryTab__Color);
    display: block;
    margin: 0 auto 8px;
    vertical-align: bottom;
    fill: var(--PrimaryTextColor);
    width: 68px;
    height: 68px;
}

.myPlansTable {
    font-size: 13px;
    margin-bottom: 0;
    color: var(--PrimaryTextColor);
}

.myPlansTable thead th {
    border: none;
    padding: 1.5rem;
    position: relative;
}

.myPlansTable tbody td {
    border: none;
    vertical-align: middle;
    padding: 1.5rem;
    text-overflow: ellipsis;
}

.myPlansTable span::after{
    top: 27px
}

.myPlansTable tbody tr:nth-child(odd) {
    background-color: var(--Table__RowBg);
}

.planViewToggle {
    justify-content: right;
}

.planCount {
    padding: 6px 20px;
}

.reprintAlert {
    position: absolute;
    top: 10px;
    right: 5.75rem;
    color: #001871;
    background-color: #13AA41;
}

@media (max-width: 767px) {
    .cardHeader {
        padding: 15px 25px 5px 25px !important;
    }
    .cardBody {
        padding: 0 25px 15px 25px !important;
    }
    .assetIcon {
        float: right;
    }
  }
@media (max-width: 991px) {
    .toggleIcon {
        width: 19px;
        height: 24px;
    }
}