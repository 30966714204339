@import url('../../css/VARIABLES.css');

@media screen and (min-width:768px) {
    .confirmContainer {
        max-width: 825px;
        margin-top: 60px;
    }
}

@media only screen and (max-width: 767.99px) {
    .apptMessage h2:first-child {
        margin-bottom: 0rem;
    }
    .confirmContainer {
        margin-top: 30px;
    }
}

.onlineConfirmCard{
    margin: 0 auto;
}

    .onlineConfirmCard .cardheader {
        background-color: var(--ProgressActive);
        color: var(--ProgressActive__Text);
        text-align: center;
    }

    .onlineConfirmCard .cardheader h1 {
        margin-bottom: 0rem;
        font-size: 28px;
        color: #fff;
    }

.onlineConfirmCard  .col p {
    font-size: 20px;
}

    .onlineConfirmCard  .col p:nth-of-type(3) {
        margin-bottom: 0;
    }

    .onlineConfirmCard  .col p:nth-of-type(2) {
        margin-bottom: 1.5rem;
    }

.claimNum {
    color: var(--PrimaryTextColor);
    font-size: 28px !important;
    margin-bottom: 0rem;
    font-weight: bold;
}

.apptTiming {
    display: flex;
    color: var(--Heading__Color);
    justify-content:center; 
}

@media only screen and (max-width: 767.99px) {
    .apptTiming {
        display: block;
    }
}

.onlineConfirmCard  img {
    margin-bottom: 1rem;
}

.apptTiming h2:first-child {
    margin-right: 12.5px;
}

.apptTiming h2:last-child {
    margin-left: 12.5px;
}

.onlineConfirmCard .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.rescheduleAppt {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

    .rescheduleAppt p:first-of-type {
        color: var(--Heading__Color);
        margin-bottom: 0rem;
        font-weight: 600;
        line-height: 1;
    }

.lineMiddle {
    width: 2px;
    background-color: var(--Heading__Color);
    height: 36px;
}

.apptMessage {
    margin: 2.0rem auto 0.8rem;
    text-align: center;
}

.servicerDet {
    text-align: center;
    font-size: 18px;
}

    .servicerDet p:first-child {
        margin-bottom: 0rem;
    }

.onlineConfirmCard  img {
    height: 152px;
    margin-bottom: 1rem;
}
.confirmIcon {
    margin-bottom: 26px;
    height: 151px;
}