@import url('../../../css/VARIABLES.css');

.headerBorder {
    border-bottom: 1px solid var(--Color__Neutral3);
}

.headerLg {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.closeButtonTop {
    top: 2px;
    z-index: 9;
}

.hidden {
    opacity: 0;
    height: 0;
}

.logoContainer {
    margin-bottom: 4px;
}

.logoContainer a {
    cursor: pointer;
}

.logo {
    height: 92px;
    width: 260px;
    margin-left: 16px;
}

.aigLogo {
    height: 40px;
    width: 79px;
}

.headerRight {
    margin-top: 0px;
    margin: 10px;
}

.headerRight span {
    color: #001871;
    font-size: 10px;
}

.mobileNav button {
    border: 1px solid rgba(0, 0, 0, .1) !important;
    padding: 0 !important;
}

.navContainer {
    display: flex;
    justify-content: flex-end;
}

.navTabs li a {
    padding: 0.5rem 0.7rem !important;
    font-size: 1rem !important;
    font-weight: normal !important;
    color: var(--PrimaryTextColor) !important;
    border: none !important;
    background: transparent !important;
    cursor: pointer;
}

.navTabs li a:hover,
.navTabs li a:active {
    background: var(--PrimaryBrandColor) !important;
    color: #fff !important;
}

.mobileLogo {
    display: none;
}

.poweredBy {
    display: none;
    padding-right: 5px;
}

.navMenu {
    margin: 0;
}

@media screen and (max-width: 767px) {

    .navTabs li a {
        color: var(--Color__Neutral1) !important;
        font-size: 1.3rem !important;
    }
}

@media (min-width: 767px) {

    .mobileNav {
        padding: 0px !important;
    }
}

@media screen and (max-width: 400px) {

    .poweredBy {
        display: none;
    }
}

@media screen and (max-device-width : 768px) {

    .poweredBy {
        display: block;
    }

    .mobileLogo {
        display: block;
    }

    .logo {
        height: 45px;
        width: 160px;
    }

    .headerLg {
        display: none;
    }

    .navContainer {
        padding: 8px;
        justify-content: space-between;
    }
}

@media screen and (max-device-width: 430px) {

    .logo {
        height: 45px;
        width: 160px;
        margin-left: 8px;
    }
}

@media screen and (max-device-width: 360px) {

    .logo {
        width: 145px;
    }
}