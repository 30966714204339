.scriptBox {
    box-sizing: border-box;
    min-height: 68px;
    height: fit-content;
    border: 1px solid #0079FF;
    border-radius: .25rem;
    background-color: rgba(0, 121, 255, 0.04);
    margin: 0px auto 24px;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    padding: 12px 24px;
}

.scriptBox .msg {
    color: #001871;
    font-size: 16px;
    margin-bottom: 0;
}

.scriptBox .h3 {
    font-weight: normal;
    margin-bottom: 0;
}

.scriptBox .chatIcon {
    max-height: 3.8rem;
    max-width: 4rem;
    margin-right: 24px;
}