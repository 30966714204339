.formBlock {
  -webkit-box-pack: left!important;
  -ms-flex-pack: left!important;
  justify-content: left!important;
}

.formBody label.cardTitle, .formBody p.helpText {
  text-align: left;
}

h1 {
  font-size: 30px;
  margin-bottom: 24px;
}

.cardTitle {
  font-size: 18px!important;
  color: #4A4A49;
  font-weight: normal;
}

.radioButton.col-md-6, #symptomGroup .radioButton.col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

@media (max-width: 767px) {
    .symptomGroup.list .radioButton.col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

    @media (min-width: 992px) {
        #affirm .checkbox {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }