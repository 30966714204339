@import url('../../css/VARIABLES.css');

.fileClaimCard {
    padding: 60px;
    margin: 32px 0;
    border: none;
}

.mainContainer {
    padding: 0;
}

.landingBackground {
    background-image: url('../../assets/images/Vendor/Cutler/landing-desktop.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
}

.landingBackground::before {
    content: '';
    width: 50%;
    height: 100%;
    background-color: #f9f9f9;
    position: absolute;
    transform: skew(-15deg) translateX(-30%);
}

.divider {
    border: 1px solid var(--Color__Neutral3);
    margin-bottom: 42px;
    opacity: 0.6;
}

.checkboxInput {
    margin-bottom: 0 !important;
}

.checkboxInput label::before {
    border: 1px solid #DCDCDC !important;
}


.constellationHero {
    position: relative;
    background-image: url('../../assets/images/Vendor/Constellation/landing_desktop.jpg');
    background-position: center top 15%;
    background-size: cover;
    background-repeat: no-repeat;
}

.constellationSwoosh {
    position: relative;
    background-image: url('../../assets/images/Vendor/Constellation/swoosh_no_wings.svg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 20px;
    margin-top: -1px;
}

@media screen and (max-device-width: 768px) {
    .landingBackground {
        background-image: url('../../assets/images/Vendor/Cutler/landing_ipad.png');
    }

    .landingBackground::before {
        width: 0;
    }
}

@media screen and (max-device-width: 430px) {
    .landingBackground::before {
        width: 0;
    }

    .landingBackground {
        background: none;
    }

    .fileClaimCard {
        padding: 30px 24px;
        background-color: #f9f9f9;
        margin: 0;
        border-radius: 0;
    }

    .fileClaimCard.CONSTELLATION {
        margin: 32px 0;
        background-color: #ffffff;
    }

    .constellationSwoosh {
        height: 8px;
    }

    .checkboxInput label::before {
        background-color: #ffffff !important;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 991.98px) {

    .constellationSwoosh {
        height: 16px;
    }

    .constellationHero {
        background-position: center left 33%;
    }
}