@import url('../../css/VARIABLES.css');
    /* .fileClaim {
    margin-top: 30px;
} */
    
    .staticTab {
        border-bottom: 1px solid var(--Color__Neutral2);
        padding: 24px 40px;
        display: flex;
        align-items: center;
    }
    
    .staticTab div {
        padding-left: 40px;
    }
    
    .staticTab p,
    .staticTab h3 {
        margin-bottom: 0;
    }
    
    #icon-range {
        height: 55px;
    }
    
    .navTabs {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
    
    a.navLink:not([href]):not([tabindex]):hover,
    a.navLink:not([href]):not([tabindex]):focus {
        color: var(--CategoryTab__Color);
    }
    
    a.navLink:not([href]) {
        text-align: center;
        border-top: 3px solid #fff;
        border-bottom: 1px solid var(--Color__Neutral2);
        font-size: 1rem;
        font-weight: var(--Heading__FontWeight);
        border-radius: 0;
        color: var(--CategoryTab__Color);
        display: block;
        padding: 1.5rem 0;
    }
    
    a.navLink.active:not([href]) {
        border-top: 3px solid var(--ActiveTab);
        border-bottom: none;
    }
    /* ===== HANDLES TAB RESPONSIVENESS BEHAVIOR ===== */
    
    .navTabs {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        padding-left: 0;
    }
    
    .navTabs,
    .navTabs .navItem.active,
    .navTabs .navItem:focus {
        border-bottom: none;
    }
    
    .navItem {
        text-align: center;
        border-left: 1px solid var(--Color__Neutral2);
        min-width: 140px;
        flex: 1 0 20%;
    }
    
    .navItemET {
        flex: 0;
    }

    .navItemET:last-child {
        border-right: 1px solid var(--Color__Neutral2);
    }

    .tabIcon {
        fill: var(--CategoryTab__Color);
        height: 55px;
        width: 55px;
        display: block;
        margin: 0 auto 8px;
        vertical-align: bottom;
    }
    /* make first break go 3 over2 instead of 4 over 1*/
    
    @media screen and (max-width: 1199px) {
        .navItem {
            flex-basis: 33%;
        }
        a.navLink.active:not([href]) {
            border-bottom: 1px solid var(--Color__Neutral2);
        }
    }
    /* ===== make mobile break take buttons full-width ===== */
    
    @media screen and (max-width: 359px) {
        .navItem {
            flex-basis: 33%;
            min-width: 90px;
        }
        .navLink {
            padding: 8px 0 !important;
        }
        .tabIcon {
            visibility: collapse;
            display: none;
        }
    }
    /* END TAB RESPONSIVENESS */
    
    .btnPopover {
        font-size: 1rem;
        line-height: inherit;
        padding: 48px 0;
        transition: all .3s ease;
        align-items: center;
        border-radius: 0;
        font-weight: 500;
        cursor: pointer;
        justify-content: center;
        letter-spacing: .5px;
        text-align: center;
        color: var(--PrimaryTextColor);
        width: 100%;
        background: none;
        border: none;
    }
    
    .assetList {
        list-style: none;
        padding-left: 0;
    }
    
    .assetIcon {
        fill: var(--CategoryTab__Color);
        height: 55px;
        width: 55px;
        display: block;
        margin: 0 auto 8px;
        vertical-align: bottom;
        fill: var(--PrimaryTextColor);
        width: 68px;
        height: 68px;
    }
    
    .tabPane .col {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .tab-pane .col .asset-card {
        position: absolute;
        top: 170px;
        z-index: 10;
        /* keep on top of other elements on the page */
        outline: 9999px solid rgba(0, 0, 0, 0.5);
    }
    
    .popoverHeader {
        background-color: var(--Color__Neutral2);
        border-bottom: none;
        padding: 1.5rem 40px;
        /*display: none;*/
        width: 100%;
    }
    
    .popover {
        max-width: 665px;
        border-radius: 0;
        outline: 9999px solid rgba(0, 0, 0, 0.5);
        font-family: var(--TextFont);
    }
    
    .assetCard.multi .card-header {
        display: block;
    }
    
    .popoverBody {
        padding: 40px;
    }
    
    .assetCard .navItem,
    .assetCard .navItem:last-child {
        max-width: none;
        width: auto;
    }
    
    .assetCard .navItem {
        border-bottom: 0;
    }
    
    .assetCard .navTabs .navLink {
        background: transparent;
        padding: 0;
        color: var(--ActiveTab__Text);
        border: none;
        margin: 0 24px 0 0;
    }
    
    .assetCard .navItem {
        border-left: none;
    }
    
    .assetCard .navTabs a.navLink.active:not([href]) {
        border-bottom: 3px solid var(--ActiveTab);
        border-top: none;
        color: var(--ActiveTab__Text);
    }
    
    .assetForm {
        width: 100%;
    }
    
    .assetData {
        width: auto;
    }
    
    .assetData ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
    }
    
    .assetData ul li:first-child {
        font-weight: 600;
        font-size: 20px;
    }
    
    .assetData p {
        margin-bottom: 0
    }
    
    .assetData button {
        margin: 24px 0 32px;
    }
    
    .btnWrapper {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: row-reverse;
    }
    
    @media screen and (max-width: 767px) {
        .btnWrapper {
            display: block;
            margin-top: 48px;
        }
        .btnWrapper button {
            margin-bottom: 16px;
        }
    }

    @media screen and (max-width: 480px) {
        .popoverHeader {
            padding: 16px;
        }

        .navItemET {
            flex-basis: 33%;
            min-width: 90px;
            flex: 1 !important;
        }
    }
    /*Medium Asset*/
    
    #humidifier .tab-icon,
    #air-conditioner .tab-icon,
    #sewer .tab-icon {
        height: 55px;
    }