@import url('../../css/VARIABLES.css');

.hero {
    min-height: 286px;
    background: var(--Hero__Bg);
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 32px;
    padding: 24px 0 0;
}
    .hero.CUTLER {
        padding: 0px !important;
    }

    .hero.CONSTELLATION {
        padding: 0px !important;
    }

.heroContainer {
    width: 100%;
}

    .heroContainer.ET {
        min-height: 286px;
        background-image: url('../../assets/images/Vendor/ET/ET-hero.png'); 
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
    }

    .heroContainer.CUTLER {
        min-height: 286px;
        background-image: url('../../assets/images/Vendor/Cutler/Cutler-hero.png'); 
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
        padding: 0 24px;
    }

    .heroContainer.CONSTELLATION {
        min-height: 286px;
        background-image: url('../../assets/images/Vendor/Constellation/dot_pattern_left.svg'),
            url('../../assets/images/Vendor/Constellation/dot_pattern_right.svg');
        background-size: auto;
        background-position: top left, top right;
        background-repeat: no-repeat;
        padding: 0 24px;
    }

.hero h1 {
    color: var(--Hero__Heading);
    margin-bottom: 0;
}

    .hero p {
        color: var(--Hero__Text);
        margin-bottom: 0;
    }

    .hero .card {
        background: var(--Notification__Bg);
        color: var(--Notification__Text);
        box-shadow: none;
    }

.hero .card-header {
    border-color: var(--Color__Neutral2);
    color: var(--Hero__Text);
    padding: 16px 40px;
    background: transparent;
    font-size: calc(1.125rem * 1.173 * 1.173);
    font-weight: var(--Heading__FontWeight);
}

.hero .card-body div.row {
    margin-bottom: 8px;
}

.subContainer{
    min-height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.hero .card a {
    color: var(--Notification__Link);
    text-decoration: underline;
}

/*.accountDetails {
    background-color: transparent !important;
    color: white;
}*/

.planOverviewButton {
    color: #007BFF;
    /* border: none; */
}

.noBorder {
    border: none !important;
}

.constellationHero {
    max-height: 100px;
    width: 100%;
    margin-top: -96px;
}

.constellationHeroMobile {
    max-height: 70px;
    width: 100%;
    margin-top: -95px;
}

@media only screen and (max-width: 767px) {
    .hero .card {
        margin-top: 16px;
    }

    .mobile-mb-3 {
        margin-bottom: 1rem;
    }
}

@media (max-width: 991.98px) {

    h1.CONSTELLATION {
        margin-top: 24px;
    }
}

@media (min-device-width: 768px) and (max-device-width: 991.98px) {

    .constellationHero {
        max-height: 70px;
    }
}
