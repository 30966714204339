@import url('../../../css/VARIABLES.css');

.etFooter {
    font-size: 14px;
    color: #FFFFFF;
    background: var(--SecondaryBrandColor);
    padding: 36px 18px;
    margin-top: 0;
}

.etFooterText {
    color: #343741;
    margin-bottom: 0;
    margin-top: 0;
    padding: 20px 0;
}


.LogoContainer {
    margin-top: 20px;
    margin-bottom: 15px;
}

.etNav button {
    color: rgba(0, 0, 0, 0.5);
    border: none !important;
    padding: 0;
}

.etNav {
    padding: 0.5rem 0;
}

.Logo {
    height: 40px;
    width: 133px;
}

.aigLogo {
    height: 40px;
    width: 79px;
}

.footerRight {
    margin-top: 0px;
    margin-bottom: 12px
}

    .footerRight span {
        color: #001871;
        font-size: 10px;
    }

.footerLg {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.horzList {
    list-style-type: none;
    display: inline-flex;
    padding-left: 0;
    text-decoration: none;
}

    .horzList li:first-child ::after {
        content: "|";
        color: #FFFFFF;
        margin-left: 16px;
    }

    .horzList li a:first-child {
        padding-right: 16px;
    }

    .horzList li a {
        color: #FFFFFF;
        padding: 0px;
    }

.etFooter p {
    /* margin-bottom: 16px; */
    letter-spacing: 0.43px;
}

.footerBrandInfo {
    display: none;
}

@media (max-width: 767px) {
    .container {
        max-width: 100%;
    }
    .footerBrandInfo {
        display: flex;
    }
    .Logo {
        height: 36px;
        width: 120px;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .Logo {
        height: 36px;
        width: 120px;
    }
}