@import url('../../../css/VARIABLES.css');
.expandableTextarea {
    position: relative;
}

.emptyTextArea{
    position: relative;
}

.emptyTextArea svg {
    height: 16px;
    width: 16px;
    fill: var(--IconColor);
    position: absolute;
    top: -60px;
    right: 8px;
}

.charCount {
    font-size: 16px;
    color: var(--PrimaryTextColor);
    text-align: right;
}

.formControlTextarea {
    background-color: #fff;
    background-image: none;
    border: var(--Input__Border);
    color: var(--PrimaryTextColor);
    display: block;
    font-size: var(--BtnAndInput__FontSize);
    font-weight: 400;
    letter-spacing: var(--LetterSpacing);
    line-height: var(--Input__LineHeight);
    width: 100%;
    height: auto;
    padding: var(--Input__Padding);
    border-radius: 0;
    transition: border-color, border-width ease-in-out .2s;
    min-height: 56px;
}

.formControlTextarea:focus {
    border-color: var(--FocusColor);
    outline: 0;
    box-shadow: inset 0 0 0 1px var(--FocusColor);
}

.formControlTextarea[disabled] {
    background-color: var(--InputDisabled__Bg) !important;
    border: var(--InputDisabled__Border) !important;
    color: var(--InputDisabled__Text);
    opacity: 1;
    cursor: not-allowed;
}