@font-face {
    font-family: "aig_sanssourcepro";
    src: url('../assets/fonts/SourceSansPro-regular.eot');
    src: url('../assets/fonts/SourceSansPro-regular.woff') format("woff"); 
    src: url('../assets/fonts/SourceSansPro-regular.otf') format("opentype");
}

@font-face {
    font-family: "aig_sanssourcepro_semibold";
    src: url('../assets/fonts/SourceSansPro-semibold.eot');
    src: url('../assets/fonts/SourceSansPro-semibold.woff') format("woff");
    src: url('../assets/fonts/SourceSansPro-semibold.otf') format("opentype");
}

@font-face {
    font-family: "hwa_roboto";
    src: url('../assets/fonts/Vendor/HWA/Roboto-Regular.eot');
    src: url('../assets/fonts/Vendor/HWA/Roboto-Regular.woff') format("woff");
    src: url('../assets/fonts/Vendor/HWA/Roboto-Regular.otf') format('opentype');
}

@font-face {
    font-family: "hwa_roboto_bold";
    src: url('../assets/fonts/Vendor/HWA/Roboto-Bold.eot');
    src: url('../assets/fonts/Vendor/HWA/Roboto-Bold.woff') format("woff");
    src: url('../assets/fonts/Vendor/HWA/Roboto-Bold.otf') format("opentype");
}

:root {
    /* ============================== */
    /* ======= BRAND COLORS: ======== */
    /* ============================== */
    --PrimaryBrandColor: #001871;
    --SecondaryBrandColor: #1352de;
    --PrimaryTextColor: #343741;
    --FocusColor: #009DDB;
    --ErrorColor: #ec1944;
    /* NEUTRALS: DO NOT CUSTOMIZE BY BRAND */
    --Color__Neutral1: #f2f6fb;
    --Color__Neutral2: #dee9f6;
    --Color__Neutral3: #b9c6d4;
    --Color__Neutral4: #5d738d;
    --Color__Neutral5: #616160;
    /* ============================== */
    /* ======== Layout ========= */
    /* ============================== */
    --Container__MaxWidth: 1140px;
    /* ============================== */
    /* ========== TYPOGRAPHY ======== */
    /* ============================== */
    --TextFont: "aig_sanssourcepro", Arial, Helvetica, sans-serif;
    --FontSize: 16px;
    --LineHeight: 24px;
    --LinkColor: var(--SecondaryBrandColor);
    --IconColor: var(--SecondaryBrandColor);
    --LetterSpacing: 0.5px;
    --Heading__Font: var(--TextFont);
    --Heading__FontWeight: 600;
    --Heading__Color: var(--PrimaryBrandColor);
    --H1__Size: calc(1.125rem * 1.173 * 1.173 * 1.173 * 1.173 * 1.173); /* 40px */
    --H1__LineHeight: 52px;
    --H1__Weight: 700;
    --H2__Size: calc(1.125rem * 1.173 * 1.173 * 1.173 * 1.173); /* 30px */
    --H2__LineHeight: 34px;
    --H3__Size: calc(1.125rem * 1.173 * 1.173 * 1.173); /* 28px */
    --H3__LineHeight: 34px;
    --H4__Size: calc(1.125rem * 1.173 * 1.173); /* 24px */
    --H4__LineHeight: 32px;
    --H5__Size: calc(1.125rem * 1.173); /* 18px */
    --H5__LineHeight: 24px;
    /*    --H5__Weight: 400;*/
    --H6__Size: 1.125rem; /* 16px */
    --H6__LineHeight: 24px;
    /*    --H6__Weight: 400;*/
    /* ============================== */
    /* ========== BUTTONS =========== */
    /* ============================== */
    --BtnAndInput__FontSize: 1rem;
    --BtnAndInput__ActiveBorder: 1px solid var(--FocusColor);
    --Btn__BorderRadius: 999em;
    --Btn__Padding: 15px 40px;
    --Btn__LineHeight: 1.15;
    --BtnPrimary__Bg: var(--PrimaryBrandColor);
    --BtnPrimary__Text: white;
    --BtnPrimary__Border: 1px solid var(--PrimaryBrandColor);
    --BtnPrimary__HoverBg: var(--SecondaryBrandColor);
    --BtnPrimary__HoverBorder: 1px solid var(--SecondaryBrandColor);
    --BtnPrimary__HoverText: white;
    --BtnSecondary__Bg: white;
    --BtnSecondary__Border: 1px solid var(--Color__Neutral3);
    --BtnSecondary__Text: var(--PrimaryBrandColor);
    --BtnSecondary__HoverBg: var(--SecondaryBrandColor);
    --BtnSecondary__HoverBorder: 1px solid var(--SecondaryBrandColor);
    --BtnSecondary__HoverText: white;
    --BtnLink__Text: var(--SecondaryBrandColor);
    --BtnDisabled__Bg: var(--Color__Neutral1);
    --BtnDisabled__Border: 1px solid var(--Color__Neutral3);
    --BtnDisabled__Text: var(--Color__Neutral4);
    /* ============================== */
    /* =========== INPUTS =========== */
    /* ============================== */
    --Input__LabelColor: var(--PrimaryTextColor);
    --Input__LabelWeight: 600;
    --Input__LabelLineHeigt: 1.5;
    --Input__LabelMarginBottom: 8px;
    --Input__RequiredAsterisk: var(--ErrorColor);
    --Input__Placeholder: var(--Color__Neutral3);
    --Input__Border: 1px solid var(--Color__Neutral3);
    --Input__BorderRadius: var(--Btn__BorderRadius);
    --Input__LineHeight: 24px;
    --Input__Padding: 15px 24px;
    --Input__Entered: var(--PrimaryTextColor);
    --InputFocus__Border: 2px solid var(--FocusColor);
    --InputError__Color: var(--ErrorColor);
    --InputError__Bg: #fefbfb;
    --InputDisabled__Bg: var(--Color__Neutral1);
    --InputDisabled__Text: var(--Color__Neutral4);
    --InputDisabled__Border: 1px solid var(--Color__Neutral3);
    --Input__Icon: var(--SecondaryBrandColor);
    --Input__IconHeight: 28px;
    --Input__IconWidth: 28px;
    /* ============================== */
    /* ======== MISC INPUTS ========= */
    /* ============================== */
    --Toggle__Color: var(--SecondaryBrandColor);
    --Checkbox__Border: var(--Input__Border);
    --Checkbox__Height: 24px;
    --Checkbox__Width: 24px;
    --Checkbox__CheckedBg: linear-gradient(225deg,#1352de,#001871);
    ;
    --Checkbox__CheckColor: white;
    /* USE APPLICABLE INPUT VALUES ON DROPS TO UTILIZE OVERLAP */
    --Drop__ArrowColor: var(--PrimaryBrandColor);
    --DropOption__HoverBg: var(--SecondaryBrandColor);
    --RadioAsButton__Border: 1px solid var(--Color__Neutral3);
    --RadioAsButton__Bg: white;
    --RadioAsButton__Text: var(--PrimaryBrandColor);
    --RadioAsButton__CheckedBorder: var(--SecondaryBrandColor);
    --RadioAsButton__CheckedBg: var(--Color__Neutral1);
    --RadioAsButton__CheckedText: var(--PrimaryBrandColor);
    /* ============================== */
    /* =========== TABLES =========== */
    /* ============================== */
    --Table__RowBg: #f6f9fd;
    --Table__Border: 1px solid var(--Color__Neutral2);
    /* ============================== */
    /* ========= TABS ========== */
    /* ============================== */
    --ActiveTab: var(--PrimaryBrandColor);
    --ActiveTab__Text: var(--PrimaryBrandColor);
    --CategoryTab__Color: var(--SecondaryBrandColor);
    /* ============================== */
    /* ============ Hero ============ */
    /* ============================== */
    --Hero__Bg: linear-gradient(225deg,#1352de,#001871);
    --Hero__Heading: white;
    --Hero__Text: white;
    /* ============================== */
    /* ======= NOTIFICATIONS ======== */
    /* ============================== */
    --Notification__HeaderBg: #0057B8;
    --Notification__HeaderText: white;
    --Notification__Bg: #3578C2;
    --Notification__Border: 1px solid #929292;
    --Notification__Text: white;
    --Notification__Link: white;
    /* ============================== */
    /* ======== PROGRESS BAR ======== */
    /* ============================== */
    --ProgressActive: var(--SecondaryBrandColor);
    --ProgressPending: #FFBF3F;
    --ProgressAlert: var(--ErrorColor);
    --ProgressSuccess: #13AA41;
    --ProgressActive__Text: white;
    --Status__Bg: transparent;
    --Status__Border: 1px solid var(--PrimaryBrandColor);
    --Status__ActiveBg: linear-gradient(225deg,#1352de,#001871);
    --Status__ActiveCheck: white;
}
