@import url("../../css/VARIABLES.css");

.addonModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addonModalBody div {
  text-align: left;
  margin-bottom: 1.5rem;
}

.addonModalBody h5 {
  margin-top: 24px;
  margin-bottom: 40px;
}

@media (max-width: 425px) {
  .addonModalBody div {
    padding: 0px;
  }
}
