@import url('../../../../css/VARIABLES.css');

.progressBar {
    text-align: center;
    position: relative;
    margin-bottom: 68px;
    font-size: 18px;
}


.progressBar:after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background: var(--Color__Neutral3);
    position: absolute;
    bottom: 0;
    left: 0;
}

@media (max-width: 767px) {
    .progressBar {
        margin-bottom: 32px;
    }
}

.progressBar div {
    padding-bottom: 16px;
    color: var(--Color__Neutral4);
    font-weight: 600;
}

.progressBar div.active {
    font-weight: 600;
    opacity: 1;
    border-bottom: 4px solid var(--PrimaryBrandColor);
    color: var(--PrimaryTextColor);
}

.progressBar div.completed {
    opacity: 1;
    font-weight: 600;
    color: var(--PrimaryTextColor);
}

@media screen and (max-width: 767px) {
    .progressBar {
        font-size: 14px;
    }
}