@import url('../../css/VARIABLES.css');
.mainWrapper {
    max-width: 900px;
    margin: 0px 15rem;
}

.instructionHeader {
    font-size: 24px;
}

.infoIcon {
    height: 17px;
    fill: var(--IconColor);
    max-width: 25px;
    vertical-align: sub;
    cursor: pointer;
}

.instructionHeader {
    font-size: 24px;
}

.headerWrap {
    color: var(--Heading__Color);
}

.docWrapper {
    margin: 0 5rem;
}

.link {
    display: contents;
}

.downloadIcon {
    height: 20px;
    fill: var(--IconColor);
    vertical-align: sub;
    margin-left: 10px;
}

.tooltiptext {
    width: 480px;
}

.tooltiptext::before {
    border-bottom: 8px solid #dee2e6 !important;
}

.affirmError {
    margin-left: 34px;
}

.affirmCheckboxLabel {
    cursor: pointer;
}