@import url('./VARIABLES.css');



/* =============================================================================
  FORM
============================================================================= */



.formBody label.cardTitle, .formBody p.helpText {
    text-align: center !important;
    display: block;
}

.formHeader {
    background-color: var(--Color__Neutral1);
    height: 225px;
    text-align: center;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.formHeader h1 {
    margin-bottom: 0;
}

.formBody {
    margin-top: 48px;
}

@media (max-width: 767px) {
    .formBody {
        margin-top: 0px;
    }
}

.formBody .btnWrapper {
    margin-top: 1rem;
}

.formBlock {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.stepsFormBlock {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}



/* =============================================================================
    ERROR HANDLING
============================================================================= */



.form-error .form-label {
    color: var(--ErrorColor);
}

.errorMsg, .error-msg {
    color: var(--ErrorColor);
    display: block;
}

.error-msgPS {
    color: var(--ErrorColor);
    display: block;
    text-align:center;
}

.form-error .form-control {
    border-color: var(--ErrorColor);
    background-color: #fff;
    color: var(--ErrorColor);
}

.validationSummary, .validationSummary a, .validation-summary, .validation-summary a {
    color: var(--ErrorColor) !important;
    font-weight: 600 !important;
    text-align: left;
    margin-bottom: 24px;
}

.validationSummary a,
.validation-summary .link {
    text-decoration: underline !important;
    cursor: pointer;
}



/* =============================================================================
  FLOATING LABELS
============================================================================= */



.form-control:focus {
    border-color: var(--FocusColor);
    outline: 0;
    box-shadow: inset 0 0 0 1px var(--FocusColor);
}

.has-float-label .form-control:placeholder-shown:not(:focus) + * {
    top: 1.25em;
    color: var(--Input__Placeholder);
    letter-spacing: var(--LetterSpacing);
    font-size: var(--FontSize);
    opacity: .5;
    text-align: center;
}

.has-float-label {
    display: block;
    position: relative
}

.has-float-label label {
    background: white;
}

.has-float-label label, .has-float-label > span {
    position: absolute;
    cursor: text;
    font-size: 75%;
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
    top: -.5em;
    left: 1rem;
    z-index: 3;
    line-height: 1;
    padding: 0 10px
}

.has-float-label label::after, .has-float-label > span::after {
    content: " ";
    display: block;
    position: absolute;
    height: 2px;
    top: 50%;
    left: -.2em;
    right: -.2em;
    z-index: -1
}

.has-float-label .form-control::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s
}

.has-float-label .form-control::-moz-placeholder {
    opacity: 1;
    transition: all .2s
}

.has-float-label .form-control:-ms-input-placeholder {
    opacity: 1;
    transition: all .2s
}

.has-float-label .form-control::placeholder {
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input- placeholder {
    opacity: 0
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
    opacity: 0
}

.has-float-label .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
    opacity: 0
}

.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
    opacity: 0
}

.form-group .has-float-label {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.form-group .has-float-label .form-control {
    width: 100%;
    border-radius: var(--Input__BorderRadius)
}

.form-group .has-float-label:not(:last-child), .form-group .has-float-label:not(:last-child) .form-control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0
}

.form-group .has-float-label:not(:first-child), .form-group .has-float-label:not(:first-child) .form-control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}
