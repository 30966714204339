.planhero {
    padding: 40px 0px;
    background-color: var(--Color__Neutral1);
}

.planhero h1 {
    color: var(--PrimaryTextColor);
    margin-bottom: 0;
    font-size: 40px;
    margin-left: 30px;
}

.cardContainer {
    margin-top: 50px;
    margin-left: 15px;
}

.card {
    border-top: 5px solid var(--SecondaryBrandColor);
    border-radius: 4px 4px 0 0;
    margin-bottom: 24px;
}

.cardHeader {
    padding: 20px 40px 16px;
}

.cardHeader h3 {
    font-size: 24px;
    margin-bottom: 8px;
}

.cardBody ul li>span {
    font-weight: 700;
}

.cardBody ul li:last-child>span {
    color: #1352de;
}

.cardIcon {
    fill: var(--PrimaryTextColor);
    position: absolute;
    bottom: 16px;
    right: 30px;
    width: 86px;
    height: 90px;
}

.contactInfo {
    margin-left: 30px;
}

@media only screen and (max-width: 767px) {

    .planhero {
        padding: 30px 0;
    }

    .planhero h1 {
        margin-left: 15px;
    }

    .cardContainer {
        margin-top: 32px;
        margin-left: -15px;
    }

    .cardHeader {
        padding: 20px;
    }

    .cardHeader h3 {
        font-size: 20px;
    }

    .cardBody {
        padding: 20px;
    }

    .cardIcon {
        display: none;
    }

    .contactInfo {
        margin-left: 15px;
    }
}

@media screen and (min-width:768px) and (max-width:1023px) {

    .planhero {
        padding: 30px 0;
    }

    .planhero h1 {
        margin-left: 15px;
    }

    .cardContainer {
        margin-top: 32px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .cardHeader {
        padding: 24px;
    }

    .cardHeader h3 {
        font-size: 20px;
    }

    .cardBody {
        padding: 24px;
    }

    .contactInfo {
        margin-left: 15px;
    }
}