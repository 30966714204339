.callNum {
    padding: 9px 0px;
    background: #F3F3F3;
}

.njrHeader {
    padding: 14px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.njrLogo svg {
    height: 53px;
    width: 117px;
}

.navLinkNJR {
    font-size: 18px !important;
    font-weight: normal !important;
    border: none !important;
    background: transparent !important;
    cursor: pointer;
    font-weight: 600 !important;
    color: #004B87 !important;
    padding: 0px !important;
}

.mobileNav button {
    border: none !important;
}

.mobileMenu {
    position: fixed;
    background: #004B87;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    top: 0px;
}

.closeMenu {
    position: absolute;
    top: 8px;
    right: 16px;
}

.closeMenu svg {
    height: 16px;
    width: 16px;
}

.closeMenu svg polygon {
    fill: #fff;
}

@media (max-width: 767px) {
    .njrHeader {
        padding: 14px 16px !important;
    }
    .mobileNav button, .mobileNav {
        padding: 0px !important;
    }
    .navTabsNJR {
        margin-top: 4rem;
    }
    .navTabsNJR li {
        padding: 8px 0px !important;
    }

    .navLinkNJR {
        color: #fff !important;
        padding: 0px 24px !important;
    }
    .callNum{
        display: none !important;
    }
}