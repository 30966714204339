@import url('./VARIABLES.css');

@font-face {
    font-family: "aig_sanssourcepro";
    src: url('../assets/fonts/SourceSansPro-regular.eot');
    src: url('../assets/fonts/SourceSansPro-regular.woff') format("woff"), url('../assets/fonts/SourceSansPro-regular.otf') format("opentype"), url('../assets/fonts/SourceSansPro-regular.svg#filename') format("svg");
}

@font-face {
    font-family: "aig_sanssourcepro_semibold";
    src: url('../assets/fonts/SourceSansPro-semibold.eot');
    src: url('../assets/fonts/SourceSansPro-semibold.woff') format("woff"), url('../assets/fonts/SourceSansPro-semibold.otf') format("opentype"), url('../assets/fonts/SourceSansPro-semibold.svg#filename') format("svg");
}

h1, 
.h1, 
h2, 
.h2, 
h3, 
.h3, 
h4, 
.h4, 
h5, 
.h5, 
h6, 
.h6 {
    color: var(--Heading__Color);
    font-weight: var(--Heading__FontWeight);
    margin-bottom: 16px;
    line-height: 1.1;
}

h1, 
.h1 {
    font-size: var(--H1__Size);
    line-height: 1.3;
    font-weight: var(--H1__Weight);
}

h2, 
.h2 {
    font-size: var(--H2__Size);
    line-height: 1.05;
}

h3, 
.h3 {
    font-size: var(--H3__Size);
    line-height: 1.172;
}

h4, 
.h4 {
    font-size: var(--H4__Size);
    line-height: 1.33;
}

h5, 
.h5 {
    font-size: var(--H5__Size);
    line-height: 1.33;
}

h6, 
.h6 {
    font-size: var(--H6__Size);
    line-height: 1.33;
}

p {
    margin-bottom: 16px;
    line-height: 1.625;
}

.text-sm {
    font-size: 14px;
}

b {
    font-weight: 600;
}