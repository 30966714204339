.appleNav button {
    color: rgba(0, 0, 0, 0.5);
    border: none !important;
    padding: 0;
}

.appleNav {
    padding: 0.5rem 0;
}

@media screen and (max-width: 767px) {
    .mobileMenu {
        background-color: var(--PrimaryBrandColor);
        height: 100%;
        padding-top: 20px;
    }
    .centerNavItemsOnMobile {
        margin: 8px 0 !important;
    }
    .centerNavItemsOnMobile a {
        margin: 0 !important;
    }
}

.langToggleBtnFlex {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.flagIcon svg{
    height: 24px;
    margin-right: 16px;
}

.flagMenu {
    position: absolute;
    top: 12px !important;
}

.langDropToggle {
    position: relative;
    cursor: pointer;
}

.langDropToggle:hover {
    color: var(--LinkColor)
}

.langDropToggle .languageLabel:after {
    display: inline-block;
    content: ' ';
    background-image: url('../../../assets/images/arrow-cheveron-down.svg');
    background-size: 16px 16px;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 5px;
    margin: 0 5px;
}

.dropbtn {
    display: inline-block;
    border-radius: 0;
    padding: 16px 24px;
    padding-right: 48px;
    width: 100%;
    vertical-align: middle;
    background-color: #fff;
    font-size: var(--BtnAndInput__FontSize);
    font-weight: 400;
    text-align: left;
    line-height: var(--Input__LineHeight);
    color: var(--PrimaryTextColor);
    overflow: hidden;
    white-space: nowrap;
    padding: 24px 32px;
    display: flex;
    align-items: center;
}


/*.dropbtn-placeholder {
    color: var(--Input__Placeholder);
}*/

.dropbtn:after {
    display: inline-block;
    content: ' ';
    background-image: url('../../../assets/images/arrow-cheveron-down.svg');
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 24px;
}

.optionsBtn:after {
    margin-right: -4px !important;
}

.hidden {
    opacity: 0;
    height: 0;
}

.closeButtonTop {
    top: 2px;
    z-index: 9;
}

.navmenu{
    margin : 0;
}

.navmenu .modal-content {
    height: 100%!important;
    background: var(--PrimaryBrandColor)!important;
}

.closeBtn {
    background: none;
    border: none;
    font-size: 28px;
    color: var(--SecondaryBrandColor);
    opacity: 0.75;
}
/* Apple Logo Modal Styles */

.logoBtn {
    cursor: default;
}

.logoBtn:focus {
    outline: none;
}