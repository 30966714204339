.ccImageList {
    margin: 0;
    list-style: none;
    display: flex;
    position: absolute;
    top: 42px;
    right: 16px;
    pointer-events: none;
}

.ccImages {
    height: 35px;
    width: 45px;
    margin-left: 10px;
}