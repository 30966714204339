@import url('../../../css/VARIABLES.css');

.HeaderBorder {
    border-bottom: 1px solid #b9c6d4;
}

.HeaderLg {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Container {
    width: 90%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.closeButtonTop {
    top: 2px;
    z-index: 9;
}

.hidden {
    opacity: 0;
    height: 0;
}

.LogoContainer {
    margin-top: 20px;
    margin-bottom: 15px;
}

    .LogoContainer a {
        cursor: pointer;
    }

.etNav button {
    color: rgba(0, 0, 0, 0.5);
    border: none !important;
    padding: 0;
}

.etNav {
    padding: 0.5rem 0;
}

.Logo {
    height: 67px;
    width: 216px;
    margin: 10px;
}

.aigLogo {
    height: 40px;
    width: 79px;
}

.HeaderRight {
    margin-top: 0px;
    margin: 10px;
}

    .HeaderRight span {
        color: #001871;
        font-size: 10px;
    }

.mobileNav button {
    border: 1px solid rgba(0, 0, 0, .1) !important;
    padding: 0 !important;
}

.navContainer {
    display: flex;
    justify-content: flex-end;
}

.navTabs li a {
    padding: 0.5rem 0.7rem !important;
    font-size: 1rem !important;
    font-weight: normal !important;
    color: var(--PrimaryTextColor) !important;
    border: none !important;
    background: transparent !important;
    cursor: pointer;
}

    .navTabs li a:hover,
    .navTabs li a:active {
        background: var(--PrimaryBrandColor) !important;
        color: #fff !important;
    }

.appleNav button {
    color: rgba(0, 0, 0, 0.5);
    border: none !important;
    padding: 0;
}

.appleNav {
    padding: 0.5rem 0;
}

.mobileLogo {
    display: none;
}

.providedBy {
    display: none;
    padding-right: 5px;
}

.header-content .modal-content {
    height: 100% !important;
    background: var(--SecondaryBrandColor) !important;
}

.navmenu {
    margin: 0;
}

.navbar{
    padding: 0;
}

@media screen and (max-width: 767px) {
    .HeaderLg {
        display: none;
    }

    .navTabs li a {
        color: var(--Color__Neutral1) !important;
        font-size: 1.3rem !important;
        font-weight: normal !important;
    }

    .Container {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
    }

    .Header {
        border-bottom: none !important;
    }

    .navContainer {
        justify-content: space-between;
    }

    .centerNavItemsOnMobile {
        margin: 8px 0 !important;
    }

        .centerNavItemsOnMobile a {
            margin: 0 !important;
        }

    .mobileLogo {
        display: block;
    }

    .providedBy {
        display: block;
    }

    .Logo {
        height: 36px;
        width: 120px;
        margin: 10px;
    }
}

@media (min-width: 767px) {
    .LogoMobile,
    .callUSSm {
        display: none;
    }

    .mobileNav {
        padding: 0px !important;
    }
}

@media screen and (max-width: 400px) {
    .providedBy {
        display: none;
    }
}

@media only screen and (max-device-width : 768px) {

    .providedBy {
        display: block;
    }

    .mobileLogo {
        display: block;
    }

    .Logo {
        height: 36px;
        width: 120px;
        margin: 10px;
    }

    .navTabs li a {
        color: var(--Color__Neutral1) !important;
        font-size: 1.3rem !important;
        font-weight: normal !important;
    }

    .HeaderLg {
        display: none;
    }

    .navContainer {
        padding: 10px;
        justify-content: space-between;
    }
}
