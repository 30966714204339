
.appleform {
    text-align: center;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    margin: 24px 0;
}

.confirmBold {
    margin-bottom: .25rem !important;
}
