@import url('../../../css/VARIABLES.css');

.overviewBody {
    flex: 1 0 auto;
    position: relative;
}

.searchIcon {
    fill: var(--LinkColor);
    height: 16px;
    width: 16px;
    margin-right: .25rem;
    transform: rotate(90deg);
}

.downloadIcon {
    height: 20px;
    fill: var(--IconColor);
    vertical-align: sub;
    margin-left: 10px;
}

.search {
    display: flex;
    align-items: center;
    color: var(--LinkColor) !important;
    font-size: 16px;
    font-weight: 600;
    margin: 0px 14px;
}

.rtCol {
    background: var(--Color__Neutral1);
    padding: 24px 32px;
    line-height: 1.8;
    font-size: 15px;
    border-radius: 6px;
    max-width: 416px;
}

.hoverHand {
    cursor: pointer;
}

.overviewSection {
    margin-top: 30px;
}

    .overviewSection:first-child {
        margin-top: 0;
    }

.allUpdate {
    color: var(--LinkColor);
    font-size: 15px;
    font-weight: bold;
}


.contentWrapper {
    flex: 1 0 auto;
    position: relative;
    margin: 38px auto;
    max-width: 1140px;
}

.standardContentWrap {
    margin-bottom: 16px;
}

.standardContentWrap p {
    display: inline;
    margin-bottom: 0px;
}


.btmLine {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--Color__Neutral3);
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.actionRequiredBadge {
    background-color: var(--ProgressPending);
    margin-left: 16px;
    padding: 12px 12px;
    border-radius: 20px;
    font-weight: bold;
    font-size: var(--FontSize);
}

.deviceInfo {
    position: relative;
    padding-left: 80px;
    margin-bottom: 1rem;
}

.deviceImg {
    position: absolute;
    left: 15px;
    top: 0;
    width: 82px;
}

.pendingInfoRequired {
    padding-left: 20px;
}

.btmLine {
    border-bottom: 1px solid var(--Color__Neutral3);
    padding-bottom: 16px;
    margin-bottom: 32px;
}

.ccImage {
    width: 50px;
    margin-right: 8px;
}

.claimDetailsH6 {
    color: var(--PrimaryBrandColor);
    margin-bottom: .5rem;
    font-size: 1.125rem;
    line-height: 1.33;
}

.claimDetailsSubHeader {
    color: var(--PrimaryBrandColor);
    margin-bottom: .5rem;
    font-size: 16px !important;
    line-height: 1.33;
}

.claimInfo:first-child:nth-last-child(3), .claimInfo:first-child:nth-last-child(3) ~ .claimInfo:first-child {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

    .claimInfo:first-child:nth-last-child(2), .claimInfo:first-child:nth-last-child(3) ~ .claimInfo:nth-child(2) {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }


.claimInfo {
    margin-bottom: 40px;
}

.detailClaimMargin {
    margin-top: 40px;
    margin-bottom: 0px !important;
}

.caseTitle {
    color: var(--PrimaryBrandColor);
    text-align: right;
    margin: auto 0;
}

.estArrival {
    font-weight: normal;
    color: var(--PrimaryTextColor);
    margin-bottom: 0;
    font-size: calc(1.125rem * 1.173 * 1.173);
    line-height: 1.33;
}

.claimProgress {
    margin-top: 32px;
}

.supportingDocsStyle {
    display: flex;
    margin-bottom: 8px; 
}

.supportingDocsStyle a {
    color: var(--LinkColor) !important;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.paperIcon {
    fill: var(--LinkColor);
    width: 12px;
    height: 14px;
    margin-right: 8px;
}

@media (max-width: 991px) {
    .rtCol {
        margin: 0 15px 32px;
    }
}

@media (max-width: 765px) {

    .claimInfo {
        margin-bottom: 16px;
    }

    .caseTitle {
        text-align: left;
    }
}

@media (max-width: 573px) {
    .rtCol {
        max-width: 100%;
    }
}

.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.relative {
    position: relative
}

.wrapText {
    overflow-wrap: break-word;
}

/* ==================Dropzone================ */
.docsReq {
    padding-left: 20px;
}
