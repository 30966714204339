@import url('../../css/VARIABLES.css');

.modal-header.suggestedAddress {
    flex-direction: column;
    padding-bottom: 0;
    border-bottom: none;
}

.radioBox input[type="radio"]:checked + label {
    background-color: var(--RadioAsButton__CheckedBg);
    border: var(--RadioAsButton__CheckedBorder) solid 1px;
    color: var(--RadioAsButton__Text);
}

.radioControlsLabel {
    -ms-flex-align: baseline;
    align-items: baseline;
    color: var(--PrimaryTextColor);
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
    padding: 1rem 3rem 1rem 3rem;
    position: relative;
    text-align: left;
    border: var(--Input__Border);
    margin: 0.25rem 0 1rem;
    border-radius: 3px;
    width: 100%;
}

.radioBox input[type=radio]:checked+.radioControlsLabel:after {
    background: var(--Checkbox__CheckedBg);
    border-radius: 50%;
    content: '';
    height: 9px;
    left: 16px;
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    width: 9px;
}

.radioBox:focus {
    outline-color: transparent !important;
}
.radioControlsLabel:before {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 50%;
    border: var(--Input__Border);
    content: '';
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex: 0 0 18px;
    flex: 0 0 31px;
    height: 18px;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 18px;
    transition: background .3s ease;
    width: 24px;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
}

.radioBox input[type=radio]:checked + .radioControlsLabel:before {
    border-color: var(--RadioAsButton__CheckedBorder);
}

.radioBox input[type=radio] {
    left: -9999px;
    opacity: 0;
    position: absolute;
}

.radioBoxWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.radioBox {
    position: relative;
}

.addressRadioLabel {
    color: var(--RadioAsButton__Text);
    text-align: left;
    font-weight: 600;
    margin-bottom: 0;
}

/* save modal */

.saveCardHeader h4 {
    margin-bottom: 0px;
}

.saveCardHeader {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid var(--Color__Neutral2);
    justify-content: space-between;
}

.btnAlign {
    display: flex;
    justify-content: flex-end;
}