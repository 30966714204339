@import url('../../../css/VARIABLES.css');

@media (max-width: 767px) {
    .pageHeader p {
        font-size: 16px;
    }

    .pageHeaderContent {
        align-items:flex-start!important
    }
}

.pageHeader {
  height: 229px;
  background-color: var(--Color__Neutral1);
}

.pageHeaderContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 229px;
}

.pageHeader p {
    margin-bottom: 0rem;
}

.btnLinkCustom {
  font-weight: normal;
}
.contactText {
    font-weight: bold;
    color:#001871;
}

.contactUsFormWrapper {
  display: flex;
  justify-content: center !important;
}

.helpForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 3rem;
    align-items: center;
    margin-bottom: 5rem;
    width: 40%;
}

.captchaCheck {
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
  align-items: center;
  height: 86px;
  width: 312px;
  border: 1px solid #B9C6D4;
  padding: 10px;
  margin-bottom: 3.5rem;
}

.captchaCheck div {
  margin-bottom: 0rem !important;
}

.helpLineNum {
    height: 157px;
    min-width: 311px;
    border: 1px solid #b9c6d4;
    text-align: center;
    padding: 2rem 0 0;
}

.helpLineNum img {
  margin-bottom: 1rem;
}

.helpLineNum p:first-of-type {
  margin-bottom: 0rem;
}

.helpLineNum:first-of-type {
  margin-right: 1.5rem;
}