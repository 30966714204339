@import url("./VARIABLES.css");
button {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
    line-height: var(--Btn__LineHeight);
}

a,
a:hover {
    color: var(--LinkColor);
    text-decoration: none !important;
}

a:focus {
    outline-color: var(--BtnAndInput__ActiveBorder);
}

button.btn,
.btn-primary,
.btn-secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: inherit;
    padding: var(--Btn__Padding);
    border-radius: var(--Btn__BorderRadius);
    font-weight: 600;
    letter-spacing: var(--LetterSpacing);
    text-align: center;
    cursor: pointer;
    transition: all .3s ease;
}

button:focus {
    outline: 0;
    outline-offset: -2px;
    outline-color: var(--BtnAndInput__ActiveBorder);
}

button:focus,
.btn:focus {
    outline: -webkit-focus-ring-color auto 5px;
}

.btn-primary {
    color: #fff;
    background: var(--BtnPrimary__Bg);
    border: var(--BtnPrimary__Border);
}

.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus {
    color: var(--BtnPrimary__HoverText);
    background: var(--BtnPrimary__HoverBg);
    border: var(--BtnPrimary__HoverBorder);
}


/* .btn-primary:focus, 
.btn-secondary:focus {
    border-color: #00a4e4;
    background: #fff;
    color: #001871;
    box-shadow: inset 0 0 0 1px #00a4e4;
} */

.btn-secondary {
    color: var(--BtnSecondary__Text);
    background: var(--BtnSecondary__Bg);
    border: var(--BtnSecondary__Border);
}
    .btn-secondary.disabled,
    .btn-secondary:disabled {
        background: var(--BtnDisabled__Bg);
        border: var(--BtnSecondary__Border);
        color: var(--BtnDisabled__Text);
        pointer-events: none;
    }

    .btn-secondary.disabled,
    .btn-secondary:disabled {
        background: var(--BtnDisabled__Bg);
        border: var(--BtnSecondary__Border);
        color: var(--BtnDisabled__Text);
        pointer-events: none;
    }

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
    color: var(--BtnSecondary__HoverText);
    background: var(--BtnSecondary__HoverBg);
    border: var(--BtnSecondary__HoverBorder);
}

.btn-primary.disabled,
.btn-primary:disabled {
    background: var(--BtnDisabled__Bg);
    border-color: var(--BtnDisabled__Border);
    color: var(--BtnDisabled__Text);
}

.btnWrapper button.btn {
    margin-left: 15px;
}

.stepsFormBlock .btnWrapper button.btn:first-child {
    margin-left: 0;
}

.stepsFormBlock .btnWrapper {
    display: flex;
}

.stepsFormBlock .btnWrapper .btnLink {
    margin-top: 15px;
    margin-right: 15px;
}

.btnWrapper .btnLink {
    display: block;
    text-align: center;
    margin-top: 24px;
}

.btnLink,
.button-link {
    background: none;
    color: var(--BtnLink__Text);
    border: none;
    margin: auto 0;
    padding: 0;
    /* NEEDED? Covered by "Button"? */
    font-weight: 600;
    /* NEEDED? Covered by "Button"? */
}

footer .btnLink,
header .btnLink {
    font-weight: normal;
}

button.btnLink {
    margin: auto;
}

.callLink {
    color: var(--PrimaryTextColor);
    font-weight: normal;
    text-decoration: none;
    cursor: text;
}

.callLink:hover,
.callLink:focus {
    color: var(--PrimaryTextColor);
    font-weight: normal;
    text-decoration: none;
}

a.callLink,
.validation-summary a.callLink {
    text-decoration: none;
    cursor: text;
}

.continue {
    width: 148px;
}

.saveModalBtn {
    width: 172px;
}

@media (max-width: 767px) {
    button.btn,
    .btn-primary,
    .btn-secondary,
    .btnWrapper,
    .btnWrapper button.btn {
        width: 100% !important;
        margin-left: 0 !important;
    }
    .btnWrapper {
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (max-width: 767px) {
    .saveModalBtn {
        width: 129px;
        margin-left: 0 !important;
    }
}

@media (max-width: 320px) {
    .saveModalBtn {
        width: auto;
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 767px) {
    .callLink,
    .callLink:hover {
        color: var(--LinkColor);
        font-weight: 600;
        cursor: pointer;
    }
}