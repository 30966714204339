@import url('../../../css/VARIABLES.css');

@media (max-width: 767px) {
    .usMap {
        display: none !important;
    }

    .stateSelection {
        align-items: center !important;
    }

    .pageHeaderContent {
        padding: 25px 15px;
    }

    .pageHeader p {
       font-size: var(--FontSize) !important;
    }

    .learnMoreText {
      width: 300px !important;
      overflow: auto;
    }

    .marginTopXS {
      margin-top: 25px;
    }

    .countryHeading {
      margin-bottom: 0 !important;
      justify-content: left !important;
    }

    .mapContainer {
      display: none;
    }

    .pageHeaderCoverDetails {
      padding-top: 25px !important;
      padding-bottom: 25px !important;
      margin-bottom: 1rem !important;
    }

    .pageHeaderCoverDetails p:first-of-type {
      margin-bottom: 0.5rem !important;
    }

    .pageHeaderCoverDetails p {
      font-size: var(--FontSize) !important;
    }

    .pageHeaderContentCoverDetails {
      text-align: left !important;
    }
}

@media (min-width: 992px) {
    .pageHeaderContent  {
        align-items:center;
    }
}

.usMap .path{
    pointer-events: all;
    fill: #f0f0f0;
    opacity: 0.7;
    stroke: #343741;
    stroke-width: 0.77px;
}

    .usMap .path:hover {
        fill: #b6cbf4;
        cursor: pointer;
        stroke: none;
        stroke-width: 0%;
        color: #fff !important;
    }

@media (max-width: 1200px) {
    .stateSelection .form-group {
        margin-right: 0rem !important;
    }
}

/* Temporary Fix */
.coverageDocs {
    margin-bottom: 28rem;
    margin-top: 7rem;
}

    .coverageDocs .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .coverageDocs p {
        font-size: 24px;
        font-weight: bold;
    }

.pageHeader {
    min-height: 229px;
    margin-bottom: 2rem;
    background-color: var(--Color__Neutral1);
}

@media (max-width:425px) {
    .flagsContainer{
        flex-direction: column;
        padding: 0 17px;
    }
 }
.pageHeaderContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 229px; 
}

.pageHeaderCoverDetails {
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 2rem;
  background-color: var(--Color__Neutral1);
}

.pageHeaderCoverDetails p {
  font-size: 18px;
}

.pageHeaderCoverDetails p:first-of-type {
  margin-bottom: 0rem;
}

.pageHeaderContentCoverDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.pageBodyContent {
  display: flex;
  justify-content: center;
}

.pageContainer {
  margin-bottom: 100px;
}

.popOverLM {
    max-width: 509px !important;
    padding: 0.8rem;
}

    .popOverLM ul {
        padding-left: 1rem;
        margin-bottom: 0px;
    }

        .popOverLM ul li {
            margin-bottom: 1rem;
        }

            .popOverLM ul li:last-child {
                margin-bottom: 0px;
            }

.closeBtn {
    background-color: transparent !important;
    border: none !important;
}

    .closeBtn img {
        height: 13px;
    }

.popOverLMHead {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.pLink {
    font-weight: normal;
}

.pageHeader p:first-of-type {
    margin-bottom: 0rem;
}

.pageHeader p {
    font-size: 18px;
}
path.state {
    pointer-events: all;
    fill: #f0f0f0;
    opacity: 0.7;
    stroke: #343741;
    stroke-width: 0.77px;
}

    path.state:hover {
        fill: #b6cbf4;
        cursor: pointer;
        stroke: none;
        stroke-width: 0%;
        color: #fff !important;
    }

.stateSelection {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.usMap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
}
    .stateSelection .form {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 4rem;
    }

.planSelForm .form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.planSelForm .form-label-radio {
    display: block;
    font-size: 18px;
    margin-top: 2rem;
    font-size: 18px;
}

.planSelForm .btnLink {
    margin-top: 1.5rem;
    margin-bottom: 10rem;
}

#radioButtonGroup .radio-controls-label {
    margin: 0.5rem 0.5rem 1rem 0.5rem;
    min-width: 148px;
}

.learnMoreText {
    background: #fff;
    padding: 1.3rem;
    position: absolute;
    width: 500px !important;
    z-index: 1;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    margin-top: .5rem;
}

    .learnMoreText p {
        font-size: 14px;
    }

    .learnMoreText ul {
        margin: 1rem 0 0;
        padding-left:19px;
    }

        .learnMoreText ul li {
            margin-bottom: 1rem;
        }

            .learnMoreText ul li:last-child {
                margin-bottom: 0;
            }

    .learnMoreText::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        left: 69%;
        top: -7%;
        transform: translate(-50%,50%) rotate(45deg);
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0,87,184,0.01);
    }

.countryLabel {
  font-weight: bold;
  padding: 5px;
  color: var(--LinkColor);
  display: flex;
  align-items: center;
}

.iconImage {
  height: 30px;
  width: 40px;
  clip-path: circle(10px at center);
  margin-top:-6px;
}

.headingLabel, .headingLabelMain {
  color: var(--PrimaryTextColor);
}

.headingLabel {
  font-weight: normal;
}

.flagContainer {
  display: flex;
}

.flagGermany {
  margin-top: -3px;
}

.flagOther {
  margin-top:-4px;
}

.mapContainer {
  height: 160px;
  width: 315px;
  margin-bottom: 50px;
}

.mapContainer svg {
  fill: #7f7f7f;
}

.countryHeading {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.learnMoreText .noBullets {
    margin-bottom: 24px;
}

.learnMoreText .close {
    background-color: transparent;
    border: none;
    background: url('../../../assets/images/close.svg');
    background-repeat: no-repeat;
    height: 13px;
    width: 13px;
    margin: auto 0.35rem auto 0;
    position: absolute;
    top: 20px;
    right: 20px;
}
.stateSelect{
    font-size: 15px;
    color: black !important;
    font-weight: 100 !important;
    margin-bottom: 0rem !important;
    margin-top: 5px;
}
.seperator {
    width: 100%;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin: -5px 0 10px;
    background: #eee;
}
.flagIcon svg{
    height: 24px;
    margin-right: 16px;
}

.regionHeader {
    width:100%;
}
.regionContainer{
    display:flex;
    justify-content: flex-start ;
    flex-flow: row wrap;
    margin-bottom: 1rem;
}

.countryItem{
    flex: 0 0 25%;
    margin: .5rem 0;
}

@media screen and (max-width: 575.99px) {
    .countryItem {
        flex-basis: 100%;
    }
}
