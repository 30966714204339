@import url('../../css/VARIABLES.css');
.paymentTotal {
    color: var(--PrimaryTextColor);
    margin-bottom: 0;
    font-weight: normal;
    font-size: 24px;
}

.amount {
    color: var(--Heading__Color);
    font-weight: var(--Heading__FontWeight);
    margin-bottom: 40px;
}

.infoIcon {
    height: 17px;
    fill: var(--IconColor);
    max-width: 25px;
}

.hideBtnTextForSpinner {
    visibility: hidden;
}

.spinnerPosition {
    position: absolute;
}

.inputTooltip,
.inputContainer {
    position: relative;
}

.hasTooltip {
    position: absolute;
    right: 14px;
    bottom: 0;
    height: 100%;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tooltiptext {
    width: 290px;
    top: 45px;
    left: -22.5px;
}

.inputTooltip .hasTooltip {
    border-bottom: none;
}

.ccPin {
    height: 45px;
    width: 68px;
    margin-bottom: 12px;
}

.paymentBtnWrapper {
    display: flex;
    flex-direction: row-reverse;
}

.paymentBtnWrapper button {
    margin-left: 1rem !important;
}

 div[class^="autoDrop__single-value"] {
    padding-right: 61px !important;
    color: var(--PrimaryTextColor) !important;
    margin-left: 22px !important;
}

@media (max-width: 767px) {
    .paymentBtnWrapper {
        display: block;
        width: 100% !important;
    }
    .paymentBtnWrapper button {
        margin-bottom: 24px !important;
        margin-left: 0px !important;
    }
}