@import url('../../css/VARIABLES.css');

.createNewPasswordBackground {
    background: url('../../assets/images/Vendor/Constellation/forgot-password.png') #FFF no-repeat top right;
    background-size: 30%;
}

.mainContainer {
    padding: 0;
}

.loginContainer p {
    font-size: 24px;
    color: #343741;
}

.passwordRequirementContainer {
    padding-top: 3rem;
    border-top: var(--Input__Border);
    margin-top: 3rem;
}

.confirmPassCheckContainer {
    display: flex;
}

.confirmPassCheck {
    padding-right: 12px;
}

    .confirmPassCheck svg {
        height: 16px;
        width: 13px;
        fill: #00BF6F;
        padding-bottom: 4px;
    }

.checkText {
    color: var(--PrimaryTextColor);
    font-size: 14px;
    padding-top: 3px;
}

.confirmPassHeadText {
    font-weight: bold;
    padding-bottom: 16px;
}

.infoText {
    font-size: 16px !important;
    font-weight: normal !important;
    color: var(--PrimaryTextColor);
    line-height: 20px;
}

.clear {
    clear: both;
}

.createNewPasswordCard {
    padding: 60px 75px;
    border: none;
    margin-top: 70px;
    margin-bottom: 70px;
    box-shadow: 28px 24px 68px 36px rgba(50, 50, 50, 0.06);
}

@media screen and (max-device-width: 820px) {
    .createNewPasswordBackground {
        background-size: 45% 52%;
        background-position-x: 105%;
    }

    .createNewPasswordCard {
        padding: 60px 78px;
        margin: 32px 0;
    }
}

@media screen and (max-device-width: 430px) {
    .createNewPasswordBackground {
        background: none;
    }

    .createNewPasswordCard {
        padding: 30px 24px;
        background: #f9f9f9;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
    }
}
