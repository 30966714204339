@import url("../../../../css/VARIABLES.css");

.formBody {
    margin-top: 22px;
}

.badgeAlert {
    padding: 10px 20px;
    border-radius: 10rem;
    font-size: 16px;
    background-color: #FFBF3F;
    margin-left: 0.5rem;
    align-self: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: var(--PrimaryTextColor);
}

.docsReq {
    padding-left: 20px;
}

.infoIcon {
    width: 16px;
    height: 16px;
    fill: var(--IconColor);
    position: relative;
    top: -5px;
}

.downloadIcon {
    height: 18px;
    fill: var(--IconColor);
    vertical-align: sub;
    margin-left: 10px;
}

.tooltiptext {
    width: 350px;
    left: -30px;
    top: 125%;
    font-size: 14px;
}

.dropzone {
    min-height: 152;
    border: 1.5px dashed var(--Color__Neutral3);
    border-radius: 6px;
    padding: 24px;
    margin-bottom: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;    
    max-width: 425px;
}

.mobileUpload {
    display: none;
}

.uploadImage {
    height: 105px;
}

.dzMessage {
    margin: auto 0 auto 21px;
    color: var(--PrimaryTextColor);
    font-size: 18px;
    width: 100%;
}

.fileTypes, .fileSize {
    color: #6B7387;
    font-size: 14px;
}

.fileSize {
    margin-top: 24px;
}

.uploadProgress {
    border: 1px solid var(--Color__Neutral3);
    padding: 12px 12px 12px 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    max-width: 425px;
    margin-bottom: 24px;
}

.pendingUploadProgress {
    border: 1px solid var(--Color__Neutral3);
    padding: 8px 16px 8px 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    font-size: 14px;
    min-width: 425px;
    height: 56px;
}

.validationSummary{
    color: var(--ErrorColor);
    text-align: left;
    margin-bottom: 24px;
}

.file {
    width: 34px;
    height: 40px;
    margin: auto 8px auto 0;
    fill: var(--LinkColor);
}

.fileName {
    display: flex;
    flex: 0 30%;    
    margin: auto 0;
}

.pendingFileName {
    margin: auto 0;
    width: 100%;
    max-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: break-word;
}

.toolTipStyle [class$="inner"]{
    color: var(--PrimaryTextColor);
    background-color: #FFFF; 
    border: var(--Input__Border);
}

.progressWrapper {
    margin: 0 8px;
    width: 100%;
    display: flex;
    padding-top: 8px;
    flex-direction: column;
}

.progress {
    width: 100%;
    height: .45rem;
    background-color: var(--Color__Neutral1);
}


.progressBar, .progress-bar {
    background-color: var(--SecondaryBrandColor);
    width: 25%;
    border-radius: .25rem;
}

.uploadedPendingDocContainer {
    width: 100%;
    display: flex;
    align-items: start;
}

.progressBarWrapper {
    margin-left: .50rem;
    width: 100%;
}

.uploadProgressWrapper {
    display: flex;
    align-items: center;
    flex: 0 70%;
}

.pendingUploadProgressWrapper {
    margin-left: 8px;
    margin-top: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

.docTypeDropdown {
    margin-bottom: 16px;
    display: block;
    top: -24px;
}
.closeButton {
    width: 20px;
    height: 20px;
    margin: auto 0px auto 8px;
    cursor: pointer;
}

.closeButton polygon {
    fill: var(--LinkColor) !important;
}

.uploadPercentage {
    font-size: 14px;
    color: var(--PrimaryTextColor);
}

.ml15 {
    margin-left: 15px;
}

.browseLink {
    color: var(--LinkColor) !important;
    font-weight: 600;
    cursor: pointer;
}

.container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
}

.docTypeWidth {
    width: 200px;
}

.btmLine {
    border-bottom: 1px solid var(--Color__Neutral3);
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.submitBtn {
    width: 134px;
}

.docsReq .tooltipButton {
    border: none;
    padding: 0;
    display: inline;
    background: none;
    margin: -8px 0 0 5px;
    width: 16px;
    height: 16px;
}

.standardContentWrap {
    margin-bottom: 16px;
}

.standardContentWrap p {
    display: inline;
    margin-bottom: 0px;
}

@media (max-width: 767px) {
    .submitBtn {
        width: 102px;
    }

    .fileSize {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .dzMessage {
        margin: 1rem 0 0 0;
    }

    .mobileUpload {
        border: 1px solid var(--Color__Neutral3);
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-bottom: 24px;
    }

    .dropzone {
        display: none;
    }

    .badgeAlert {
        margin-left: 0rem;
    }

    .uploadProgress {
        max-width: 100%;
        min-width: none;
    }

    .uploadedPendingDocContainer {
        flex-direction: column-reverse;
    }
    .pendingUploadProgress {
        min-width: 225px;
        margin-bottom: 16px;
    }
    .docTypeDropdown {
        margin-bottom: 16px;
        margin-left: 0px !important;
        top: 0px;
    }
    .pendingFileName {
        width: 50px;
    }
}
